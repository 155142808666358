import { FC, ReactNode } from "react"
import { FlexCol } from "./common"
import { Input } from "antd"
import { Translated } from "../../utils/translated"

export const Label: FC<{
    children?: ReactNode
}> = ({ children }) => {
    return (
        <label
            style={{ fontSize: "inherit" }}
            className="block uppercase tracking-wide text-grey-darker font-bold mb-2"
        >
            {children}
        </label>
    )
}

export const LabeledInput: FC<{
    translatableLabel: string | null
    value: string | null
    setValue: (value: string | null) => void
    addonAfter?: ReactNode
    disabled?: boolean
    inputStyle?: React.CSSProperties
}> = ({
    translatableLabel: label,
    value,
    setValue,
    addonAfter,
    disabled,
    inputStyle,
}) => {
    return (
        <FlexCol style={{ gap: 0, width: "100%" }}>
            <Label>
                <Translated keyEn={label} />
            </Label>
            <Input
                size="large"
                value={value ?? ""}
                onChange={(value) => {
                    setValue(value.target.value)
                }}
                variant="filled"
                addonAfter={addonAfter}
                disabled={disabled}
                style={inputStyle}
            />
        </FlexCol>
    )
}
