import GoogleMapReact from "google-map-react"
import { FC, ReactNode } from "react"
import { FlexCol } from "../common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
import { COLOR_BG_BLUE } from "../../../utils/utils"
import { GOOGLE_MAPS_API_LOADER_BOOTSTRAP_PARAMETERS } from "."
import { Popover } from "antd"

export const formatLCoordinateDegMinSec = (coord: number | null) => {
    if (coord === null) {
        return "N/A"
    }
    let absCoord = Math.abs(coord)
    let deg = Math.floor(absCoord)
    let min = Math.floor((absCoord - deg) * 60)
    let sec = Math.floor(((absCoord - deg) * 60 - min) * 60)
    return `${deg}° ${min}' ${sec}''`
}

export const GoogleMapsMarker: FC<{
    lat: number
    lng: number
    children?: ReactNode
    color?: string
    fontSize?: string
    overlay?: ReactNode
}> = ({ children, color, fontSize, overlay }) => {
    fontSize = fontSize ?? "3rem"
    color = color ?? COLOR_BG_BLUE
    return (
        <FlexCol
            style={{
                gap: 0,
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-" + fontSize,
            }}
        >
            <FontAwesomeIcon
                icon={faLocationDot}
                style={{
                    fontSize,
                    color,
                }}
            />
            {/* {overlay !== undefined ? (
                <Popover overlay={overlay}>
                </Popover>
            ) : (
                <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{
                        fontSize,
                        color,
                    }}
                />
            )} */}
            {children}
        </FlexCol>
    )
}

export const GoogleMapsReactSignedIn: FC<GoogleMapReact.Props> = (props) => {
    return (
        <div style={{ height: "100%", width: "100%" }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    id: GOOGLE_MAPS_API_LOADER_BOOTSTRAP_PARAMETERS.id,
                    key: GOOGLE_MAPS_API_LOADER_BOOTSTRAP_PARAMETERS.apiKey,
                    libraries:
                        GOOGLE_MAPS_API_LOADER_BOOTSTRAP_PARAMETERS.libraries,
                }}
                {...props}
            />
        </div>
    )
}
