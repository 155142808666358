import { IKinematicPoint, MeanWithStd } from "../types"

export const EN_1177_TARGET_GMAX = 200
export const EN_1177_TOLERANCE_GMAX = 5

export const EN_1177_TARGET_HIC = 1000
export const EN_1177_TOLERANCE_HIC = 50

export const EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_ADEQUATE = 80
export const EN_ISO_23659_TARGET_PERFORMANCE_FACTOR_TOREVIEW = 100

export const G = 9.8065 // m/s^2
export const FREE_FALL_ACCELERATION_THRESHOLD = 0

export enum EResult {
    NotAdequate = 0,
    ToReview = 1,
    Adequate = 2,
}

export interface IZoneResult {
    result: EResult

    // EN1177
    gmax: MeanWithStd | null
    hic: MeanWithStd | null
    cfh: MeanWithStd | null

    // EN12503/EN12562
    deflectionDistanceMM: MeanWithStd | null
    deflectionDistancePerc: MeanWithStd | null
    resiliencePerc: MeanWithStd | null

    // EN ISO 23659
    secondaryBounceHeightM: MeanWithStd | null
    performanceFactor: MeanWithStd | null
}

export interface IEquipmentResult {
    result: EResult
}

export interface IImpactKinematicHIC {
    hicMax: number
    hicTms: number
    hicDTms: number
}

export interface IImpactKinematics {
    samplingFrequency: number | null
    calculatedDurationMS: number | null
    calculatedTFF: number | null
    calculatedFFH: number | null
    calculatedHIC: IImpactKinematicHIC
    calculatedGmax: number | null
    initialVelocity: number | null
    finalVelocity: number | null
    deflectionDistanceMM: number | null
    deflectionTimeMS: number | null
    resiliencePerc: number | null

    secondarySuspenseHeightM: number | null
    secondarySuspenseTimeMS: number | null

    kinematicPoints: IKinematicPoint[]
}

export interface IRequirementsSM {
    gmax: number | null
    deformationDistanceMM: number | null
    deformationDistancePerc: number | null
    resiliencePerc: [number | null, number] | null // min, max
}
