import {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"
import { Button, Spin, Tooltip, Typography, theme } from "antd"
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    SwapOutlined,
} from "@ant-design/icons"
import { FlexCol, FlexRow } from "../components/commons-ts/common"
import {
    navigatorSupportsWebBle,
    requestBleDevice,
    useUsercommContextBLE,
} from "../usercomm/local/ble/usercommProviderBLE"
import { Translated } from "../utils/translated"
import { COLOR_BG_GREEN, COLOR_BG_ORANGE, COLOR_BG_RED } from "../utils/utils"
import platform from "platform"

export const WebBleNotSupportedWidget: FC = () => {
    let _platformName = platform.name
    let _platformOSFamily = platform.os?.family
    // Linux, OS X: Chrome
    let _alternatives: ReactElement = (
        <b>
            <a href="https://www.google.com/chrome/" target="_blank">
                Chrome
            </a>
        </b>
    )
    // iOS: Bluefy or WebBLE
    if (_platformOSFamily === "iOS") {
        _alternatives = (
            <>
                <b>
                    <a
                        href="https://apps.apple.com/us/app/bluefy-web-ble-browser/id1492822055"
                        target="_blank"
                    >
                        Bluefy
                    </a>
                </b>{" "}
                or{" "}
                <b>
                    <a
                        href="https://apps.apple.com/us/app/webble/id1193531073"
                        target="_blank"
                    >
                        WebBLE
                    </a>
                </b>
            </>
        )
    } else if (_platformOSFamily === "Android") {
        // Android: Chrome or Samsung Internet
        _alternatives = (
            <>
                <b>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.android.chrome"
                        target="_blank"
                    >
                        Chrome
                    </a>
                </b>{" "}
                or{" "}
                <b>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.sec.android.app.sbrowser"
                        target="_blank"
                    >
                        Samsung Internet
                    </a>
                </b>
            </>
        )
    } else if (_platformOSFamily === "Windows") {
        // Windows: Chrome or Edge (w/o new permissions backend)
        _alternatives = (
            <>
                <b>
                    <a href="https://www.google.com/chrome/" target="_blank">
                        Chrome
                    </a>
                </b>{" "}
                or{" "}
                <b style={{ cursor: "help" }}>
                    <a
                        href="https://www.microsoft.com/en-us/edge"
                        target="_blank"
                    >
                        Edge
                    </a>
                    <Tooltip
                        overlay={
                            <Translated keyEn="Note: Edge does not support automatic reconnects" />
                        }
                        placement="bottom"
                        overlayStyle={{ textAlign: "center" }}
                    >
                        *
                    </Tooltip>
                </b>
            </>
        )
    }
    return (
        <FlexCol
            style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                gap: 0,
            }}
        >
            <Typography.Text
                style={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                }}
            >
                <Translated keyEn="Sorry" />, {_platformName ?? "This browser"}{" "}
                <Translated keyEn="does not support Bluetooth Low Energy" />
            </Typography.Text>
            <Typography.Text style={{ textAlign: "center" }}>
                <Translated keyEn="On" /> <b>{_platformOSFamily}</b>,{" "}
                <Translated keyEn="please use" /> {_alternatives}
            </Typography.Text>
        </FlexCol>
    )
}

export const PairAndConnectivityWidget: FC<{
    name: string
    isConnected: boolean
    isPaired: boolean
    onPair: () => Promise<void>
}> = ({ name, isConnected, isPaired, onPair }) => {
    const {
        token: { colorSuccessText, colorInfoText, colorErrorText },
    } = theme.useToken()

    const memoTooltipOverlayElement = useMemo(() => {
        if (isConnected) {
            return <Translated keyEn={`${name} is connected`} />
        } else {
            if (isPaired) {
                return (
                    <Translated
                        keyEn={`${name} is paired, click to reconnect!`}
                    />
                )
            } else {
                return <Translated keyEn={`Click to pair with ${name}`} />
            }
        }
    }, [isConnected, isPaired, name])

    const memoIconColor = useMemo(() => {
        if (isConnected) {
            return colorSuccessText
        } else {
            if (isPaired) {
                return colorInfoText
            } else {
                return colorErrorText
            }
        }
    }, [isConnected, isPaired, colorSuccessText, colorInfoText, colorErrorText])

    return (
        <FlexCol
            style={{
                alignItems: "center",
                gap: 0,
            }}
        >
            <Tooltip
                overlayInnerStyle={{ textAlign: "center" }}
                overlay={memoTooltipOverlayElement}
            >
                <SwapOutlined
                    style={{
                        color: memoIconColor,
                        fontSize: "3rem",
                    }}
                    onClick={onPair}
                />
            </Tooltip>
            <Translated keyEn={name} />
        </FlexCol>
    )
}

export const BleConnectPage: FC = () => {
    const {
        bleIsPaired,
        bleIsConnected,
        bleIsConnecting,
        connectBleGatt,
        disconnectBleGatt,
        forgetBleGatt,
    } = useUsercommContextBLE()

    const onDisconnect = useCallback(async () => {
        await disconnectBleGatt()
        await forgetBleGatt()
    }, [disconnectBleGatt, forgetBleGatt])

    const [bleIsConnectingSinceMs, setBleIsConnectingSinceMs] =
        useState<number>(0)
    useEffect(() => {
        if (!bleIsConnecting) {
            return
        }
        setBleIsConnectingSinceMs(0)
        let intMs = 500
        let t = setInterval(() => {
            setBleIsConnectingSinceMs((prev) => prev + intMs)
        }, intMs)
        return () => {
            clearTimeout(t)
        }
    }, [bleIsConnecting, setBleIsConnectingSinceMs])

    const memoExperimentalWebBleBackendWidget =
        useMemo((): ReactElement | null => {
            let _platformName = platform.name
            if (_platformName === undefined) {
                return null
            }
            if (_platformName.startsWith("Microsoft Edge")) {
                return (
                    <>
                        <Translated keyEn="Edge does not support automatic reconnects." />
                        <span>
                            <Translated keyEn="Please consider using Chrome for ease of use" />{" "}
                            <ExclamationCircleOutlined
                                style={{ color: COLOR_BG_ORANGE }}
                            />
                        </span>
                    </>
                )
            }
            if (
                navigator.bluetooth !== undefined &&
                navigator.bluetooth.getDevices !== undefined
            ) {
                // enabled
                return (
                    <div>
                        <Translated keyEn="You have automatic reconnects enabled!" />{" "}
                        <CheckCircleOutlined
                            style={{ color: COLOR_BG_GREEN }}
                        />
                    </div>
                )
            } else {
                // possible but not yet enabled
                let link = null
                if (_platformName.startsWith("Chrome")) {
                    link =
                        "chrome://flags/#enable-web-bluetooth-new-permissions-backend"
                } else if (_platformName.startsWith("Samsung")) {
                    link =
                        "internet://flags/#enable-web-bluetooth-new-permissions-backend"
                }
                return (
                    <>
                        <div>
                            <Translated keyEn="Automatic reconnects are not yet enabled" />{" "}
                            <CloseCircleOutlined
                                style={{ color: COLOR_BG_RED }}
                            />
                        </div>
                        <div>
                            <Translated keyEn="Manually copy the link below into the address bar and enable the experimental feature" />
                        </div>
                        {link && (
                            <Typography.Text code copyable>
                                {link}
                            </Typography.Text>
                        )}
                    </>
                )
            }
        }, [])

    if (!navigatorSupportsWebBle()) {
        return <WebBleNotSupportedWidget />
    }

    return (
        <FlexCol
            style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                gap: 0,
            }}
        >
            <Typography.Title
                level={3}
                style={{
                    marginBottom: 0,
                }}
            >
                {bleIsConnected ? (
                    <Translated keyEn="Station Connected" />
                ) : (
                    <Translated keyEn="Station Disconnected" />
                )}
            </Typography.Title>
            <Typography.Title
                level={5}
                style={{
                    marginTop: 0,
                    marginBottom: 30,
                }}
            >
                {bleIsConnected ? (
                    <Translated keyEn="You can use the app" />
                ) : (
                    <Translated keyEn="Connect to Base Station in order to start" />
                )}
            </Typography.Title>
            <FlexRow
                style={{
                    justifyContent: "center",
                }}
            >
                <PairAndConnectivityWidget
                    name="Base Station"
                    isConnected={bleIsConnected}
                    isPaired={bleIsPaired}
                    onPair={async () => {
                        try {
                            let device = await requestBleDevice()
                            console.log(
                                "BLEConnectPage: selected device:",
                                device,
                            )
                            connectBleGatt(device)
                        } catch (e) {}
                    }}
                />
            </FlexRow>
            <FlexCol
                style={{
                    height: 70,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {bleIsConnecting && (
                    <>
                        <Spin size="large" spinning={bleIsConnecting} />
                        <span>{Math.round(bleIsConnectingSinceMs / 1e3)}s</span>
                    </>
                )}
            </FlexCol>
            <FlexCol
                style={{
                    textAlign: "center",
                    maxWidth: "80%",
                }}
            >
                <i>
                    <span>
                        <Translated keyEn="Hint: If you are using the app for the" />{" "}
                        <b>
                            <Translated keyEn="first time" />
                        </b>
                        , <Translated keyEn="click on" />{" "}
                        <SwapOutlined style={{ fontSize: "1.5rem" }} />
                    </span>
                    <br />
                    <span>
                        <Translated keyEn="You should select" />{" "}
                        <b>
                            <Translated keyEn="LU XXXXXX" />
                        </b>{" "}
                        <Translated keyEn="as indicated on your device" />
                    </span>
                </i>
            </FlexCol>
            <FlexCol
                style={{
                    marginTop: 20,
                }}
            >
                <Button size="large" onClick={onDisconnect}>
                    <Translated keyEn="Disconnect" />
                </Button>
            </FlexCol>
            <FlexCol
                style={{
                    marginTop: 50,
                    gap: 0,
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    // italic
                    fontStyle: "italic",
                }}
            >
                {memoExperimentalWebBleBackendWidget}
            </FlexCol>
        </FlexCol>
    )
}
