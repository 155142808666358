import { FC, ReactElement, useEffect, useMemo, useState } from "react"
import {
    ECloudRole,
    ESyncDisposition,
    ICloudDevice,
    ICloudUser,
} from "../../types"
import Table, { ColumnsType } from "antd/es/table"
import { Translated } from "../../utils/translated"
import {
    Avatar,
    Button,
    Col,
    Image,
    Input,
    Popconfirm,
    Progress,
    Row,
    Tooltip,
} from "antd"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { FlexCol, FlexRow } from "../../components/commons-ts/common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFlask } from "@fortawesome/free-solid-svg-icons"
import { Label } from "../../components/commons-ts/input"
import { MethodTypeEN1177, NormType, Site } from "../../generated/proto-ts/main"
import {
    COLOR_BG_GREEN,
    COLOR_BG_LIGHT_BLUE,
    COLOR_BG_ROW_DELETED,
    pbUUIDToUuid,
} from "../../utils/utils"
import {
    CheckCircleFilled,
    CheckCircleOutlined,
    CloudDownloadOutlined,
    CloudOutlined,
    CloudUploadOutlined,
    DeleteFilled,
    FilePdfOutlined,
    MinusSquareOutlined,
    PlusSquareOutlined,
} from "@ant-design/icons"
import { useSyncronizationContext } from "../../providers/syncronizationProvider"
import { UserAvatar } from "../../components/commons-ts/userWidget"

const renderAsLink = (value: any, site: Site) => {
    return (
        <Link
            to={{
                pathname: `/sites/${pbUUIDToUuid(site.uuid)}`,
            }}
            style={{
                textDecoration: "none",
            }}
        >
            {value}
        </Link>
    )
}

const SummarySitesTableSyncWidget: FC<{
    siteUUID: string
}> = ({ siteUUID }) => {
    const { aggregatedSyncProgressMap } = useSyncronizationContext()

    let siteProgressAndDisposition = aggregatedSyncProgressMap[siteUUID]
    if (siteProgressAndDisposition === undefined) {
        return null
    }

    let iconStyle = {
        fontSize: "inherit",
        color: "inherit",
    }
    let dispositionIcon = null
    let progressIcon = null

    if (siteProgressAndDisposition.disposition === ESyncDisposition.LOCAL) {
        dispositionIcon = (
            <Tooltip overlay={<Translated keyEn="Local" />}>
                <CheckCircleOutlined
                    style={{
                        ...iconStyle,
                        color: COLOR_BG_GREEN,
                    }}
                />
            </Tooltip>
        )
    } else if (
        siteProgressAndDisposition.disposition === ESyncDisposition.REMOTE
    ) {
        dispositionIcon = (
            <Tooltip overlay={<Translated keyEn="Remote" />}>
                <CloudOutlined
                    style={{
                        ...iconStyle,
                        color: COLOR_BG_LIGHT_BLUE,
                    }}
                />
            </Tooltip>
        )
    } else {
        // Both
        // Only show if progress down/up is 1
        if (
            siteProgressAndDisposition.progressDown === 1 ||
            siteProgressAndDisposition.progressUp === 1
        ) {
            dispositionIcon = (
                <Tooltip overlay={<Translated keyEn="Synced" />}>
                    <CheckCircleFilled
                        style={{
                            ...iconStyle,
                            color: COLOR_BG_GREEN,
                        }}
                    />
                </Tooltip>
            )
        }
    }

    if (
        siteProgressAndDisposition.progressUp > 0 &&
        siteProgressAndDisposition.progressUp < 1
    ) {
        progressIcon = (
            <Progress
                type="circle"
                size={30}
                percent={100 * siteProgressAndDisposition.progressUp}
                format={() => {
                    return (
                        <CloudUploadOutlined
                            style={{
                                fontSize: "1.1rem",
                            }}
                        />
                    )
                }}
            />
        )
    } else if (
        siteProgressAndDisposition.progressDown > 0 &&
        siteProgressAndDisposition.progressDown < 1
    ) {
        progressIcon = (
            <Progress
                type="circle"
                size={30}
                percent={100 * siteProgressAndDisposition.progressDown}
                format={() => {
                    return (
                        <CloudDownloadOutlined
                            style={{
                                fontSize: "1.1rem",
                            }}
                        />
                    )
                }}
            />
        )
    }

    return (
        <FlexRow
            style={{
                fontSize: 30,
                justifyContent: "center",
            }}
        >
            {dispositionIcon}
            {progressIcon}
        </FlexRow>
    )
}

const SummarySitesTableUserWidget: FC<{
    siteUserUUID: string | null
}> = ({ siteUserUUID }) => {
    const { enterpriseUsers } = useSyncronizationContext()
    const [user, setUser] = useState<ICloudUser | null>(null)
    useEffect(() => {
        if (enterpriseUsers === null || siteUserUUID === null) {
            return
        }
        let _user = enterpriseUsers.find((u) => u.UUID === siteUserUUID)
        if (_user === undefined) {
            return
        }
        setUser(_user)
    }, [siteUserUUID, enterpriseUsers])

    return <UserAvatar user={user} />
}

export const SummarySitesTable: FC<{
    sites: Site[] | null
    setClonedSite: (site: Site | null) => void
    onDeleteSite: (site: Site) => void
}> = ({ sites, setClonedSite, onDeleteSite }) => {
    const [remoteDeviceMap, setRemoteDeviceMap] = useState<Record<
        ICloudDevice["SerialNumber"],
        ICloudDevice
    > | null>(null)
    const [filteredSites, setFilteredSites] = useState<Site[] | null>(null)
    const [searchValue, setSearchValue] = useState<string>("")

    const { enterpriseDevices, enterpriseUsers, isAdmin } =
        useSyncronizationContext()

    useEffect(() => {
        if (enterpriseDevices === null) {
            return
        }
        let _remoteDeviceMap: Record<
            ICloudDevice["SerialNumber"],
            ICloudDevice
        > = {}
        for (let device of enterpriseDevices) {
            _remoteDeviceMap[device.SerialNumber] = device
        }
        setRemoteDeviceMap(_remoteDeviceMap)
    }, [enterpriseDevices])

    useEffect(() => {
        if (sites === null) {
            setFilteredSites(null)
            return
        }
        if (searchValue === "") {
            setFilteredSites(sites)
            return
        }

        const t = setTimeout(() => {
            let _filteredSites: Site[] = []
            let normalizedSearchValue = searchValue.toLowerCase().normalize()
            for (let site of sites) {
                if (
                    site.mission_name
                        .toLowerCase()
                        .normalize()
                        .includes(normalizedSearchValue)
                ) {
                    _filteredSites.push(site)
                    continue
                }
                if (
                    site.site_name
                        .toLowerCase()
                        .normalize()
                        .includes(normalizedSearchValue)
                ) {
                    _filteredSites.push(site)
                    continue
                }
                if (
                    site.client_name
                        .toLowerCase()
                        .normalize()
                        .includes(normalizedSearchValue)
                ) {
                    _filteredSites.push(site)
                    continue
                }
            }
            setFilteredSites(_filteredSites)
        }, 250)
        return () => {
            clearTimeout(t)
        }
    }, [searchValue, sites])

    const columns = useMemo((): ColumnsType<Site> => {
        console.log(`SitesTable: columns useMemo`)
        return [
            {
                title: <Translated keyEn="User" />,
                render: (_, site) => {
                    return (
                        <SummarySitesTableUserWidget
                            siteUserUUID={pbUUIDToUuid(site.user_uuid)}
                        />
                    )
                },
            },
            {
                title: <Translated keyEn="Norm" />,
                render: (_, site) => {
                    let normElement: ReactElement | null = null
                    switch (site.norm_type) {
                        case NormType.EN_1177:
                            normElement = (
                                <Tooltip
                                    overlay={
                                        <Translated keyEn="Playground floor" />
                                    }
                                >
                                    <Avatar
                                        style={{
                                            color: "#cf1322", // red-7
                                            backgroundColor: "#ffccc7", // red-2
                                        }}
                                    >
                                        <Translated keyEn="PG" />
                                    </Avatar>
                                </Tooltip>
                            )
                            break
                        case NormType.EN_12503:
                            normElement = (
                                <Tooltip
                                    overlay={<Translated keyEn="Sports mat" />}
                                >
                                    <Avatar
                                        style={{
                                            color: "#389e0d", // green-7
                                            backgroundColor: "#d9f7be", // green-2
                                        }}
                                    >
                                        <Translated keyEn="SM" />
                                    </Avatar>
                                </Tooltip>
                            )
                            break
                        case NormType.EN_14960:
                            normElement = (
                                <Tooltip
                                    overlay={<Translated keyEn="Airbags" />}
                                >
                                    <Avatar
                                        style={{
                                            color: "#389e0d", // green-7
                                            backgroundColor: "#d9f7be", // green-2
                                        }}
                                    >
                                        <Translated keyEn="AIR" />
                                    </Avatar>
                                </Tooltip>
                            )
                            break
                        case NormType.EN_ISO_23659:
                            normElement = (
                                <Tooltip
                                    overlay={<Translated keyEn="Trampolines" />}
                                >
                                    <Avatar
                                        style={{
                                            color: "#389e0d", // green-7
                                            backgroundColor: "#d9f7be", // green-2
                                        }}
                                    >
                                        <Translated keyEn="TL" />
                                    </Avatar>
                                </Tooltip>
                            )
                            break
                    }
                    let methodElement: ReactElement | null = null
                    switch (site.method_type_en_1177) {
                        case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
                            methodElement = (
                                <Tooltip
                                    overlay={
                                        <Translated keyEn="Critical fall height" />
                                    }
                                >
                                    <Avatar
                                        style={{
                                            color: "#cf1322", // magenta-7
                                            backgroundColor: "#ffd6e7", // magenta-2
                                        }}
                                    >
                                        <Translated keyEn="CFH" />
                                    </Avatar>
                                </Tooltip>
                            )
                            break
                        case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
                            methodElement = (
                                <Tooltip
                                    overlay={<Translated keyEn="Adequacy" />}
                                >
                                    <Avatar
                                        style={{
                                            color: "#9254de", // purple-5
                                            backgroundColor: "#efdbff", // purple-2
                                        }}
                                    >
                                        <Translated keyEn="ADQ" />
                                    </Avatar>
                                </Tooltip>
                            )
                            break
                    }
                    if (site.is_lab_test) {
                        methodElement = (
                            <FlexRow
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {methodElement}{" "}
                                <FontAwesomeIcon
                                    icon={faFlask}
                                    style={{
                                        color: "#f759ab", // magenta-5
                                    }}
                                />
                            </FlexRow>
                        )
                    }
                    return (
                        <FlexRow
                            style={{
                                gap: 5,
                                justifyContent: "start",
                                alignItems: "center",
                            }}
                        >
                            {normElement}
                            {methodElement}
                        </FlexRow>
                    )
                },
                sorter: (a, b) => {
                    return b.norm_type - a.norm_type
                },
                filters: [
                    {
                        text: <Translated keyEn="Playground floor" />,
                        value: NormType.EN_1177,
                    },
                    {
                        text: <Translated keyEn="Sports mat" />,
                        value: NormType.EN_12503,
                    },
                ],
                onFilter: (value, record) => {
                    return record.norm_type === value
                },
            },
            // {
            //     title: <Translated keyEn="Method" />,
            //     render: (_, site) => {

            //     },
            //     sorter: (a, b) => {
            //         return (
            //             (b.method_type_en_1177 ?? 0) -
            //             (a.method_type_en_1177 ?? 0)
            //         )
            //     },
            //     filters: [
            //         {
            //             text: <Translated keyEn="CFH" />,
            //             value: MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION,
            //         },
            //         {
            //             text: <Translated keyEn="ADQ" />,
            //             value: MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE,
            //         },
            //     ],
            //     onFilter: (value, record) => {
            //         // If no method is specified, show the row
            //         return (
            //             !record.method_type_en_1177 ||
            //             record.method_type_en_1177 === value
            //         )
            //     },
            // },
            {
                title: <Translated keyEn="Title" />,
                render: (_, site) => {
                    let _title = site.site_name
                    let _subtitle = ""
                    if (site.mission_name) {
                        _subtitle += `${site.mission_name}`
                    }
                    if (site.client_name) {
                        _subtitle += ` @ ${site.client_name}`
                    }
                    let el = (
                        <FlexCol
                            style={{
                                gap: 0,
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                            }}
                        >
                            <b style={{ fontSize: "1.1rem" }}>{_title}</b>
                            <i style={{ whiteSpace: "nowrap" }}>{_subtitle}</i>
                        </FlexCol>
                    )
                    return renderAsLink(el, site)
                },
            },
            {
                title: <Translated keyEn="Sync" />,
                render: (_, site) => {
                    return (
                        <SummarySitesTableSyncWidget
                            siteUUID={pbUUIDToUuid(site.uuid)}
                        />
                    )
                },
            },
            {
                title: <Translated keyEn="Device" />,
                render: (_, site) => {
                    let deviceSnStr = "N/A"
                    let deviceSnBigInt = BigInt(site.device_sn)
                    if (deviceSnBigInt !== BigInt(0)) {
                        deviceSnStr = deviceSnBigInt
                            .toString(16)
                            .padStart(16, "0")
                    }
                    if (
                        deviceSnBigInt !== BigInt(0) &&
                        remoteDeviceMap !== null
                    ) {
                        let remoteDevice = remoteDeviceMap[deviceSnStr]
                        if (remoteDevice !== undefined && remoteDevice.Name) {
                            deviceSnStr = remoteDevice.Name.padStart(4, "0")
                        }
                    }
                    return <i>{deviceSnStr}</i>
                    // return renderAsLink(deviceIDStr, site)
                },
                sorter: (a, b) => {
                    return (b.device_sn ?? 0) > (a.device_sn ?? 0) ? 1 : -1
                },
            },
            {
                title: <Translated keyEn="Date" />,
                render: (_, site) => {
                    if (site.execution_date === 0) {
                        let formattedDate = dayjs(site.created_at).format(
                            "YYYY-MM-DD",
                        )
                        return (
                            <i style={{ whiteSpace: "nowrap" }}>
                                {formattedDate}
                            </i>
                        )
                    } else {
                        let formattedDate = dayjs(site.execution_date).format(
                            "YYYY-MM-DD",
                        )
                        return (
                            <i style={{ whiteSpace: "nowrap" }}>
                                {formattedDate}
                            </i>
                        )
                    }
                },
                sorter: (a, b) => {
                    let dateA = a.execution_date ?? a.created_at
                    let dateB = b.execution_date ?? b.created_at
                    return dayjs(dateB).unix() - dayjs(dateA).unix()
                },
                defaultSortOrder: "descend",
            },
            {
                title: "",
                width: "1rem",
                render: (_, site) => {
                    return (
                        <FlexRow
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 0,
                            }}
                        >
                            {/* Report */}
                            <Tooltip
                                overlay={<Translated keyEn="Generate report" />}
                            >
                                <Link to={`/report/${pbUUIDToUuid(site.uuid)}`}>
                                    <Button
                                        type="link"
                                        icon={<FilePdfOutlined />}
                                    />
                                </Link>
                            </Tooltip>
                            {/* Duplicate */}
                            {/* <Tooltip overlay={<Translated keyEn="Duplicate" />}>
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    onClick={() => {
                                        setClonedSite(site)
                                    }}
                                    size="lg"
                                    style={{
                                        color: COLOR_BG_GRAY,
                                        cursor: "pointer",
                                    }}
                                />
                            </Tooltip> */}
                            {/* Delete */}
                            <Tooltip overlay={<Translated keyEn="Delete" />}>
                                <Popconfirm
                                    title="Are you sure to delete this site?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => {
                                        onDeleteSite(site)
                                    }}
                                >
                                    <Button
                                        danger
                                        icon={<DeleteFilled />}
                                        type="text"
                                    />
                                </Popconfirm>
                            </Tooltip>
                        </FlexRow>
                    )
                },
            },
        ]
    }, [isAdmin, remoteDeviceMap])

    return (
        <FlexCol>
            <FlexCol style={{ gap: 0 }}>
                <Label>
                    <Translated keyEn={"Universal search"} />
                </Label>
                <Input
                    size="large"
                    value={searchValue ?? ""}
                    placeholder={"Search for mission, site, client.."}
                    onChange={(value) => {
                        setSearchValue(value.target.value)
                    }}
                    variant="filled"
                />
            </FlexCol>
            <Table
                size="small"
                dataSource={filteredSites ?? []}
                loading={sites === null}
                columns={columns}
                pagination={{
                    defaultPageSize: 20,
                    hideOnSinglePage: true,
                }}
                expandable={{
                    expandIcon: ({ expanded, onExpand, record }) => {
                        if (expanded) {
                            return (
                                <MinusSquareOutlined
                                    onClick={(e) => onExpand(record, e)}
                                />
                            )
                        } else {
                            return (
                                <PlusSquareOutlined
                                    onClick={(e) => onExpand(record, e)}
                                />
                            )
                        }
                    },
                    expandedRowRender: (site) => {
                        let user = enterpriseUsers?.find(
                            (u) => u.UUID === pbUUIDToUuid(site.user_uuid),
                        )
                        return (
                            <>
                                <Row gutter={[10, 10]}>
                                    <Col xs={24} md={12}>
                                        <Label>
                                            <Translated keyEn="Details" />
                                        </Label>
                                        <ul>
                                            {/* <li>
                                                <b>UUID:</b>{" "}
                                                {pbUUIDToUuid(site.uuid)}
                                            </li> */}
                                            <li>
                                                <b>
                                                    <Translated keyEn="Created at" />
                                                </b>{" "}
                                                {dayjs(site.created_at).format(
                                                    "YYYY-MM-DD HH:mm",
                                                )}
                                            </li>
                                            <li>
                                                <b>
                                                    <Translated keyEn="Updated at" />
                                                </b>{" "}
                                                {dayjs(site.updated_at).format(
                                                    "YYYY-MM-DD HH:mm",
                                                )}
                                            </li>
                                            <li>
                                                <b>
                                                    <Translated keyEn="Executed at" />
                                                </b>{" "}
                                                {dayjs(
                                                    site.execution_date,
                                                ).format("YYYY-MM-DD HH:mm")}
                                            </li>
                                            {user !== undefined && (
                                                <li>
                                                    <b>
                                                        <Translated keyEn="User" />
                                                        :{" "}
                                                    </b>
                                                    {user.FirstName}{" "}
                                                    {user.LastName} (
                                                    {user.Enterprise.Name})
                                                </li>
                                            )}
                                            {site.address && (
                                                <li>
                                                    <b>
                                                        <Translated keyEn="Address" />
                                                    </b>{" "}
                                                    {site.address}
                                                </li>
                                            )}
                                        </ul>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Label>
                                            <Translated keyEn="Picture" />
                                        </Label>
                                        {site.pictures.length > 0 && (
                                            <Image
                                                src={`/api/uploads/${pbUUIDToUuid(site.pictures[0])}`}
                                                height="100px"
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </>
                        )
                    },
                }}
                rowKey="uuid"
                scroll={{ x: true }}
                bordered
                onRow={(site) => {
                    return {
                        style: {
                            backgroundColor:
                                site.deleted_at !== 0
                                    ? COLOR_BG_ROW_DELETED
                                    : "",
                        },
                    }
                }}
            />
        </FlexCol>
    )
}
