import { FC, useCallback, useEffect, useMemo, useState } from "react"
import {
    MAX_WIDTH_CENTRAL_CONTAINER,
    parsePathForEntityUUID,
    pbUUIDToUuid,
    uuidToPbUUID,
} from "../utils/utils"
import { useLocation, useHistory, Link } from "react-router-dom"
import {
    Button,
    Col,
    Empty,
    Input,
    Popconfirm,
    Row,
    Spin,
    Typography,
    message as antdMessage,
} from "antd"
import {
    FlexCol,
    FlexRow,
    UnderlinedSectionTitle,
} from "../components/commons-ts/common"
import { SimplifiedBreadcrumb } from "../components/commons-ts/simplifiedBreadcrumb"
import { Label, LabeledInput } from "../components/commons-ts/input"
import { Translated } from "../utils/translated"
import {
    normTypeElement,
    methodElement,
    isLabTestElement,
} from "../components/commons-ts/tags"
import {
    DeleteOutlined,
    DoubleRightOutlined,
    SaveOutlined,
    VerticalAlignBottomOutlined,
} from "@ant-design/icons"
import { SliderButton } from "./Impact/SliderButton"

import {
    Impact,
    MethodTypeEN1177,
    NormType,
    UUID,
} from "../generated/proto-ts/main"
import { useUsercommSetImpactParentBLE } from "../usercomm/local/ble/usercommAsyncRequestBLE"
import { useUsercommContextBLE } from "../usercomm/local/ble/usercommProviderBLE"
import { ZoneChartPF_CFH } from "../components/commons-ts/uplotCharts"
import { UploadImageListV2 } from "../components/commons-ts/uploadImageV2"
import { DataTreeDrawer } from "../components/commons-ts/dataTreeDrawer"
import {
    ZoneImpactsTablePF_CFH,
    ZoneImpactsTablePF_ADQ,
} from "./Tables/03_ZoneImpacts/ZoneImpactsTable_PF"
import { ZoneImpactsTableSM } from "./Tables/03_ZoneImpacts/ZoneImpactsTable_SM"
import { ZoneImpactsTableTL } from "./Tables/03_ZoneImpacts/ZoneImpactsTable_TL"
import { ZoneImpactsTableAB } from "./Tables/03_ZoneImpacts/ZoneImpactsTable_AB"
import { getCFH_Global } from "../calculus/calculus_PF"
import {
    useUsercommDeleteImpactBimodal,
    useUsercommDeleteZoneBimodal,
    useUsercommEquipmentBimodal,
    useUsercommSiteBimodal,
    useUsercommUpdateZoneBimodal,
    useUsercommZoneBimodal,
    useUsercommZoneImpactsBimodal,
} from "../usercomm/common/usercommAsyncRequestBimodal"
import { useDebounce } from "../utils/debounce"
import dayjs from "dayjs"

export const ZonePage: FC = () => {
    const location = useLocation()
    const history = useHistory()
    const memoZoneUUID = useMemo((): UUID | null => {
        let zoneUUID = parsePathForEntityUUID(location.pathname)
        if (zoneUUID === null) {
            return null
        }
        return uuidToPbUUID(zoneUUID)
    }, [location.pathname])

    // Equipment
    const [equipment, getEquipment] = useUsercommEquipmentBimodal()
    // Equipment fields
    const [equipmentUUIDStr, setEquipmentUUIDStr] = useState<string | null>(
        null,
    ) // use string to avoid effects re-firing on UUID ref change
    const [equipmentName, setEquipmentName] = useState<string | null>(null)

    // Ste
    const [site, getSite] = useUsercommSiteBimodal()
    // Site fields
    const [siteUUIDStr, setSiteUUIDStr] = useState<string | null>(null) // use string to avoid effects re-firing on UUID ref change
    const [siteName, setSiteName] = useState<string | null>(null)
    const [normType, setNormType] = useState<NormType | null>(null)
    const [method, setMethod] = useState<MethodTypeEN1177 | null>(null)
    const [isLabTest, setIsLabTest] = useState<boolean | null>(null)

    // Zone
    const [zone, getZone, externallySetZone] = useUsercommZoneBimodal()
    const [updatedZoneAck, updateZone] = useUsercommUpdateZoneBimodal()
    const [deletedZoneAck, deleteZoneSoft] = useUsercommDeleteZoneBimodal()
    // Zone fields
    const [zoneName, setZoneName] = useState<string | null>(null)
    const [zoneFreeFallHeightMetersStr, setZoneFreeFallHeightMetersStr] =
        useState<string | null>(null)
    const [
        zoneGroundThicknessMillimetersStr,
        setZoneGroundThicknessMillimetersStr,
    ] = useState<string | null>(null)
    const [pictures, setPictures] = useState<UUID[]>([])
    const [zoneComment, setZoneComment] = useState<string | null>(null)
    const [zoneTemperature, setZoneTemperature] = useState<number | null>(null)
    const [zoneHumidity, setZoneHumidity] = useState<number | null>(null)
    const [zoneSpecimenTemperature, setZoneSpecimenTemperature] = useState<
        number | null
    >(null)
    const [zoneSpecimenHumidity, setZoneSpecimenHumidity] = useState<
        number | null
    >(null)

    const [createdAt, setCreatedAt] = useState<number | null>(null)
    const [updatedAt, setUpdatedAt] = useState<number | null>(null)

    // Impacts
    const [impacts, getImpacts] = useUsercommZoneImpactsBimodal()
    const [setImpactParentAck, setImpactParent] =
        useUsercommSetImpactParentBLE()
    const [deletedImpactAck, deleteImpact] = useUsercommDeleteImpactBimodal()

    const [releaseResetTrigger, setReleaseResetTrigger] = useState<number>(0)
    const [isReleaseLoading, setIsReleaseLoading] = useState<boolean>(false)
    const [newImpactUUID, setNewImpactUUID] = useState<string | null>(null)

    const {
        bleIsConnected,
        stationSensors,
        hicRawMeasurementConsumable,

        emitDropHIC,
        consumeHICRawMeasurement,
    } = useUsercommContextBLE()

    // Fetch zone
    useEffect(() => {
        if (memoZoneUUID === null) {
            return
        }
        getZone(memoZoneUUID)
    }, [memoZoneUUID])

    useEffect(() => {
        if (zone === null) {
            return
        }
        console.log(`ZonePage: zone received:`, zone.toObject())
        setZoneName(zone.zone_name)
        let ffhMetres = zone.zone_ffh_max / 100
        setZoneFreeFallHeightMetersStr(ffhMetres.toFixed(2))
        setZoneGroundThicknessMillimetersStr(zone.floor_thickness.toFixed(2))
        setZoneTemperature(zone.zone_temperature)
        setZoneHumidity(zone.zone_humidity)
        setZoneSpecimenTemperature(zone.specimen_temperature)
        setZoneSpecimenHumidity(zone.specimen_humidity)
        setZoneComment(zone.comments)
        setPictures(zone.pictures)
        setEquipmentUUIDStr(pbUUIDToUuid(zone.equipment_uuid))
        setCreatedAt(zone.created_at)
        setUpdatedAt(zone.updated_at)
    }, [zone])

    // // Fetch equipment
    useEffect(() => {
        if (equipmentUUIDStr === null) {
            return
        }
        getEquipment(uuidToPbUUID(equipmentUUIDStr))
    }, [equipmentUUIDStr])

    useEffect(() => {
        if (equipment === null) {
            return
        }
        console.log(`ZonePage: equipment received:`, equipment.toObject())
        setSiteUUIDStr(pbUUIDToUuid(equipment.site_uuid))
        setEquipmentName(equipment.equipment_name)
    }, [equipment])

    // Fetch site
    useEffect(() => {
        if (siteUUIDStr == null) {
            return
        }
        getSite(uuidToPbUUID(siteUUIDStr))
    }, [siteUUIDStr])

    useEffect(() => {
        if (site === null) {
            return
        }
        console.log(`ZonePage: site received:`, site.toObject())
        setSiteName(site.site_name)
        setNormType(site.norm_type)
        setMethod(site.method_type_en_1177)
        setIsLabTest(site.is_lab_test)
    }, [site])

    useEffect(() => {
        if (normType === NormType.EN_ISO_23659) {
            setZoneFreeFallHeightMetersStr("1")
        }
    }, [normType])

    // Fetch impacts
    useEffect(() => {
        if (memoZoneUUID === null) {
            return
        }
        getImpacts(memoZoneUUID)
    }, [memoZoneUUID])

    // Impact reception
    useEffect(() => {
        if (hicRawMeasurementConsumable === null) {
            return
        }
        consumeHICRawMeasurement()
        // console.log(`Zone: newRecord received:`, hicRawMeasurementConsumable)
        // antdMessage.success(<Translated keyEn="Received new impact!" />)
        if (memoZoneUUID === null) {
            return
        }
        setImpactParent(hicRawMeasurementConsumable, memoZoneUUID, Date.now())
        setNewImpactUUID(pbUUIDToUuid(hicRawMeasurementConsumable))
    }, [memoZoneUUID, hicRawMeasurementConsumable])

    useEffect(() => {
        if (setImpactParentAck === null || newImpactUUID === null) {
            return
        }
        antdMessage.success(<Translated keyEn="Received an impact!" />)
        setIsReleaseLoading(false)
        setReleaseResetTrigger(Date.now())
        setTimeout(() => {
            history.push(`/impacts/${newImpactUUID}`)
        }, 1000)
    }, [newImpactUUID, setImpactParentAck])

    // CRUD

    const __zoneMutableFields = [
        memoZoneUUID,
        zone,
        zoneName,
        zoneFreeFallHeightMetersStr,
        zoneGroundThicknessMillimetersStr,
        pictures,
        zoneComment,
        zoneTemperature,
        zoneHumidity,
        zoneSpecimenTemperature,
        zoneSpecimenHumidity,
    ]

    const memoZoneIsMutated = useMemo(() => {
        if (zone === null) {
            return false
        }
        let prevPicturesStr = zone.pictures
            .map((p) => pbUUIDToUuid(p))
            .join(",")
        let currPicturesStr = pictures.map((p) => pbUUIDToUuid(p)).join(",")

        let prevFreeFallHeightMetersStr = (zone.zone_ffh_max / 100).toFixed(2)
        let prevGroundThicknessMillimetersStr = zone.floor_thickness.toFixed(2)

        let isMutated =
            zoneName !== zone.zone_name ||
            zoneFreeFallHeightMetersStr !== prevFreeFallHeightMetersStr ||
            zoneGroundThicknessMillimetersStr !==
                prevGroundThicknessMillimetersStr ||
            zoneComment !== zone.comments ||
            zoneTemperature !== zone.zone_temperature ||
            zoneHumidity !== zone.zone_humidity ||
            zoneSpecimenTemperature !== zone.specimen_temperature ||
            zoneSpecimenHumidity !== zone.specimen_humidity ||
            prevPicturesStr !== currPicturesStr
        // if (isMutated) {
        //     console.debug(`ZonePage: isMutated: ${isMutated}
        //         zoneName: ${zoneName} vs ${zone.zone_name}
        //         zoneFreeFallHeightMetersStr: ${zoneFreeFallHeightMetersStr} vs ${prevFreeFallHeightMetersStr}
        //         zoneGroundThicknessMillimetersStr: ${zoneGroundThicknessMillimetersStr} vs ${prevGroundThicknessMillimetersStr}
        //         zoneComment: ${zoneComment} vs ${zone.comments}
        //         zoneTemperature: ${zoneTemperature} vs ${zone.zone_temperature}
        //         zoneHumidity: ${zoneHumidity} vs ${zone.zone_humidity}
        //         zoneSpecimenTemperature: ${zoneSpecimenTemperature} vs ${zone.specimen_temperature}
        //         zoneSpecimenHumidity: ${zoneSpecimenHumidity} vs ${zone.specimen_humidity}
        //         pictures: ${pictures} vs ${zone.pictures}`)
        // }
        return isMutated
    }, __zoneMutableFields)

    // DELETE ZONE
    const onDelete = useCallback(async () => {
        if (zone === null) {
            return
        }
        deleteZoneSoft(zone.uuid)
    }, [zone])
    // DELETE ZONE ACK
    useEffect(() => {
        if (deletedZoneAck === null || zone === null) {
            return
        }
        antdMessage.info(<>Zone deleted!</>)
        if (equipmentUUIDStr !== null) {
            setTimeout(() => {
                history.push(`/equipments/${equipmentUUIDStr}`)
            }, 1000)
        } else {
            console.warn(
                `ZonePage: received deletedZoneAck but equipmentUUID is null!`,
            )
        }
    }, [deletedZoneAck, equipmentUUIDStr])

    // UPDATE ZONE
    const onUpdate = useDebounce(async () => {
        if (zone === null) {
            return
        }

        if (
            zoneName === null ||
            // zoneFreeFallHeightMetersStr === null ||
            // zoneGroundThicknessMillimetersStr === null ||
            zoneTemperature === null ||
            zoneHumidity === null
            // zoneSpecimenTemperature === null ||
            // zoneSpecimenHumidity === null ||
            // pictures === null ||
            // zoneComment === null
        ) {
            return
        }

        let updatedZone = zone.clone()
        let ffhCm: number | null = null
        if (zoneFreeFallHeightMetersStr !== null) {
            ffhCm = Math.floor(parseFloat(zoneFreeFallHeightMetersStr) * 100)
        }
        let groundThicknessMm: number | null = null
        if (zoneGroundThicknessMillimetersStr !== null) {
            groundThicknessMm = Math.floor(
                parseFloat(zoneGroundThicknessMillimetersStr),
            )
        }
        updatedZone.zone_name = zoneName ?? ""
        updatedZone.zone_ffh_max = ffhCm ?? 0
        updatedZone.floor_thickness = groundThicknessMm ?? 0
        updatedZone.comments = zoneComment ?? ""
        updatedZone.zone_temperature = zoneTemperature ?? 0
        updatedZone.zone_humidity = zoneHumidity ?? 0
        updatedZone.specimen_temperature = zoneSpecimenTemperature ?? 0
        updatedZone.specimen_humidity = zoneSpecimenHumidity ?? 0
        updatedZone.pictures = pictures
        updatedZone.updated_at = Date.now()
        updateZone(updatedZone)
        externallySetZone(updatedZone)
    })
    // UPDATE ZONE ACK
    useEffect(() => {
        if (updatedZoneAck === null || memoZoneUUID === null) {
            return
        }
        console.log(`ZonePage: updatedZoneAck:`, updatedZoneAck)
    }, [updatedZoneAck, memoZoneUUID])

    // TRIGGER UPDATE
    useEffect(() => {
        console.log(`ZonePage: memoZoneIsMutated:`, memoZoneIsMutated)
        if (memoZoneIsMutated === false) {
            return
        }
        onUpdate()
    }, [memoZoneIsMutated])

    // DELETE IMPACT
    const onDeleteImpact = useCallback(async (impact: Impact | null) => {
        if (impact === null) {
            return
        }
        deleteImpact(impact.uuid)
    }, [])
    // DELETE IMPACT ACK
    useEffect(() => {
        if (deletedImpactAck === null || memoZoneUUID === null) {
            return
        }
        antdMessage.info(<>Impact was successfully deleted!</>)
        getImpacts(memoZoneUUID)
    }, [deletedImpactAck, memoZoneUUID])

    useEffect(() => {
        if (impacts === null) {
            return
        }
        console.log(
            `ZonePage: impacts received:`,
            impacts.map((i) => i.toObject()),
        )
    }, [impacts])

    const memoNormTypeElement = useMemo(() => {
        return normTypeElement(normType)
    }, [normType])

    const memoMethodElement = useMemo(() => {
        if (normType === null || normType === NormType.EN_12503) {
            return null
        }
        return methodElement(method)
    }, [normType, method])

    const memoIsLabTestElement = useMemo(() => {
        return isLabTestElement(isLabTest)
    }, [isLabTest])

    const memoImpactsTable = useMemo(() => {
        if (normType === null) {
            return null
        }
        if (normType === NormType.EN_12503) {
            return (
                <ZoneImpactsTableSM
                    impacts={impacts}
                    equipment={equipment}
                    currentImpactUUID={newImpactUUID}
                    onDeleteImpact={onDeleteImpact}
                />
            )
        } else if (normType === NormType.EN_14960) {
            return (
                <ZoneImpactsTableAB
                    impacts={impacts}
                    currentImpactUUID={newImpactUUID}
                    zone={zone}
                    onDeleteImpact={onDeleteImpact}
                />
            )
        } else if (normType === NormType.EN_ISO_23659) {
            let impactorWeight: number | null = null
            if (equipment !== null) {
                impactorWeight = equipment.trampoline_impactor_weight
            }
            if (impactorWeight === null || impactorWeight === 0) {
                impactorWeight = 6.25
            }
            return (
                <ZoneImpactsTableTL
                    impacts={impacts}
                    equipmentImpactorWeight={impactorWeight}
                    currentImpactUUID={newImpactUUID}
                    onDeleteImpact={onDeleteImpact}
                />
            )
        } else if (normType === NormType.EN_1177) {
            if (
                method === MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
            ) {
                return (
                    <ZoneImpactsTablePF_CFH
                        impacts={impacts}
                        currentImpactUUID={newImpactUUID}
                        zone={zone}
                        onDeleteImpact={onDeleteImpact}
                    />
                )
            } else if (
                method === MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE
            ) {
                return (
                    <ZoneImpactsTablePF_ADQ
                        impacts={impacts}
                        currentImpactUUID={newImpactUUID}
                        onDeleteImpact={onDeleteImpact}
                    />
                )
            }
        }
    }, [method, zoneFreeFallHeightMetersStr, impacts, normType, equipment])

    const memoCalculatedCriticalFallHeight = useMemo((): number | null => {
        if (
            impacts === null ||
            method !== MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
        ) {
            return null
        }
        let cfhMeanStd = getCFH_Global(impacts)
        if (cfhMeanStd === null) {
            return null
        }
        return cfhMeanStd.mean // meters
    }, [method, impacts])

    const onReleaseCB = useCallback(() => {
        if (!bleIsConnected) {
            antdMessage.warning(
                <Translated keyEn="No connection to the station, please double check!" />,
            )
            return
        }
        emitDropHIC()
        setIsReleaseLoading(true)
        setTimeout(() => {
            setIsReleaseLoading(false)
            setReleaseResetTrigger(Date.now())
        }, 10e3)
    }, [bleIsConnected])

    if (zone === null) {
        return (
            <FlexCol
                style={{
                    width: "100%",
                    height: "60vh",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <>
            <FlexCol
                style={{
                    width: "100%",
                    maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                    margin: "auto",
                    gap: 30,
                    marginBottom: "2rem",
                }}
            >
                <SimplifiedBreadcrumb
                    previousItems={[
                        {
                            href: `/sites/${siteUUIDStr}`,
                            label: siteName,
                        },
                        {
                            href: `/equipments/${equipmentUUIDStr}`,
                            label: equipmentName,
                        },
                    ]}
                    currentItem={{ label: zoneName }}
                />
                <Row
                    justify="space-between"
                    style={{
                        marginTop: "1rem",
                    }}
                >
                    <Col>
                        <FlexCol style={{ gap: 0 }}>
                            <Label>
                                <Translated keyEn="Zone to test" />
                            </Label>
                            <Typography.Text
                                style={{
                                    fontSize: "2rem",
                                }}
                            >
                                {zoneName}
                            </Typography.Text>
                        </FlexCol>
                    </Col>
                    <Col>
                        <FlexCol style={{ gap: 3 }}>
                            {memoNormTypeElement}
                            {memoMethodElement}
                            {memoIsLabTestElement}
                        </FlexCol>
                    </Col>
                </Row>
                {/* Zone name */}
                <LabeledInput
                    translatableLabel={"Zone name"}
                    value={zoneName}
                    setValue={setZoneName}
                />
                {/* Impacts Chart */}
                {normType === NormType.EN_1177 &&
                    method ===
                        MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION && (
                        <div>
                            <UnderlinedSectionTitle>
                                <Translated keyEn="CFH polynomial" />
                            </UnderlinedSectionTitle>
                            {impacts && impacts.length > 0 ? (
                                <FlexCol
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "400px",
                                        marginBottom: "2rem",
                                    }}
                                >
                                    <ZoneChartPF_CFH
                                        zone={zone}
                                        impacts={impacts}
                                    />
                                </FlexCol>
                            ) : (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        <Translated keyEn="No impacts" />
                                    }
                                />
                            )}
                        </div>
                    )}
                {/* Zone impacts */}
                <div>
                    <Label>
                        <Translated keyEn="Impacts" />
                    </Label>
                    {memoImpactsTable}
                </div>
                {/* Zone picture */}
                <Label>
                    <Translated keyEn="Picture" />
                </Label>
                <UploadImageListV2
                    pictures={pictures}
                    setPictures={setPictures}
                />
                {/* Zone measured values */}
                <div>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Measured values" />
                    </UnderlinedSectionTitle>
                    <Row
                        gutter={[10, 10]}
                        style={{
                            marginTop: 20,
                            justifyContent: "end",
                            alignItems: "end",
                        }}
                    >
                        {memoCalculatedCriticalFallHeight !== null && (
                            <Col flex="auto">
                                <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated keyEn="Calculated CFH" />
                                    </Label>
                                    <Input
                                        size="large"
                                        value={
                                            memoCalculatedCriticalFallHeight
                                                ? memoCalculatedCriticalFallHeight.toFixed(
                                                      1,
                                                  )
                                                : ""
                                        }
                                        disabled={true}
                                        variant="filled"
                                        addonAfter="m"
                                    />
                                </FlexCol>
                            </Col>
                        )}
                        <Col>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn="Temperature" />
                                </Label>
                                <Input
                                    size="large"
                                    value={zoneTemperature ?? ""}
                                    onChange={(value) => {
                                        if (value.target.value === "") {
                                            setZoneTemperature(null)
                                            return
                                        }
                                        setZoneTemperature(
                                            parseInt(value.target.value),
                                        )
                                    }}
                                    style={{
                                        maxWidth: "10rem",
                                    }}
                                    // disabled={true}
                                    variant="filled"
                                    addonAfter="°C"
                                />
                            </FlexCol>
                        </Col>
                        <Col>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn="Humidity" />
                                </Label>
                                <Input
                                    size="large"
                                    value={zoneHumidity ?? ""}
                                    onChange={(value) => {
                                        if (value.target.value === "") {
                                            setZoneHumidity(null)
                                            return
                                        }
                                        setZoneHumidity(
                                            parseInt(value.target.value),
                                        )
                                    }}
                                    style={{
                                        maxWidth: "10rem",
                                    }}
                                    // disabled={true}
                                    variant="filled"
                                    addonAfter="%"
                                />
                            </FlexCol>
                        </Col>
                        <Col>
                            <Button
                                size="large"
                                type="primary"
                                disabled={stationSensors === null}
                                onClick={() => {
                                    if (stationSensors === null) {
                                        return
                                    }
                                    setZoneTemperature(
                                        Math.round(stationSensors.temperature),
                                    )
                                    setZoneHumidity(
                                        Math.round(
                                            stationSensors.relative_humidity,
                                        ),
                                    )
                                }}
                                icon={<VerticalAlignBottomOutlined />}
                            >
                                Auto
                            </Button>
                        </Col>
                    </Row>
                </div>
                {/* New impact slider button */}
                <div className="flex-col mx-1">
                    <SliderButton
                        onReleaseHIC={onReleaseCB}
                        isLoading={isReleaseLoading}
                        resetTrigger={releaseResetTrigger}
                    />
                    {newImpactUUID !== null && (
                        <FlexRow
                            style={{
                                justifyContent: "end",
                                marginRight: "0.25rem",
                            }}
                        >
                            <Link to={`/impacts/${newImpactUUID}`}>
                                <Button size="large" type="primary">
                                    <Translated keyEn="Go to the new impact" />{" "}
                                    <DoubleRightOutlined />
                                </Button>
                            </Link>
                        </FlexRow>
                    )}
                </div>
                {/* Zone data */}
                <div>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Zone" />
                    </UnderlinedSectionTitle>
                    <Row
                        gutter={[10, 10]}
                        style={{
                            marginTop: 20,
                        }}
                    >
                        <Col xs={"auto"}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn={"Free Fall Height"} />
                                </Label>
                                <Input
                                    size="large"
                                    value={zoneFreeFallHeightMetersStr ?? ""}
                                    onChange={(value) => {
                                        setZoneFreeFallHeightMetersStr(
                                            value.target.value,
                                        )
                                    }}
                                    variant="filled"
                                    addonAfter="m"
                                    disabled={
                                        normType === NormType.EN_ISO_23659
                                    }
                                />
                            </FlexCol>
                        </Col>
                        {normType === NormType.EN_1177 && (
                            <>
                                <Col xs={24} md={12}>
                                    <FlexCol style={{ gap: 0 }}>
                                        <Label>
                                            <Translated
                                                keyEn={"Ground thickness"}
                                            />
                                        </Label>
                                        <Input
                                            size="large"
                                            value={
                                                zoneGroundThicknessMillimetersStr ??
                                                ""
                                            }
                                            onChange={(value) => {
                                                setZoneGroundThicknessMillimetersStr(
                                                    value.target.value,
                                                )
                                            }}
                                            type="number"
                                            variant="filled"
                                            addonAfter="mm"
                                        />
                                    </FlexCol>
                                </Col>
                                <Col xs={24} md={12}>
                                    <FlexCol style={{ gap: 0 }}>
                                        <Label>
                                            <Translated
                                                keyEn={"Specimen temperature"}
                                            />
                                        </Label>
                                        <Input
                                            size="large"
                                            value={
                                                zoneSpecimenTemperature ?? ""
                                            }
                                            onChange={(value) => {
                                                if (value.target.value === "") {
                                                    setZoneSpecimenTemperature(
                                                        null,
                                                    )
                                                    return
                                                }
                                                setZoneSpecimenTemperature(
                                                    parseInt(
                                                        value.target.value,
                                                    ),
                                                )
                                            }}
                                            style={{
                                                maxWidth: "10rem",
                                            }}
                                            // disabled={true}
                                            variant="filled"
                                            addonAfter="°C"
                                        />
                                    </FlexCol>
                                </Col>
                                <Col xs={24} md={12}>
                                    <FlexCol style={{ gap: 0 }}>
                                        <Label>
                                            <Translated
                                                keyEn={"Specimen humidity"}
                                            />
                                        </Label>
                                        <Input
                                            size="large"
                                            value={zoneSpecimenHumidity ?? ""}
                                            onChange={(value) => {
                                                if (value.target.value === "") {
                                                    setZoneSpecimenHumidity(
                                                        null,
                                                    )
                                                    return
                                                }
                                                setZoneSpecimenHumidity(
                                                    parseInt(
                                                        value.target.value,
                                                    ),
                                                )
                                            }}
                                            style={{
                                                maxWidth: "10rem",
                                            }}
                                            // disabled={true}
                                            variant="filled"
                                            addonAfter="%"
                                        />
                                    </FlexCol>
                                </Col>
                            </>
                        )}
                        <Col flex={"auto"}>
                            <LabeledInput
                                translatableLabel={"Comments"}
                                value={zoneComment}
                                setValue={setZoneComment}
                            />
                        </Col>
                    </Row>
                </div>
                <FlexRow
                    style={{
                        alignItems: "center",
                        alignSelf: "flex-end",
                    }}
                >
                    <Popconfirm
                        title={
                            <>
                                <Translated keyEn="Are you sure you want to delete this site" />
                                ?
                            </>
                        }
                        onConfirm={onDelete}
                    >
                        <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            size="small"
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                    fontSize: "0.8rem",
                                }}
                            >
                                <Translated keyEn="Delete" />
                            </span>
                        </Button>
                    </Popconfirm>
                    <div
                        style={{
                            width: 200,
                        }}
                    >
                        <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            size="large"
                            block
                            onClick={onUpdate}
                            disabled={!memoZoneIsMutated}
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                }}
                            >
                                <Translated keyEn="Save" />
                            </span>
                        </Button>
                    </div>
                </FlexRow>
            </FlexCol>
            <FlexCol>
                <i>
                    <Translated keyEn="Created at" />:{" "}
                    {dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss")}
                </i>
                <i>
                    <Translated keyEn="Last update" />:{" "}
                    {dayjs(updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                </i>
            </FlexCol>
            <DataTreeDrawer
                site={site}
                selectedKey={memoZoneUUID ? pbUUIDToUuid(memoZoneUUID) : null}
            />
        </>
    )
}
