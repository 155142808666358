import { FC } from "react"
import { pbUUIDToUuid } from "../../utils/utils"
import { UUID } from "../../generated/proto-ts/main"

export const TableImageThumbnail: FC<{ pictureUUID: UUID; alt: string }> = ({
    pictureUUID,
    alt,
}) => {
    return (
        <img
            src={`/api/uploads/${pbUUIDToUuid(pictureUUID)}`}
            // alt={alt}
            style={{
                width: "3rem",
                height: "2rem",
                objectFit: "cover",
            }}
        />
    )
}
