import { faFlask } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, ReactElement, ReactNode } from "react"
import { Translated } from "../../utils/translated"
import {
    MatTypeEN12503,
    MethodTypeEN1177,
    NormType,
} from "../../generated/proto-ts/main"
import { Tooltip } from "antd"

const Tag: FC<{ color?: string; children: ReactNode }> = ({
    color,
    children,
}) => {
    let colorClassesStr = ""
    if (color) {
        colorClassesStr = `bg-${color}-lightest text-${color}-dark`
    }

    return (
        <span
            className={colorClassesStr}
            style={{
                borderRadius: 9999,
                paddingLeft: "1rem",
                paddingRight: "1rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
            }}
        >
            {children}
        </span>
    )
}

export const normTypeDescriptionStr = (normType: NormType | null): string => {
    if (normType === null) {
        return ""
    }
    let normTypeStr = ""
    switch (normType) {
        case NormType.EN_1177:
            normTypeStr = "EN 1177 - PLAYGROUND FLOORS"
            break
        case NormType.EN_12503:
            normTypeStr = "EN 12503 - SPORTS MATS"
            break
        case NormType.EN_14960:
            normTypeStr = "EN 14960 - AIRBAGS"
            break
        case NormType.EN_ISO_23659:
            normTypeStr = "ISO 23659 - TRAMPOLINES"
            break
    }
    return normTypeStr
}

export const normTypeElement = (normType: NormType | null) => {
    if (normType === null) {
        return null
    }
    let normTypeStr = normTypeDescriptionStr(normType)
    return (
        <Tag color="green">
            <Tooltip title="Norm" placement="right">
                <span
                    style={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                    }}
                >
                    <Translated keyEn={normTypeStr} />
                </span>
            </Tooltip>
        </Tag>
    )
}

export const methodDescriptionStr = (
    method: MethodTypeEN1177 | null,
): string => {
    if (method === null) {
        return ""
    }
    let methodStr = ""
    switch (method) {
        case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
            methodStr = "Critical Fall Height"
            break
        case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
            methodStr = "Impact Attenuation Compliance"
            break
    }
    return methodStr
}

export const methodElement = (method: MethodTypeEN1177 | null) => {
    if (
        method === null ||
        method === MethodTypeEN1177.UNKNOWN_METHOD_TYPE_EN_1177
    ) {
        return null
    }
    let methodEl: ReactElement | null = null
    let methodColor: string | undefined = undefined
    let descriptionStr = methodDescriptionStr(method)
    switch (method) {
        case MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION:
            methodEl = (
                <span>
                    1: <Translated keyEn={descriptionStr} />
                </span>
            )
            methodColor = "pink"
            break
        case MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE:
            methodEl = (
                <span>
                    2: <Translated keyEn={descriptionStr} />
                </span>
            )
            methodColor = "indigo"
            break
    }
    return (
        <Tag color={methodColor}>
            <Translated keyEn="Method" />{" "}
            <span
                style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                }}
            >
                {methodEl}
            </span>
        </Tag>
    )
}

export const isLabTestElement = (isLabTest: boolean | null) => {
    if (isLabTest === null || isLabTest === false) {
        return null
    }
    return (
        <Tag color="blue">
            <FontAwesomeIcon icon={faFlask} />
        </Tag>
    )
}

export const sportsMatTypeDescriptionStr = (
    smType: MatTypeEN12503 | null,
): string | null => {
    if (smType === null) {
        return null
    }
    let smTypeStr = ""
    switch (smType) {
        case MatTypeEN12503.TYPE_1:
            smTypeStr = "Gymnastics: for basic excercises without jumping"
            break
        case MatTypeEN12503.TYPE_2:
            smTypeStr = "Gymnastics: for jumping"
            break
        case MatTypeEN12503.TYPE_3:
            smTypeStr = "Gymnastics: for jumping and reception"
            break
        case MatTypeEN12503.TYPE_4:
            smTypeStr =
                "Gymnastics: simple equipment exit and stabilized reception"
            break
        case MatTypeEN12503.TYPE_5:
            smTypeStr = "Gymnastics: equipment exit"
            break
        case MatTypeEN12503.TYPE_6:
            smTypeStr = "Gymnastics: equipment exit for difficult excercises"
            break
        case MatTypeEN12503.TYPE_7:
            smTypeStr = "Gymnastics: landing mat for simple excercices"
            break
        case MatTypeEN12503.TYPE_8:
            smTypeStr = "Gymnastics: landing mat"
            break
        case MatTypeEN12503.TYPE_9:
            smTypeStr =
                "High jump: simple excercises for schools and training clubs"
            break
        case MatTypeEN12503.TYPE_10:
            smTypeStr = "High jump: competition"
            break
        case MatTypeEN12503.TYPE_11:
            smTypeStr = "High jump: pole vault"
            break
        case MatTypeEN12503.TYPE_12:
            smTypeStr = "Judo"
            break
        case MatTypeEN12503.TYPE_EN12572:
            smTypeStr = "Climbing: EN12572"
    }
    return smTypeStr
}

export const sportsMatTypeElement = (smType: MatTypeEN12503 | null) => {
    if (smType === null) {
        return null
    }
    let smTypeStr = sportsMatTypeDescriptionStr(smType)
    return (
        <Tag color="purple">
            <Translated keyEn="Sports mat" /> <b>Type {smType}</b>:{" "}
            <span
                style={{
                    textTransform: "uppercase",
                    // fontWeight: "bold",
                }}
            >
                <Translated keyEn={smTypeStr} />
            </span>
        </Tag>
    )
}
