import { FC, useState } from "react"
import { useUsercommContextBLE } from "../../usercomm/local/ble/usercommProviderBLE"
import { FlexCol, FlexRow } from "./common"
import { Modal, Button, Row, Col, Alert, Spin } from "antd"
import { PoweroffOutlined } from "@ant-design/icons"
import { Translated } from "../../utils/translated"
import { useHistory } from "react-router-dom"

export const StationRestartWidget: FC = () => {
    const { emitRestartStation, emitRebootStation, emitHaltStation } =
        useUsercommContextBLE()
    const history = useHistory()
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onRestart = (emitCallback: () => void) => {
        emitCallback()
        setIsLoading(true)
        setTimeout(() => {
            history.push("/connect")
            window.location.reload()
        }, 3e3)
    }

    return (
        <FlexCol>
            <Modal
                title={<Translated keyEn="System restart" />}
                open={modalIsOpen}
                onOk={() => {
                    setModalIsOpen(false)
                }}
                onCancel={() => {
                    setModalIsOpen(false)
                }}
                footer={null}
            >
                {" "}
                <FlexCol>
                    <Translated keyEn="Are you sure you want to restart the system?" />
                    <br />
                    <Translated keyEn="Please choose mode:" />
                    <Row gutter={[10, 10]}>
                        <Col xs={24} md={8}>
                            <Button
                                type="primary"
                                block
                                onClick={() => {
                                    onRestart(emitRestartStation)
                                }}
                            >
                                <Translated keyEn="Firmware restart" />
                            </Button>
                        </Col>
                        <Col xs={24} md={8}>
                            <Button
                                type="primary"
                                block
                                onClick={() => {
                                    onRestart(emitRebootStation)
                                }}
                            >
                                <Translated keyEn="CPU reboot" />
                            </Button>
                        </Col>
                        <Col xs={24} md={8}>
                            <Button
                                type="primary"
                                block
                                danger
                                onClick={() => {
                                    onRestart(emitHaltStation)
                                }}
                            >
                                <Translated keyEn="Power OFF" />
                            </Button>
                        </Col>
                    </Row>
                    <Alert
                        type="info"
                        message={
                            <>
                                <b>Note: </b>
                                <Translated keyEn="Power OFF will require turning it back ON manually" />
                            </>
                        }
                    />
                    {isLoading && (
                        <FlexRow
                            style={{
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <Spin size="large" />
                        </FlexRow>
                    )}
                </FlexCol>
            </Modal>
            <Button
                type="default"
                onClick={() => {
                    setModalIsOpen(true)
                }}
            >
                <PoweroffOutlined />
            </Button>
        </FlexCol>
    )
}
