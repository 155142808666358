import { useCallback, useEffect, useState } from "react"
import {
    Annex,
    BashCmd,
    DeviceTelemetry,
    Equipment,
    GNSSUbloxGetALPFilesResponse,
    GNSSUbloxResetRequest,
    GNSSUbloxSetALPFileRequest,
    GNSSUbloxState,
    GNSSUbloxWriteRTCMRequest,
    GenericStorageEntity,
    HICConfig,
    Impact,
    LegacyHostApiRequest,
    LegacyHostApiResponse,
    NetworkGetPrimaryIPAddressInfoResponse,
    RawStorageEntity,
    ReleaseRequest,
    ReleaseResponse,
    Site,
    StationConfig,
    StationState,
    StorageEntityType,
    StorageRequest,
    StorageResponse,
    StorageStats,
    UCAck,
    UCError,
    UCPayload,
    UCPayloadType,
    UUID,
    WPAGetNetworksResponse,
    WPAScanResultsResponse,
    WPASetNetworksRequest,
    WPAStatusResponse,
    Zone,
} from "../../generated/proto-ts/main"
import { decodeLengthDelimitedArray } from "./usercommUtils"
import { pbUUIDToUuid, uuidToPbUUID } from "../../utils/utils"
import { IRawGenericStorageEntity } from "../../types"
import { message as antdMessage } from "antd"

export type UsercommAsyncRequestHook = () => [
    UCPayload | null,
    (payload: UCPayload) => void,
]

export const _handleUCPayloadError = (
    key: string,
    expectedPayloadType: UCPayloadType,
    responsePayload: UCPayload,
): boolean => {
    if (responsePayload.type === UCPayloadType.SICO_ERROR) {
        let error = UCError.deserializeBinary(responsePayload.data)
        let msg = `Error ${key}: ${error.value}`
        // Case of getting a single entity locally that does not exist
        if (
            responsePayload.request_type ===
                UCPayloadType.SICO_STORAGE_GET_ENTITY &&
            error.value.includes("sql: no rows in result set")
        ) {
            console.debug(
                "UC Generic Error Handler: case of SICO_STORAGE_GET_ENTITY/NO_ROWS: probably an expected error by design, will not log nor show using antdMessage..",
            )
            return false
        }
        // Case of updating (upserting) a single entity which parent does not exist (local)
        if (
            responsePayload.request_type ===
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY &&
            error.value.includes("parent does not exist")
        ) {
            console.debug(
                "UC Generic Error Handler: case of SICO_STORAGE_UPDATE_ENTITY/PARENT_NOT_EXIST: probably an expected error by design, will not log nor show using antdMessage..",
            )
            return false
        }
        // Case of updating (upserting) a single entity which parent does not exist (remote)
        if (
            responsePayload.request_type ===
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY &&
            error.value.includes("violates foreign key constraint")
        ) {
            console.debug(
                "UC Generic Error Handler: case of SICO_STORAGE_UPDATE_ENTITY/PARENT_NOT_EXIST: probably an expected error by design, will not log nor show using antdMessage..",
            )
            return false
        }
        // Weird case of entityUUID is nil
        // TODO: investigate why this happens
        if (
            responsePayload.request_type ===
                UCPayloadType.SICO_STORAGE_GET_ENTITY &&
            error.value.includes("entityUUID is nil")
        ) {
            console.debug(
                "UC Generic Error Handler: case of SICO_STORAGE_GET_ENTITY/ENTITY_UUID_IS_NIL: probably an expected error by design, will not log nor show using antdMessage..",
            )
            return false
        }
        console.error(msg)
        antdMessage.error(msg)
        return false
    }
    if (responsePayload.type !== expectedPayloadType) {
        let msg = `Could not parse payload for ${key}: unexpected response type: ${responsePayload.type}`
        console.error(msg)
        antdMessage.error(msg)
        return false
    }
    return true
}

// SYNC
export const useUsercommGenericDeviceEntitiesGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [IRawGenericStorageEntity[] | null, (deviceUUID: UUID) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [genericEntities, setGenericEntities] = useState<
        IRawGenericStorageEntity[] | null
    >(null)

    const getGenericEntities = useCallback(
        (deviceUUID: UUID) => {
            let storageRequest = new StorageRequest({
                parent_uuid: deviceUUID, // device_uuid is not quite the parent_uuid as sites' parents are users, but the server will know what to do
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ALL_GENERIC_ENTITIES,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting generic entities",
                UCPayloadType.SICO_STORAGE_GET_ALL_GENERIC_ENTITIES,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _entities = decodeLengthDelimitedArray(
                RawStorageEntity,
                storageResponse.multiple_entities_data,
            )
            let _rawGenericStorageEntities: IRawGenericStorageEntity[] = []
            for (let _rawStorageEntity of _entities) {
                let _genericStorageEntity =
                    GenericStorageEntity.deserializeBinary(
                        _rawStorageEntity.data,
                    )
                let _uuid = pbUUIDToUuid(_rawStorageEntity.uuid)
                if (_genericStorageEntity.has_uuid) {
                    let genUUID = pbUUIDToUuid(_genericStorageEntity.uuid)
                    if (_uuid !== genUUID) {
                        console.warn(
                            `generic entity UUID mismatch: rawUUID=${_uuid}, genUUID=${genUUID}`,
                        )
                        continue
                    }
                }
                let parentUUID: string | null = null
                if (
                    _rawStorageEntity.has_parent_uuid &&
                    !_genericStorageEntity.has_parent_uuid
                ) {
                    parentUUID = pbUUIDToUuid(_rawStorageEntity.parent_uuid)
                } else if (
                    !_rawStorageEntity.has_parent_uuid &&
                    _genericStorageEntity.has_parent_uuid
                ) {
                    parentUUID = pbUUIDToUuid(_genericStorageEntity.parent_uuid)
                } else if (
                    _rawStorageEntity.has_parent_uuid &&
                    _genericStorageEntity.has_parent_uuid
                ) {
                    parentUUID = pbUUIDToUuid(_rawStorageEntity.parent_uuid)
                    let genParentUUID = pbUUIDToUuid(
                        _genericStorageEntity.parent_uuid,
                    )
                    if (parentUUID !== genParentUUID) {
                        console.warn(
                            `generic entity parent UUID mismatch: rawParentUUID=${parentUUID}, genParentUUID=${genParentUUID}`,
                        )
                        continue
                    }
                }
                _rawGenericStorageEntities.push({
                    uuid: _uuid,
                    parent_uuid: parentUUID,
                    entity_type: _rawStorageEntity.entity_type,
                    created_at: _genericStorageEntity.created_at,
                    updated_at: _genericStorageEntity.updated_at,
                    deleted_at: _genericStorageEntity.deleted_at,
                })
            }
            setGenericEntities(_rawGenericStorageEntities)
        }
    }, [responsePayload])

    return [genericEntities, getGenericEntities]
}

export const useUsercommRawStorageEntityGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [
    Uint8Array | null,
    (entityUUID: UUID, entityType: StorageEntityType) => void,
] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [rawStorageEntity, setRawStorageEntity] = useState<Uint8Array | null>(
        null,
    )

    const getRawStorageEntity = useCallback(
        (entityUUID: UUID, entityType: StorageEntityType) => {
            let storageRequest = new StorageRequest({
                entity_uuid: entityUUID,
                entity_type: entityType,
                preprocess_decimation_rate: 0, // DO NOT DECIMATE as we want the raw data
                preprocess_include_axes_points: true, // INCLUDE AXES POINTS
                preprocess_include_quadratic_points: false, // DO NOT INCLUDE QUADRATIC POINTS as it is not the raw data
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting raw storage entity",
                UCPayloadType.SICO_STORAGE_GET_ENTITY,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            setRawStorageEntity(storageResponse.single_entity_data)
        }
    }, [responsePayload])

    return [rawStorageEntity, getRawStorageEntity]
}

export const useUsercommUpdateRawStorageEntityGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [
    UCAck | null,
    UCAck | null,
    (
        entity_uuid: UUID,
        parent_uuid: UUID,
        entity_type: StorageEntityType,
        rawStorageEntityData: Uint8Array,
    ) => void,
] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [nack, setNack] = useState<UCAck | null>(null)

    const updateRawStorageEntity = useCallback(
        (
            entity_uuid: UUID,
            parent_uuid: UUID,
            entity_type: StorageEntityType,
            rawStorageEntityData: Uint8Array,
        ) => {
            let storageRequest = new StorageRequest({
                entity_uuid: entity_uuid,
                parent_uuid: parent_uuid,
                entity_type: entity_type,
                single_entity_data: rawStorageEntityData,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        // BLE-specific: handle cases of:
        // - "upserting site of different device is forbidden unless explicitely allowed"
        // - "parent does not exist"
        if (responsePayload.type === UCPayloadType.SICO_ERROR) {
            let error = UCError.deserializeBinary(responsePayload.data)
            if (
                error.value.includes("different device is forbidden") ||
                error.value.includes("parent does not exist")
            ) {
                setNack(new UCAck())
                return
            }
        }
        if (
            _handleUCPayloadError(
                "updating raw storage entity",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        } else {
            setAck(new UCAck())
        }
    }, [responsePayload])

    return [ack, nack, updateRawStorageEntity]
}

export const useUsercommDeleteRawStorageEntityGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [
    UCAck | null,
    (entityUUID: UUID, entityType: StorageEntityType) => void,
] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const deleteRawStorageEntity = useCallback(
        (entityUUID: UUID, entityType: StorageEntityType) => {
            let storageRequest = new StorageRequest({
                entity_uuid: entityUUID,
                entity_type: entityType,
                current_timestamp: Date.now(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting raw storage entity",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, deleteRawStorageEntity]
}

// STATS
export const useUsercommStatsGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [StorageStats | null, (parentUUID: UUID | undefined) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [stats, setStats] = useState<StorageStats | null>(null)

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting stats",
                UCPayloadType.SICO_STORAGE_GET_STATS,
                responsePayload,
            )
        ) {
            let _stats = StorageStats.deserializeBinary(responsePayload.data)
            setStats(_stats)
        }
    }, [responsePayload])

    const getStats = useCallback(
        (parentUUID: UUID | undefined) => {
            let storageRequest = new StorageRequest({
                parent_uuid: parentUUID,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_STATS,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    return [stats, getStats]
}

// SITES
export const useUsercommSitesGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [Site[] | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [sites, setSites] = useState<Site[] | null>(null)

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting sites",
                UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _sites = decodeLengthDelimitedArray(
                Site,
                storageResponse.multiple_entities_data,
            )
            setSites(_sites)
        }
    }, [responsePayload])

    const getSites = useCallback(() => {
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.SITE,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES_OF_TYPE,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [setRequestPayload])

    return [sites, getSites]
}

export const useUsercommSiteGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [Site | null, (siteUUID: UUID | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [site, setSite] = useState<Site | null>(null)

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting site",
                UCPayloadType.SICO_STORAGE_GET_ENTITY,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _site = Site.deserializeBinary(
                storageResponse.single_entity_data,
            )
            setSite(_site)
        }
    }, [responsePayload])

    const getSite = useCallback(
        (siteUUID: UUID | null) => {
            if (siteUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.SITE,
                entity_uuid: siteUUID,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setSite(null)
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    return [site, getSite]
}

export const useUsercommCreateSiteGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UUID | null, (userUUID: string | null, site: Site | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [newUUID, setNewUUID] = useState<UUID | null>(null)

    const createSite = useCallback(
        (userUUID: string | null, site: Site | null) => {
            if (site === null || userUUID === null) {
                return
            }
            site.user_uuid = uuidToPbUUID(userUUID)
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.SITE,
                parent_uuid: uuidToPbUUID(userUUID),
                entity_uuid: site.uuid,
                single_entity_data: site.serializeBinary(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "creating site",
                UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                responsePayload,
            )
        ) {
            let newUUID = UUID.deserializeBinary(responsePayload.data)
            setNewUUID(newUUID)
        }
    }, [responsePayload])

    return [newUUID, createSite]
}

export const useUsercommUpdateSiteGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (site: Site) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const updateSite = useCallback(
        (site: Site) => {
            if (site === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.SITE,
                entity_uuid: site.uuid,
                single_entity_data: site.serializeBinary(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "updating site",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, updateSite]
}

export const useUsercommDeleteSiteGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (siteUUID: UUID) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [siteUUID, setSiteUUID] = useState<string | null>(null)

    const deleteSite = useCallback(
        (siteUUID: UUID) => {
            if (siteUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.SITE,
                entity_uuid: siteUUID,
                current_timestamp: Date.now(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting site",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, deleteSite]
}

export const useUsercommDeleteSiteHardGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (siteUUID: string) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const deleteSiteHard = useCallback(
        (siteUUID: string | null) => {
            if (siteUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.SITE,
                entity_uuid: uuidToPbUUID(siteUUID),
                current_timestamp: Date.now(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "hard-deleting site",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, deleteSiteHard]
}

// ANNEXES
export const useUsercommSiteAnnexesGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [Annex[] | null, (siteUUID: UUID | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [annexes, setAnnexes] = useState<Annex[] | null>(null)

    const getAnnexes = useCallback(
        (siteUUID: UUID | null) => {
            if (siteUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.ANNEX,
                parent_uuid: siteUUID,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting annexes",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _annexes = decodeLengthDelimitedArray(
                Annex,
                storageResponse.multiple_entities_data,
            )
            setAnnexes(_annexes)
        }
    }, [responsePayload])

    return [annexes, getAnnexes]
}

export const useUsercommAnnexGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [Annex | null, (annexUUID: string | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [annex, setAnnex] = useState<Annex | null>(null)

    const getAnnex = useCallback(
        (annexUUID: string | null) => {
            if (annexUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.ANNEX,
                entity_uuid: uuidToPbUUID(annexUUID),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setAnnex(null)
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting annex",
                UCPayloadType.SICO_STORAGE_GET_ENTITY,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _annex = Annex.deserializeBinary(
                storageResponse.single_entity_data,
            )
            setAnnex(_annex)
        }
    }, [responsePayload])

    return [annex, getAnnex]
}

export const useUsercommCreateAnnexGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UUID | null, (siteUUID: UUID | null, annex: Annex | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [newUUID, setNewUUID] = useState<UUID | null>(null)

    const createAnnex = useCallback(
        (siteUUID: UUID | null, annex: Annex | null) => {
            if (annex === null || siteUUID === null) {
                return
            }
            annex.site_uuid = siteUUID
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.ANNEX,
                parent_uuid: siteUUID,
                entity_uuid: annex.uuid,
                single_entity_data: annex.serializeBinary(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "creating annex",
                UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                responsePayload,
            )
        ) {
            let newUUID = UUID.deserializeBinary(responsePayload.data)
            setNewUUID(newUUID)
        }
    }, [responsePayload])

    return [newUUID, createAnnex]
}

export const useUsercommUpdateAnnexGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (annex: Annex) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const updateAnnex = useCallback(
        (annex: Annex) => {
            if (annex === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.ANNEX,
                entity_uuid: annex.uuid,
                parent_uuid: annex.site_uuid,
                single_entity_data: annex.serializeBinary(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "updating annex",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, updateAnnex]
}

export const useUsercommDeleteAnnexHardGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (annexUUID: Annex["uuid"]) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const deleteAnnex = useCallback(
        (annexUUID: Annex["uuid"]) => {
            if (annexUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.ANNEX,
                entity_uuid: annexUUID,
                current_timestamp: Date.now(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting annex",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, deleteAnnex]
}

// RECURSIVE SITE CHILDREN
export const useUsercommSiteChildrenRecursiveGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [
    Record<string, Equipment[]> | null, // Site's equipments
    Record<string, Zone[]> | null, // Equipment's zones
    Record<string, Impact[]> | null, // Zone's impacts
    (siteUUID: Site["uuid"] | null) => void,
] => {
    const [siteEquipmentsMap, setSiteEquipmentsMap] = useState<Record<
        string,
        Equipment[]
    > | null>(null)
    const [equipmentZonesMap, setEquipmentZonesMap] = useState<Record<
        string,
        Zone[]
    > | null>(null)
    const [zoneImpactsMap, setZoneImpactsMap] = useState<Record<
        string,
        Impact[]
    > | null>(null)

    const [getEntitiesRecursiveResponse, getEntitiesRecursiveRequest] =
        useAsyncRequest()

    const requestEntitiesRecursive = useCallback(
        (siteUUID: Site["uuid"] | null) => {
            if (siteUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.SITE,
                parent_uuid: siteUUID,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_RECURSIVE,
                data: storageRequest.serializeBinary(),
            })
            getEntitiesRecursiveRequest(payload)
        },
        [getEntitiesRecursiveRequest],
    )

    useEffect(() => {
        if (getEntitiesRecursiveResponse === null) {
            return
        }
        if (
            !_handleUCPayloadError(
                "getting site children recursive",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_RECURSIVE,
                getEntitiesRecursiveResponse,
            )
        ) {
            return
        }
        let storageResponse = StorageResponse.deserializeBinary(
            getEntitiesRecursiveResponse.data,
        )
        let rawStorageEntities = decodeLengthDelimitedArray(
            RawStorageEntity,
            storageResponse.multiple_entities_data,
        )
        if (rawStorageEntities.length === 0) {
            return
        }
        let _siteEquipmentsMap: Record<string, Equipment[]> = {}
        let _equipmentZonesMap: Record<string, Zone[]> = {}
        let _zoneImpactsMap: Record<string, Impact[]> = {}
        for (let entity of rawStorageEntities) {
            if (entity.entity_type === StorageEntityType.EQUIPMENT) {
                let equipment = Equipment.deserializeBinary(entity.data)
                let parentUUIDStr = pbUUIDToUuid(entity.parent_uuid)
                if (_siteEquipmentsMap[parentUUIDStr] === undefined) {
                    _siteEquipmentsMap[parentUUIDStr] = []
                }
                _siteEquipmentsMap[parentUUIDStr].push(equipment)
            }
            if (entity.entity_type === StorageEntityType.ZONE) {
                let zone = Zone.deserializeBinary(entity.data)
                let parentUUIDStr = pbUUIDToUuid(entity.parent_uuid)
                if (_equipmentZonesMap[parentUUIDStr] === undefined) {
                    _equipmentZonesMap[parentUUIDStr] = []
                }
                _equipmentZonesMap[parentUUIDStr].push(zone)
            }
            if (entity.entity_type === StorageEntityType.IMPACT) {
                let impact = Impact.deserializeBinary(entity.data)
                let parentUUIDStr = pbUUIDToUuid(entity.parent_uuid)
                if (_zoneImpactsMap[parentUUIDStr] === undefined) {
                    _zoneImpactsMap[parentUUIDStr] = []
                }
                _zoneImpactsMap[parentUUIDStr].push(impact)
            }
        }
        setSiteEquipmentsMap(_siteEquipmentsMap)
        setEquipmentZonesMap(_equipmentZonesMap)
        setZoneImpactsMap(_zoneImpactsMap)
    }, [getEntitiesRecursiveResponse])

    return [
        siteEquipmentsMap,
        equipmentZonesMap,
        zoneImpactsMap,
        requestEntitiesRecursive,
    ]
}
// EQUIPMENTS
export const useUsercommSiteEquipmentsGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [Equipment[] | null, (siteUUID: UUID | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [equipments, setEquipments] = useState<Equipment[] | null>(null)

    const getEquipments = useCallback(
        (siteUUID: UUID | null) => {
            if (siteUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.EQUIPMENT,
                parent_uuid: siteUUID,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting equipments",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _equipments = decodeLengthDelimitedArray(
                Equipment,
                storageResponse.multiple_entities_data,
            )
            setEquipments(_equipments)
        }
    }, [responsePayload])

    return [equipments, getEquipments]
}

export const useUsercommEquipmentGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [Equipment | null, (equipmentUUID: UUID | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [equipment, setEquipment] = useState<Equipment | null>(null)

    const getEquipment = useCallback(
        (equipmentUUID: UUID | null) => {
            if (equipmentUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.EQUIPMENT,
                entity_uuid: equipmentUUID,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setEquipment(null)
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting equipment",
                UCPayloadType.SICO_STORAGE_GET_ENTITY,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _equipment = Equipment.deserializeBinary(
                storageResponse.single_entity_data,
            )
            setEquipment(_equipment)
        }
    }, [responsePayload])

    return [equipment, getEquipment]
}

export const useUsercommCreateEquipmentGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [
    UUID | null,
    (siteUUID: UUID | null, equipment: Equipment | null) => void,
] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [newUUID, setNewUUID] = useState<UUID | null>(null)

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "creating equipment",
                UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                responsePayload,
            )
        ) {
            let newUUID = UUID.deserializeBinary(responsePayload.data)
            setNewUUID(newUUID)
        }
    }, [responsePayload])

    const createEquipment = useCallback(
        (siteUUID: UUID | null, equipment: Equipment | null) => {
            if (equipment === null || siteUUID === null) {
                return
            }
            equipment.site_uuid = siteUUID
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.EQUIPMENT,
                parent_uuid: siteUUID,
                entity_uuid: equipment.uuid,
                single_entity_data: equipment.serializeBinary(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    return [newUUID, createEquipment]
}

export const useUsercommUpdateEquipmentGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (equipment: Equipment) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const updateEquipment = useCallback(
        (equipment: Equipment) => {
            if (equipment === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.EQUIPMENT,
                entity_uuid: equipment.uuid,
                parent_uuid: equipment.site_uuid,
                single_entity_data: equipment.serializeBinary(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "updating equipment",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, updateEquipment]
}

export const useUsercommDeleteEquipmentHardGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (equipmentUUID: UUID) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const deleteEquipment = useCallback(
        (equipmentUUID: UUID) => {
            if (equipmentUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.EQUIPMENT,
                entity_uuid: equipmentUUID,
                current_timestamp: Date.now(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting equipment",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, deleteEquipment]
}

// RECURSIVE EQUIPMENT CHILDREN
export const useUsercommEquipmentChildrenRecursiveGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [
    Record<string, Impact[]> | null, // Zone's impacts
    (equipmentUUID: Equipment["uuid"] | null) => void,
] => {
    const [getEntitiesRecursiveResponse, getEntitiesRecursiveRequest] =
        useAsyncRequest()

    const [zoneImpactsMap, setZoneImpactsMap] = useState<Record<
        string,
        Impact[]
    > | null>(null)

    const getEntitiesRecursive = useCallback(
        (equipmentUUID: Equipment["uuid"] | null) => {
            if (equipmentUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.EQUIPMENT,
                parent_uuid: equipmentUUID,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_RECURSIVE,
                data: storageRequest.serializeBinary(),
            })
            getEntitiesRecursiveRequest(payload)
        },
        [getEntitiesRecursiveRequest],
    )

    useEffect(() => {
        if (getEntitiesRecursiveResponse === null) {
            return
        }
        if (
            !_handleUCPayloadError(
                "getting equipment children recursive",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_RECURSIVE,
                getEntitiesRecursiveResponse,
            )
        ) {
            return
        }
        let storageResponse = StorageResponse.deserializeBinary(
            getEntitiesRecursiveResponse.data,
        )
        let rawStorageEntities = decodeLengthDelimitedArray(
            RawStorageEntity,
            storageResponse.multiple_entities_data,
        )
        if (rawStorageEntities.length === 0) {
            return
        }
        let _zoneImpactsMap: Record<string, Impact[]> = {}
        for (let entity of rawStorageEntities) {
            if (entity.entity_type === StorageEntityType.IMPACT) {
                let impact = Impact.deserializeBinary(entity.data)
                let parentUUIDStr = pbUUIDToUuid(entity.parent_uuid)
                if (_zoneImpactsMap[parentUUIDStr] === undefined) {
                    _zoneImpactsMap[parentUUIDStr] = []
                }
                _zoneImpactsMap[parentUUIDStr].push(impact)
            }
        }
        setZoneImpactsMap(_zoneImpactsMap)
    }, [getEntitiesRecursiveResponse])

    return [zoneImpactsMap, getEntitiesRecursive]
}

// ZONES
export const useUsercommEquipmentZonesGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [Zone[] | null, (equipmentUUID: UUID | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [zones, setZones] = useState<Zone[] | null>(null)

    const getZones = useCallback(
        (equipmentUUID: UUID | null) => {
            if (equipmentUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.ZONE,
                parent_uuid: equipmentUUID,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting zones",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _zones = decodeLengthDelimitedArray(
                Zone,
                storageResponse.multiple_entities_data,
            )
            setZones(_zones)
        }
    }, [responsePayload])

    return [zones, getZones]
}

export const useUsercommZoneGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [Zone | null, (zoneUUID: UUID | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [zone, setZone] = useState<Zone | null>(null)

    const getZone = useCallback(
        (zoneUUID: UUID | null) => {
            if (zoneUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.ZONE,
                entity_uuid: zoneUUID,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setZone(null)
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting zone",
                UCPayloadType.SICO_STORAGE_GET_ENTITY,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _zone = Zone.deserializeBinary(
                storageResponse.single_entity_data,
            )
            setZone(_zone)
        }
    }, [responsePayload])

    return [zone, getZone]
}

export const useUsercommCreateZoneGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UUID | null, (equipmentUUID: UUID | null, zone: Zone | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [newUUID, setNewUUID] = useState<UUID | null>(null)

    const createZone = useCallback(
        (equipmentUUID: UUID | null, zone: Zone | null) => {
            if (zone === null || equipmentUUID === null) {
                return
            }
            zone.equipment_uuid = equipmentUUID
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.ZONE,
                parent_uuid: equipmentUUID,
                entity_uuid: zone.uuid,
                single_entity_data: zone.serializeBinary(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "creating zone",
                UCPayloadType.SICO_STORAGE_CREATE_ENTITY,
                responsePayload,
            )
        ) {
            let newUUID = UUID.deserializeBinary(responsePayload.data)
            setNewUUID(newUUID)
        }
    }, [responsePayload])

    return [newUUID, createZone]
}

export const useUsercommUpdateZoneGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (zone: Zone) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [zone, setZone] = useState<Zone | null>(null)

    const updateZone = useCallback(
        (zone: Zone) => {
            if (zone === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.ZONE,
                entity_uuid: zone.uuid,
                parent_uuid: zone.equipment_uuid,
                single_entity_data: zone.serializeBinary(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "updating zone",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, updateZone]
}

export const useUsercommDeleteZoneHardGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (zoneUUID: UUID) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const deleteZone = useCallback(
        (zoneUUID: UUID) => {
            if (zoneUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.ZONE,
                entity_uuid: zoneUUID,
                current_timestamp: Date.now(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting zone",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, deleteZone]
}

// IMPACTS

export const useUsercommZoneImpactsGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [Impact[] | null, (zoneUUID: UUID | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [impacts, setImpacts] = useState<Impact[] | null>(null)
    const getImpacts = useCallback(
        (zoneUUID: UUID | null) => {
            if (zoneUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.IMPACT,
                parent_uuid: zoneUUID,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting impacts",
                UCPayloadType.SICO_STORAGE_GET_ENTITY_CHILDREN_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _impacts = decodeLengthDelimitedArray(
                Impact,
                storageResponse.multiple_entities_data,
            )

            setImpacts(_impacts)
        }
    }, [responsePayload])

    return [impacts, getImpacts]
}

export const useUsercommAllImpactsGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [Impact[] | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [impacts, setImpacts] = useState<Impact[] | null>(null)

    const getImpacts = useCallback(() => {
        let storageRequest = new StorageRequest({
            entity_type: StorageEntityType.IMPACT,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES_OF_TYPE,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [setRequestPayload])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting all impacts",
                UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES_OF_TYPE,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _impacts = decodeLengthDelimitedArray(
                Impact,
                storageResponse.multiple_entities_data,
            )

            setImpacts(_impacts)
        }
    }, [responsePayload])

    return [impacts, getImpacts]
}

export interface SyntheticImpactWithEmbeddedReferences {
    impact: Impact
    zone: Zone | null
    equipment: Equipment | null
    site: Site | null
}
export const useUsercommSyntheticImpactWithEmbeddedReferencesGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [SyntheticImpactWithEmbeddedReferences[] | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [syntheticImpacts, setSyntheticImpacts] = useState<
        SyntheticImpactWithEmbeddedReferences[] | null
    >(null)

    const getAllEntities = useCallback(() => {
        let storageRequest = new StorageRequest({})
        let payload = new UCPayload({
            type: UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES,
            data: storageRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [setRequestPayload])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting synthetic impacts",
                UCPayloadType.SICO_STORAGE_GET_ALL_ENTITIES,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let rawStorageEntities = decodeLengthDelimitedArray(
                RawStorageEntity,
                storageResponse.multiple_entities_data,
            )
            let sitesMap: Record<string, Site> = {}
            let equipmentsMap: Record<string, Equipment> = {}
            let zonesMap: Record<string, Zone> = {}
            let _syntheticImpacts: SyntheticImpactWithEmbeddedReferences[] = []
            for (let rawStorageEntity of rawStorageEntities) {
                switch (rawStorageEntity.entity_type) {
                    case StorageEntityType.SITE:
                        let site = Site.deserializeBinary(rawStorageEntity.data)
                        sitesMap[pbUUIDToUuid(rawStorageEntity.uuid)] = site
                        break
                    case StorageEntityType.EQUIPMENT:
                        let equipment = Equipment.deserializeBinary(
                            rawStorageEntity.data,
                        )
                        equipmentsMap[pbUUIDToUuid(rawStorageEntity.uuid)] =
                            equipment
                        break
                    case StorageEntityType.ZONE:
                        let zone = Zone.deserializeBinary(rawStorageEntity.data)
                        zonesMap[pbUUIDToUuid(rawStorageEntity.uuid)] = zone
                        break
                    case StorageEntityType.IMPACT:
                        let impact = Impact.deserializeBinary(
                            rawStorageEntity.data,
                        )
                        let syntheticImpact: SyntheticImpactWithEmbeddedReferences =
                            {
                                impact: impact,
                                zone: null,
                                equipment: null,
                                site: null,
                            }
                        _syntheticImpacts.push(syntheticImpact)
                        break
                }
            }
            for (let syntheticImpact of _syntheticImpacts) {
                if (!syntheticImpact.impact.zone_uuid) {
                    continue
                }
                let zoneUUID = pbUUIDToUuid(syntheticImpact.impact.zone_uuid)
                let zone = zonesMap[zoneUUID]
                if (zone === undefined) {
                    console.warn(
                        `Zone not found for impact ${syntheticImpact.impact.uuid}`,
                    )
                    continue
                }
                syntheticImpact.zone = zone
                let equipmentUUID = pbUUIDToUuid(zone.equipment_uuid)
                let equipment = equipmentsMap[equipmentUUID]
                if (equipment === undefined) {
                    console.warn(
                        `Equipment not found for impact ${syntheticImpact.impact.uuid}`,
                    )
                    continue
                }
                syntheticImpact.equipment = equipment
                let siteUUID = pbUUIDToUuid(equipment.site_uuid)
                let site = sitesMap[siteUUID]
                if (site === undefined) {
                    console.warn(
                        `Site not found for impact ${syntheticImpact.impact.uuid}`,
                    )
                    continue
                }
                syntheticImpact.site = site
            }
            setSyntheticImpacts(_syntheticImpacts)
        }
    }, [responsePayload])

    return [syntheticImpacts, getAllEntities]
}

export const useUsercommImpactGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [
    Impact | null,
    (
        impactUUID: UUID | null,
        preprocessIncludeAxesPoints: boolean,
        preprocessIncludeQuadraticPoints: boolean,
        preprocessDifferentialEncodePoints: boolean,
        decimationRate?: number,
    ) => void,
] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [impact, setImpact] = useState<Impact | null>(null)

    const getImpact = useCallback(
        (
            impactUUID: UUID | null,
            preprocessIncludeAxesPoints: boolean,
            preprocessIncludeQuadraticPoints: boolean,
            preprocessDifferentialEncodePoints: boolean,
            decimationRate?: number,
        ) => {
            if (impactUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.IMPACT,
                entity_uuid: impactUUID,
                preprocess_decimation_rate:
                    decimationRate === undefined ? 0 : decimationRate,
                preprocess_include_axes_points: preprocessIncludeAxesPoints,
                preprocess_include_quadratic_points:
                    preprocessIncludeQuadraticPoints,
                preprocess_differential_encode_points:
                    preprocessDifferentialEncodePoints,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_GET_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "getting impact",
                UCPayloadType.SICO_STORAGE_GET_ENTITY,
                responsePayload,
            )
        ) {
            let storageResponse = StorageResponse.deserializeBinary(
                responsePayload.data,
            )
            let _impact = Impact.deserializeBinary(
                storageResponse.single_entity_data,
            )

            setImpact(_impact)
        }
    }, [responsePayload])

    return [impact, getImpact]
}

export const useUsercommUpdateImpactGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (impact: Impact) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const updateImpact = useCallback(
        (impact: Impact) => {
            if (impact === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.IMPACT,
                entity_uuid: impact.uuid,
                parent_uuid: impact.zone_uuid,
                single_entity_data: impact.serializeBinary(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "updating impact",
                UCPayloadType.SICO_STORAGE_UPDATE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, updateImpact]
}

export const useUsercommDeleteImpactHardGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (impactUUID: UUID) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const deleteImpact = useCallback(
        (impactUUID: UUID | null) => {
            if (impactUUID === null) {
                return
            }
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.IMPACT,
                entity_uuid: impactUUID,
                current_timestamp: Date.now(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting impact",
                UCPayloadType.SICO_STORAGE_DELETE_ENTITY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, deleteImpact]
}

export const useUsercommSetImpactParentGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [
    UCAck | null,
    (impactUUID: UUID, newParentUUID: UUID, current_timestamp: number) => void,
] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "setting impact parent",
                UCPayloadType.SICO_STORAGE_SET_ENTITY_PARENT,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    const setImpactParentUUID = useCallback(
        (
            impactUUID: UUID | null,
            newParentUUID: UUID | null,
            current_timestamp: number,
        ) => {
            if (impactUUID === null || newParentUUID === null) {
                return
            }
            console.log(
                `Usercomm: about to set impact parent`,
                impactUUID,
                newParentUUID,
            )
            let storageRequest = new StorageRequest({
                entity_type: StorageEntityType.IMPACT,
                entity_uuid: impactUUID,
                parent_uuid: newParentUUID,
                current_timestamp: current_timestamp,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_STORAGE_SET_ENTITY_PARENT,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    return [ack, setImpactParentUUID]
}

export const useUsercommLegacyHostApiGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [LegacyHostApiResponse | null, (path: string | null) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [legacyHostApiResponse, setLegacyHostApiResponse] =
        useState<LegacyHostApiResponse | null>(null)

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let legacyHostApiResponse = LegacyHostApiResponse.deserializeBinary(
            responsePayload.data,
        )
        setLegacyHostApiResponse(legacyHostApiResponse)
    }, [responsePayload])

    const sendLegacyHostApiRequest = useCallback(
        (legacyHostApiRequest: LegacyHostApiRequest) => {
            let payload = new UCPayload({
                type: UCPayloadType.SICO_LEGACY_HOST_API_REQUEST,
                data: legacyHostApiRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    const requestLegacyHostApi = useCallback((path: string | null) => {
        if (path === null) {
            return
        }
        let legacyHostApiRequest = new LegacyHostApiRequest({
            path: path,
        })
        sendLegacyHostApiRequest(legacyHostApiRequest)
    }, [])

    return [legacyHostApiResponse, requestLegacyHostApi]
}

export const useUsercommGetReleasesGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [ReleaseResponse | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [releases, setReleases] = useState<ReleaseResponse | null>(null)

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let releaseResponse = ReleaseResponse.deserializeBinary(
            responsePayload.data,
        )
        setReleases(releaseResponse)
    }, [responsePayload])

    const getReleases = useCallback(() => {
        let payload = new UCPayload({
            type: UCPayloadType.SICO_GET_RELEASES,
        })
        setRequestPayload(payload)
    }, [setRequestPayload])

    return [releases, getReleases]
}

export const useUsercommRenameReleaseGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (releaseKey: string, newReleaseKey: string) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const renameRelease = useCallback(
        (releaseKey: string, newReleaseKey: string) => {
            if (releaseKey === null || newReleaseKey === null) {
                return
            }
            let releaseRequest = new ReleaseRequest({
                release_key: releaseKey,
                new_release_key: newReleaseKey,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_RENAME_RELEASE,
                data: releaseRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "renaming release",
                UCPayloadType.SICO_RENAME_RELEASE,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, renameRelease]
}

export const useUsercommDeleteReleaseGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (releaseKey: string) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)
    const [releaseKey, setReleaseKey] = useState<string | null>(null)
    const deleteRelease = useCallback(
        (releaseKey: string) => {
            if (releaseKey === null) {
                return
            }
            let deleteReleaseRequest = new ReleaseRequest({
                release_key: releaseKey,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_DELETE_RELEASE,
                data: deleteReleaseRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "deleting release",
                UCPayloadType.SICO_DELETE_RELEASE,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, deleteRelease]
}

export const useUsercommSetReleaseGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [
    ReleaseResponse | null,
    (releaseKey: string, releaseData: Uint8Array, offset: number) => void,
] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [releaseResponse, setReleaseResponse] =
        useState<ReleaseResponse | null>(null)

    const requestSetRelease = useCallback(
        async (
            releaseKey: string,
            releaseValue: Uint8Array,
            offset: number,
        ) => {
            if (releaseKey === null || releaseValue === null) {
                return
            }
            console.log(`Usercomm: about to set release`, releaseKey, offset)
            let setReleaseRequest = new ReleaseRequest({
                release_key: releaseKey,
                release_data: releaseValue,
                offset: offset,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_SET_RELEASE,
                data: setReleaseRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        // Handle the case of "error opening release file for write: open : no such file or directory"
        if (responsePayload.type === UCPayloadType.SICO_ERROR) {
            let error = UCError.deserializeBinary(responsePayload.data)
            if (error.value.includes("no such file or directory")) {
                console.warn(
                    `Usercomm: error opening release file for write: open : no such file or directory`,
                )
                let _releaseResponse = new ReleaseResponse({
                    current_release_offset: -1,
                })
                setReleaseResponse(_releaseResponse)
                return
            }
        }
        if (
            _handleUCPayloadError(
                "setting release data on offset",
                UCPayloadType.SICO_SET_RELEASE,
                responsePayload,
            )
        ) {
            let releaseResponse = ReleaseResponse.deserializeBinary(
                responsePayload.data,
            )
            setReleaseResponse(releaseResponse)
        }
    }, [responsePayload])

    return [releaseResponse, requestSetRelease]
}

export const useUsercommBashCommandGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (command: string, isInteractive: boolean) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const requestBashCommand = useCallback(
        (cmd: string, isInteractive: boolean) => {
            if (cmd === null) {
                return
            }
            let bashCommandRequest = new BashCmd({
                cmd: cmd,
                is_interactive: isInteractive,
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_BASH_CMD,
                data: bashCommandRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "executing bash command",
                UCPayloadType.SICO_BASH_CMD,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, requestBashCommand]
}

export const useUsercommWPAGetStatusGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [WPAStatusResponse | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [wpaStatus, setWPAStatus] = useState<WPAStatusResponse | null>(null)

    const requestWPAStatus = useCallback(() => {
        setWPAStatus(null)
        let payload = new UCPayload({
            type: UCPayloadType.SICO_WPA_GET_STATUS,
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let wpaStatus = WPAStatusResponse.deserializeBinary(
            responsePayload.data,
        )
        setWPAStatus(wpaStatus)
    }, [responsePayload])

    return [wpaStatus, requestWPAStatus]
}

export const useUsercommWPAGetNetworksGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [WPAGetNetworksResponse | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [wpaNetworks, setWPANetworks] =
        useState<WPAGetNetworksResponse | null>(null)

    const requestWPANetworks = useCallback(() => {
        setWPANetworks(null)
        let payload = new UCPayload({
            type: UCPayloadType.SICO_WPA_GET_NETWORKS,
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let wpaNetworks = WPAGetNetworksResponse.deserializeBinary(
            responsePayload.data,
        )
        setWPANetworks(wpaNetworks)
    }, [responsePayload])

    return [wpaNetworks, requestWPANetworks]
}

export const useUsercommWPASetNetworksGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (networks: WPASetNetworksRequest) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const setWPANetworks = useCallback(
        (networks: WPASetNetworksRequest) => {
            if (networks === null) {
                return
            }
            let payload = new UCPayload({
                type: UCPayloadType.SICO_WPA_SET_NETWORKS,
                data: networks.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [setRequestPayload],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "setting WPA networks",
                UCPayloadType.SICO_WPA_SET_NETWORKS,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, setWPANetworks]
}

export const useUsercommWPAScanResultsGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [WPAScanResultsResponse | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [wpaScanResults, setWPAScanResults] =
        useState<WPAScanResultsResponse | null>(null)

    const requestWPAScanResults = useCallback(() => {
        let payload = new UCPayload({
            type: UCPayloadType.SICO_WPA_GET_SCAN_RESULTS,
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let wpaScanResults = WPAScanResultsResponse.deserializeBinary(
            responsePayload.data,
        )
        setWPAScanResults(wpaScanResults)
    }, [responsePayload])

    return [wpaScanResults, requestWPAScanResults]
}

export const useUsercommWPAReassociateGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const requestWPAReassociate = useCallback(() => {
        let payload = new UCPayload({
            type: UCPayloadType.SICO_WPA_REASSOCIATE,
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "reassociating WPA",
                UCPayloadType.SICO_WPA_REASSOCIATE,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, requestWPAReassociate]
}

export const useUsercommNetworkGetPrimaryIPAddressesGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [NetworkGetPrimaryIPAddressInfoResponse | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [networkPrimaryIPAddresses, setNetworkPrimaryIPAddresses] =
        useState<NetworkGetPrimaryIPAddressInfoResponse | null>(null)

    const requestNetworkPrimaryIPAddresses = useCallback(() => {
        setNetworkPrimaryIPAddresses(null)
        let payload = new UCPayload({
            type: UCPayloadType.SICO_NETWORK_GET_PRIMARY_IP_ADDRESSES,
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let networkPrimaryIPAddresses =
            NetworkGetPrimaryIPAddressInfoResponse.deserializeBinary(
                responsePayload.data,
            )
        setNetworkPrimaryIPAddresses(networkPrimaryIPAddresses)
    }, [responsePayload])

    return [networkPrimaryIPAddresses, requestNetworkPrimaryIPAddresses]
}

export const useUsercommDownloadReleaseByUriGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (uri: string) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const requestDownloadReleaseByUri = useCallback((uri: string) => {
        let downloadReleaseRequest = new ReleaseRequest({
            release_uri: uri,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_DOWNLOAD_RELEASE_BY_URI,
            data: downloadReleaseRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "downloading release by URI",
                UCPayloadType.SICO_DOWNLOAD_RELEASE_BY_URI,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, requestDownloadReleaseByUri]
}

export const useUsercommGetStationStateGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [StationState | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [stationState, setStationState] = useState<StationState | null>(null)

    const requestStationState = useCallback(() => {
        setStationState(null)
        let payload = new UCPayload({
            type: UCPayloadType.SICO_GET_STATION_STATE,
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let stationState = StationState.deserializeBinary(responsePayload.data)
        setStationState(stationState)
    }, [responsePayload])

    return [stationState, requestStationState]
}

export const useUsercommGetStationConfigGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [StationConfig | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [stationConfig, setStationConfig] = useState<StationConfig | null>(
        null,
    )

    const requestStationConfig = useCallback(() => {
        setStationConfig(null)
        let payload = new UCPayload({
            type: UCPayloadType.SICO_GET_STATION_CONFIG_COMMAND,
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let stationConfig = StationConfig.deserializeBinary(
            responsePayload.data,
        )
        setStationConfig(stationConfig)
    }, [responsePayload])

    return [stationConfig, requestStationConfig]
}

export const useUsercommGetHICConfigGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [HICConfig | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [hicConfig, setHICConfig] = useState<HICConfig | null>(null)

    const requestHICConfig = useCallback(() => {
        setHICConfig(null)
        let payload = new UCPayload({
            type: UCPayloadType.SICO_GET_HIC_CONFIG_COMMAND,
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let hicConfig = HICConfig.deserializeBinary(responsePayload.data)
        setHICConfig(hicConfig)
    }, [responsePayload])

    return [hicConfig, requestHICConfig]
}

export const useUsercommGNSSUbloxGetStateGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [GNSSUbloxState | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [gnssState, setGNSSState] = useState<GNSSUbloxState | null>(null)

    const requestGNSSState = useCallback(() => {
        // setGNSSState(null)
        let payload = new UCPayload({
            type: UCPayloadType.SICO_GNSS_UBLOX_GET_STATE,
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let gnssState = GNSSUbloxState.deserializeBinary(responsePayload.data)
        setGNSSState(gnssState)
    }, [responsePayload])

    return [gnssState, requestGNSSState]
}

export const useUsercommGNSSUbloxGetALPFilesGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [GNSSUbloxGetALPFilesResponse | null, () => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [alpFiles, setALPFiles] =
        useState<GNSSUbloxGetALPFilesResponse | null>(null)

    const requestALPFiles = useCallback(() => {
        setALPFiles(null)
        let payload = new UCPayload({
            type: UCPayloadType.SICO_GNSS_UBLOX_GET_ALP_FILES,
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        let alpFiles = GNSSUbloxGetALPFilesResponse.deserializeBinary(
            responsePayload.data,
        )
        setALPFiles(alpFiles)
    }, [responsePayload])

    return [alpFiles, requestALPFiles]
}

export const useUsercommGNSSUbloxSetALPFileGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (alpFile: GNSSUbloxSetALPFileRequest) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const setALPFile = useCallback((alpFile: GNSSUbloxSetALPFileRequest) => {
        if (alpFile === null) {
            return
        }
        let payload = new UCPayload({
            type: UCPayloadType.SICO_GNSS_UBLOX_SET_ALP_FILE,
            data: alpFile.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "setting ALP file",
                UCPayloadType.SICO_GNSS_UBLOX_SET_ALP_FILE,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, setALPFile]
}

export const useUsercommGNSSUbloxWriteRTCMGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (rtcm: GNSSUbloxWriteRTCMRequest) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const writeRTCM = useCallback((rtcm: GNSSUbloxWriteRTCMRequest) => {
        if (rtcm === null) {
            return
        }
        let payload = new UCPayload({
            type: UCPayloadType.SICO_GNSS_UBLOX_WRITE_RTCM,
            data: rtcm.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "writing RTCM",
                UCPayloadType.SICO_GNSS_UBLOX_WRITE_RTCM,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, writeRTCM]
}

export const useUsercommGNSSUbloxResetGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [UCAck | null, (resetType: number) => void] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const requestReset = useCallback((resetType: number) => {
        let resetRequest = new GNSSUbloxResetRequest({
            reset_type: resetType,
        })
        let payload = new UCPayload({
            type: UCPayloadType.SICO_GNSS_UBLOX_RESET,
            data: resetRequest.serializeBinary(),
        })
        setRequestPayload(payload)
    }, [])

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "resetting GNSS",
                UCPayloadType.SICO_GNSS_UBLOX_RESET,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, requestReset]
}

export const useUsercommSetDeviceTelemetryGen = (
    useAsyncRequest: UsercommAsyncRequestHook,
): [
    UCAck | null,
    (deviceUUID: UUID, deviceTelemetry: DeviceTelemetry) => void,
] => {
    const [responsePayload, setRequestPayload] = useAsyncRequest()
    const [ack, setAck] = useState<UCAck | null>(null)

    const setDeviceTelemetry = useCallback(
        (deviceUUID: UUID, deviceTelemetry: DeviceTelemetry) => {
            if (deviceTelemetry === null) {
                return
            }
            let storageRequest = new StorageRequest({
                parent_uuid: deviceUUID,
                single_entity_data: deviceTelemetry.serializeBinary(),
            })
            let payload = new UCPayload({
                type: UCPayloadType.SICO_SET_DEVICE_TELEMETRY,
                data: storageRequest.serializeBinary(),
            })
            setRequestPayload(payload)
        },
        [],
    )

    useEffect(() => {
        if (responsePayload === null) {
            return
        }
        if (
            _handleUCPayloadError(
                "setting device telemetry",
                UCPayloadType.SICO_SET_DEVICE_TELEMETRY,
                responsePayload,
            )
        ) {
            let _ack = UCAck.deserializeBinary(responsePayload.data)
            setAck(_ack)
        }
    }, [responsePayload])

    return [ack, setDeviceTelemetry]
}
