import { FC, useEffect, useMemo, useState } from "react"
import { Translated } from "../utils/translated"
import { Tabs, TabsProps } from "antd"
import { WifiOutlined } from "@ant-design/icons"
import { FlexCol } from "../components/commons-ts/common"
import { StationConfigSettings } from "./Settings/StationConfigSettings"
import { useHistory, useLocation } from "react-router-dom"
import { HeadHICConfigSettings } from "./Settings/HeadHICConfigSettings"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faHardDrive,
    faSatellite,
    faTimeline,
} from "@fortawesome/free-solid-svg-icons"
import { DeviceHistorySettings } from "./Settings/DeviceHistorySettings"
import { WifiSettingsNative } from "./Settings/WifiSettingsNative"
import { ReactComponent as HicIcon } from "../svg/hic-icon.svg"
import { GNSSUbloxSettings } from "./Settings/GNSSUbloxSettings"
import { useSyncronizationContext } from "../providers/syncronizationProvider"

enum ETabKey {
    DeviceHistory = "history",
    StationConfig = "station",
    HeadHICConfig = "headhic",
    WifiConfig = "wifi",
    GNSSConfig = "gnss",
}

export const SettingsDevice: FC = () => {
    const location = useLocation()
    const history = useHistory()
    const [selectedTabKey, setSelectedTabKey] = useState<ETabKey>(
        ETabKey.StationConfig,
    )
    const { isAdmin } = useSyncronizationContext()

    useEffect(() => {
        let hash = location.hash
        if (!hash) {
            return
        }
        hash = hash.replace("#", "")
        switch (hash) {
            case ETabKey.StationConfig:
                setSelectedTabKey(ETabKey.StationConfig)
                break
            case ETabKey.HeadHICConfig:
                setSelectedTabKey(ETabKey.HeadHICConfig)
                break
            case ETabKey.WifiConfig:
                setSelectedTabKey(ETabKey.WifiConfig)
                break
            case ETabKey.DeviceHistory:
                setSelectedTabKey(ETabKey.DeviceHistory)
                break
            case ETabKey.GNSSConfig:
                setSelectedTabKey(ETabKey.GNSSConfig)
                break
        }
    }, [])

    const memoTabItems = useMemo(() => {
        let items: TabsProps["items"] = [
            {
                key: ETabKey.DeviceHistory,
                label: (
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="Device History" />
                    </span>
                ),
                icon: <FontAwesomeIcon icon={faTimeline} />,
                children: <DeviceHistorySettings />,
            },
            {
                key: ETabKey.StationConfig,
                label: (
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="Station" />
                    </span>
                ),
                icon: <FontAwesomeIcon icon={faHardDrive} />,
                children: <StationConfigSettings />,
            },
            {
                key: ETabKey.HeadHICConfig,
                label: (
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="Head HIC" />
                    </span>
                ),
                icon: (
                    <HicIcon
                        className="hic-icon"
                        width={24}
                        style={{
                            marginRight: -3,
                            marginBottom: -9,
                        }}
                    />
                ),
                children: <HeadHICConfigSettings />,
            },
            {
                key: ETabKey.WifiConfig,
                label: (
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="Wifi" />
                    </span>
                ),
                icon: <WifiOutlined />,
                children: <WifiSettingsNative />,
            },
            {
                key: ETabKey.GNSSConfig,
                label: (
                    <span
                        style={{
                            marginLeft: 5,
                        }}
                    >
                        <Translated keyEn="GNSS" />
                    </span>
                ),
                icon: <FontAwesomeIcon icon={faSatellite} />,
                children: <GNSSUbloxSettings />,
            },
        ]
        return items
    }, [isAdmin])

    return (
        <FlexCol
            style={{
                width: "80vw",
                maxWidth: "55rem", // MAX_WIDTH_CENTRAL_CONTAINER,
                margin: "auto",
                gap: 30,
                marginBottom: "2rem",
                alignItems: "center",
            }}
        >
            <Tabs
                style={{
                    width: "100%",
                }}
                destroyInactiveTabPane
                // centered
                animated
                tabBarGutter={20}
                activeKey={selectedTabKey}
                onChange={(key: string) => {
                    setSelectedTabKey(key as ETabKey)
                    // set the hash
                    history.push(`#${key}`)
                }}
                size="large"
                items={memoTabItems}
            ></Tabs>
        </FlexCol>
    )
}
