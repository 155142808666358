import {
    FC,
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"
import { useHistory, useLocation } from "react-router-dom"
import dayjs from "dayjs"
import { MeanWithStd } from "../types"
import {
    FlexCol,
    FlexRow,
    RequirementsAlertSM,
    UnderlinedSectionTitle,
} from "../components/commons-ts/common"
import { ErrorAlert } from "../components/commons-ts/errorAlert"
import { Translated } from "../utils/translated"
import {
    Alert,
    Button,
    Col,
    DatePicker,
    Input,
    Popconfirm,
    Popover,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Typography,
    message as antdMessage,
} from "antd"
import {
    DeleteOutlined,
    InfoCircleTwoTone,
    SaveOutlined,
} from "@ant-design/icons"
import { SimplifiedBreadcrumb } from "../components/commons-ts/simplifiedBreadcrumb"
import { Label, LabeledInput } from "../components/commons-ts/input"
import {
    isLabTestElement,
    methodElement,
    normTypeElement,
    sportsMatTypeDescriptionStr,
} from "../components/commons-ts/tags"
import {
    ENTITY_SAVE_DEBOUNCE_DELAY_MS,
    MAX_WIDTH_CENTRAL_CONTAINER,
    parsePathForEntityUUID,
    pbUUIDToUuid,
    uuidToPbUUID,
} from "../utils/utils"
import {
    MatTypeEN12503,
    MethodTypeEN1177,
    NormType,
    Site,
    UUID,
    Zone,
} from "../generated/proto-ts/main"
import { UploadImageListV2 } from "../components/commons-ts/uploadImageV2"
import { DataTreeDrawer } from "../components/commons-ts/dataTreeDrawer"
import {
    EquipmentZonesTablePF_ADQ,
    EquipmentZonesTablePF_CFH,
} from "./Tables/02_EquipmentZones/EquipmentZonesTable_PF"
import { EquipmentZonesTableSM } from "./Tables/02_EquipmentZones/EquipmentZonesTable_SM"
import { EquipmentZonesTableTL } from "./Tables/02_EquipmentZones/EquipmentZonesTable_TL"
import { EquipmentZonesTableAB } from "./Tables/02_EquipmentZones/EquipmentZonesTable_AB"
import { v4 as uuidv4 } from "uuid"
import {
    useUsercommCreateZoneBimodal,
    useUsercommDeleteEquipmentBimodal,
    useUsercommEquipmentBimodal,
    useUsercommEquipmentZonesBimodal,
    useUsercommSiteBimodal,
    useUsercommUpdateEquipmentBimodal,
} from "../usercomm/common/usercommAsyncRequestBimodal"
import { useDebounce } from "../utils/debounce"
import { EntityTimestamps } from "../components/commons-ts/entityTimestamps"

export const getEquipmentSportsMatThicknessMeanAndStd = (
    thicknesses: (number | null)[],
): MeanWithStd | null => {
    let thicknessesFiltered = []
    for (let thickness of thicknesses) {
        if (thickness !== null && thickness !== 0) {
            thicknessesFiltered.push(thickness)
        }
    }
    if (thicknessesFiltered.length === 0) {
        return null
    }
    let mean =
        thicknessesFiltered.reduce((a, b) => a + b, 0) /
        thicknessesFiltered.length
    let std = Math.sqrt(
        thicknessesFiltered
            .map((v) => (v - mean) ** 2)
            .reduce((a, b) => a + b, 0) / thicknessesFiltered.length,
    )
    return {
        mean,
        std,
        count: thicknessesFiltered.length,
    }
}

const LabeledInputInMM: FC<{
    label: string
    value: number | null
    setValue: (value: number | null) => void
}> = ({ label, value, setValue }) => {
    const setValueStr = useCallback(
        (v: string | null) => {
            if (v === null) {
                setValue(null)
                return
            }
            let value = parseInt(v)
            if (isNaN(value)) {
                setValue(null)
                return
            }
            setValue(value)
        },
        [setValue],
    )
    const memoValueStr = useMemo(() => {
        if (value === null) {
            return ""
        }
        return value.toFixed(0)
    }, [value])
    return (
        <LabeledInput
            translatableLabel={label}
            value={memoValueStr}
            setValue={setValueStr}
            addonAfter="mm"
        />
    )
}

const LabeledInputInCM: FC<{
    label: string
    value: number | null
    setValue: (value: number | null) => void
}> = ({ label, value, setValue }) => {
    const setValueStr = useCallback(
        (v: string | null) => {
            if (v === null) {
                setValue(null)
                return
            }
            let value = 10 * parseInt(v)
            if (isNaN(value)) {
                setValue(null)
                return
            }
            setValue(value)
        },
        [setValue],
    )
    const memoValueStr = useMemo(() => {
        if (value === null) {
            return ""
        }
        return (value / 10).toFixed(0)
    }, [value])
    return (
        <LabeledInput
            translatableLabel={label}
            value={memoValueStr}
            setValue={setValueStr}
            addonAfter="cm"
        />
    )
}

const NewZoneButton: FC<{
    equipmentUUID: UUID | null
    zoneName: string | null
    zoneFFH: number | null
}> = ({ equipmentUUID, zoneName, zoneFFH }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const history = useHistory()

    const [createdZoneUUID, createZoneRequest] = useUsercommCreateZoneBimodal()

    // REQUEST CREATE ZONE
    const onCreate = useCallback(async () => {
        setIsLoading(true)
        if (equipmentUUID === null || zoneName === null) {
            setIsLoading(false)
            return
        }
        setIsLoading(true)
        let ts = Date.now()
        createZoneRequest(
            equipmentUUID,
            new Zone({
                uuid: uuidToPbUUID(uuidv4()), // IT IS CREATOR'S RESPONSIBILITY TO GENERATE A NEW UUID
                equipment_uuid: equipmentUUID,
                zone_name: zoneName,
                zone_ffh_max: zoneFFH ?? undefined,
                created_at: ts,
                updated_at: ts,
            }),
        )
    }, [equipmentUUID, zoneName, zoneFFH])
    // RESPONSE CREATE ZONE
    useEffect(() => {
        if (createdZoneUUID === null) {
            return
        }
        history.push(`/zones/${pbUUIDToUuid(createdZoneUUID)}`)
    }, [createdZoneUUID])

    return (
        <Button
            disabled={equipmentUUID === null || zoneName === null}
            onClick={onCreate}
            loading={isLoading}
            size="large"
            type="primary"
        >
            <span
                style={{
                    textTransform: "uppercase",
                }}
            >
                <Translated keyEn="Start" />
            </span>
        </Button>
    )
}

export const EquipmentPage: FC = () => {
    const location = useLocation()
    const history = useHistory()
    const memoEquipmentUUID = useMemo((): UUID | null => {
        let equipmentUUID = parsePathForEntityUUID(location.pathname)
        if (equipmentUUID === null) {
            return null
        }
        return uuidToPbUUID(equipmentUUID)
    }, [location.pathname])

    // Site getter
    const [site, getSite] = useUsercommSiteBimodal()

    // Site fields
    const [siteUUIDStr, setSiteUUIDStr] = useState<string | null>(null) // use string to avoid effect re-firing on UUID ref change
    const [siteName, setSiteName] = useState<string | null>(null)
    const [normType, setNormType] = useState<NormType | null>(null)
    const [method, setMethod] = useState<MethodTypeEN1177 | null>(null)
    const [isLabTest, setIsLabTest] = useState<boolean | null>(null)

    // Equipment CRUD
    const [equipment, getEquipment, externallySetEquipment] =
        useUsercommEquipmentBimodal()
    const [updatedEquipmentAck, updateEquipment] =
        useUsercommUpdateEquipmentBimodal()
    const [deleteEquipmentAck, deleteEquipment] =
        useUsercommDeleteEquipmentBimodal()

    // Equipment fields
    const [equipmentName, setEquipmentName] = useState<string | null>(null)
    const [equipmentInstallationDate, setEquipmentDate] =
        useState<dayjs.Dayjs | null>(null)
    const [equipmentManufacturer, setEquipmentManufacturer] = useState<
        string | null
    >(null)
    const [equipmentRef, setEquipmentRef] = useState<string | null>(null)
    const [maxFreeFallHeight, setMaxFreeFallHeight] = useState<string | null>(
        null,
    )
    const [floorCondition, setFloorCondition] = useState<string | null>(null)
    const [floorDate, setFloorDate] = useState<dayjs.Dayjs | null>(null)
    const [floorManufacturer, setFloorManufacturer] = useState<string | null>(
        null,
    )
    const [floorKind, setFloorKind] = useState<string | null>(null)
    const [floorRef, setFloorRef] = useState<string | null>(null)
    const [floorSubstrat, setFloorSubstrat] = useState<string | null>(null)

    // Zones getter
    const [zones, getZones] = useUsercommEquipmentZonesBimodal()

    // Dimensions are stored as integers in mm
    const [sportsMatDimensionLength, setSportsMatDimensionLength] = useState<
        number | null
    >(null)
    const [sportsMatDimensionWidth, setSportsMatDimensionWidth] = useState<
        number | null
    >(null)
    const [
        sportsMatDimensionThicknessSide1,
        setSportsMatDimensionThicknessSide1,
    ] = useState<number | null>(null)
    const [
        sportsMatDimensionThicknessSide2,
        setSportsMatDimensionThicknessSide2,
    ] = useState<number | null>(null)
    const [
        sportsMatDimensionThicknessSide3,
        setSportsMatDimensionThicknessSide3,
    ] = useState<number | null>(null)
    const [
        sportsMatDimensionThicknessSide4,
        setSportsMatDimensionThicknessSide4,
    ] = useState<number | null>(null)
    const [sportsMatType, setSportsMatType] = useState<MatTypeEN12503 | null>(
        MatTypeEN12503.TYPE_EN12572,
    )
    const [sportsMatMetalFrameIsInPlace, setSportsMatMetalFrameIsInPlace] =
        useState<boolean | null>(null)

    const [trampolineImpactorWeightStr, setTrampolineImpactorWeightStr] =
        useState<string | null>(null)

    // const [geolocation, setGeolocation] = useState<string | null>(null)

    const [pictures, setPictures] = useState<Site["pictures"]>([])
    const [newZoneName, setNewZoneName] = useState<string | null>(null)

    const [error, setError] = useState<string | null>(null)

    // GET EQUIPMENT REQUEST
    useEffect(() => {
        if (memoEquipmentUUID === null) {
            return
        }
        getEquipment(memoEquipmentUUID)
    }, [memoEquipmentUUID])
    // GET EQUIPMENT RESPONSE
    useEffect(() => {
        if (equipment === null) {
            return
        }
        console.log(`EquipmentPage: equipment:`, equipment.toObject())
        if (equipment.site_uuid !== undefined) {
            setSiteUUIDStr(pbUUIDToUuid(equipment.site_uuid))
        } else {
            console.warn(`EquipmentPage: equipment.site_uuid is undefined`)
        }
        setEquipmentName(equipment.equipment_name)
        if (equipment.equipment_installation_date === 0) {
            setEquipmentDate(null)
        } else {
            setEquipmentDate(dayjs(equipment.equipment_installation_date))
        }
        setEquipmentManufacturer(equipment.equipment_manufacturer)
        setEquipmentRef(equipment.equipment_reference)
        setMaxFreeFallHeight(equipment.equipment_ffh_max.toFixed(2))
        setFloorCondition(equipment.floor_condition)
        if (equipment.floor_installation_date === 0) {
            setFloorDate(null)
        } else {
            setFloorDate(dayjs(equipment.floor_installation_date))
        }
        setFloorManufacturer(equipment.floor_manufacturer)
        setFloorKind(equipment.floor_kind)
        setFloorRef(equipment.floor_reference)
        setFloorSubstrat(equipment.floor_substrat)
        setPictures(equipment.pictures)
        if (equipment.sports_mat_type > 0) {
            setSportsMatType(equipment.sports_mat_type)
        } else {
            setSportsMatType(null)
        }
        setSportsMatMetalFrameIsInPlace(
            equipment.sports_mat_metal_frame_is_present,
        )
        setSportsMatDimensionLength(equipment.sports_mat_length)
        setSportsMatDimensionWidth(equipment.sports_mat_width)
        setSportsMatDimensionThicknessSide1(
            equipment.sports_mat_thickness_side_one ?? null,
        )
        setSportsMatDimensionThicknessSide2(
            equipment.sports_mat_thickness_side_two ?? null,
        )
        setSportsMatDimensionThicknessSide3(
            equipment.sports_mat_thickness_side_three ?? null,
        )
        setSportsMatDimensionThicknessSide4(
            equipment.sports_mat_thickness_side_four ?? null,
        )
        setSportsMatMetalFrameIsInPlace(
            equipment.sports_mat_metal_frame_is_present,
        )
        setTrampolineImpactorWeightStr(
            equipment.trampoline_impactor_weight.toFixed(2),
        )
    }, [equipment])

    // GET ZONES REQUEST
    useEffect(() => {
        if (memoEquipmentUUID === null) {
            return
        }
        getZones(memoEquipmentUUID)
    }, [memoEquipmentUUID])
    // GET ZONES RESPONSE
    useEffect(() => {
        if (zones === null) {
            return
        }
        console.log(`EquipmentPage: zones:`, zones)
    }, [zones])

    // GET SITE REQUEST
    useEffect(() => {
        if (siteUUIDStr === null) {
            return
        }
        getSite(uuidToPbUUID(siteUUIDStr))
    }, [siteUUIDStr])
    // GET SITE RESPONSE
    useEffect(() => {
        if (site === null) {
            return
        }
        console.log(`EquipmentPage: parent site:`, site.toObject())
        setSiteName(site.site_name)
        setNormType(site.norm_type)
        setMethod(site.method_type_en_1177)
        setIsLabTest(site.is_lab_test)
    }, [site])

    const memoNormTypeElement = useMemo(() => {
        return normTypeElement(normType)
    }, [normType])

    const memoMethodElement = useMemo(() => {
        if (normType === null || normType === NormType.EN_12503) {
            return null
        }
        return methodElement(method)
    }, [normType, method])

    const memoIsLabTestElement = useMemo(() => {
        return isLabTestElement(isLabTest)
    }, [isLabTest])

    const memoSportsMatThicknessMeanAndStd = useMemo((): MeanWithStd | null => {
        return getEquipmentSportsMatThicknessMeanAndStd([
            sportsMatDimensionThicknessSide1,
            sportsMatDimensionThicknessSide2,
            sportsMatDimensionThicknessSide3,
            sportsMatDimensionThicknessSide4,
        ])
    }, [
        sportsMatDimensionThicknessSide1,
        sportsMatDimensionThicknessSide2,
        sportsMatDimensionThicknessSide3,
        sportsMatDimensionThicknessSide4,
    ])

    const memoZonesTable = useMemo(() => {
        if (memoEquipmentUUID === null) {
            return null
        }
        console.log(`EquipmentPage: memoZonesTable:`, zones, normType, method)
        if (normType === NormType.EN_12503) {
            return (
                <EquipmentZonesTableSM
                    equipmentUUID={memoEquipmentUUID}
                    zones={zones}
                    smType={sportsMatType}
                    smThickness={memoSportsMatThicknessMeanAndStd}
                />
            )
        } else if (normType === NormType.EN_ISO_23659) {
            let impactorWeight = 6.25
            if (
                trampolineImpactorWeightStr !== null &&
                trampolineImpactorWeightStr !== "" &&
                trampolineImpactorWeightStr !== "0"
            ) {
                impactorWeight = parseFloat(trampolineImpactorWeightStr)
            }
            return (
                <EquipmentZonesTableTL
                    equipmentUUID={memoEquipmentUUID}
                    equipmentImpactorWeight={impactorWeight}
                    zones={zones}
                />
            )
        } else if (normType === NormType.EN_14960) {
            return (
                <EquipmentZonesTableAB
                    equipmentUUID={memoEquipmentUUID}
                    zones={zones}
                />
            )
        } else if (normType === NormType.EN_1177) {
            if (
                method === MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
            ) {
                return (
                    <EquipmentZonesTablePF_CFH
                        equipmentUUID={memoEquipmentUUID}
                        zones={zones}
                    />
                )
            } else if (
                method === MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE
            ) {
                return (
                    <EquipmentZonesTablePF_ADQ
                        equipmentUUID={memoEquipmentUUID}
                        zones={zones}
                    />
                )
            }
        }
    }, [
        memoEquipmentUUID,
        trampolineImpactorWeightStr,
        zones,
        normType,
        method,
        sportsMatType,
        memoSportsMatThicknessMeanAndStd,
    ])

    const memoSportsMatLayoutElement = useMemo((): ReactElement | null => {
        if (sportsMatType === null || normType !== NormType.EN_12503) {
            return null
        }
        let src = ""
        let legend: ReactElement | null = null
        switch (sportsMatType) {
            case MatTypeEN12503.TYPE_1:
            case MatTypeEN12503.TYPE_2:
            case MatTypeEN12503.TYPE_3:
            case MatTypeEN12503.TYPE_4:
            case MatTypeEN12503.TYPE_5:
            case MatTypeEN12503.TYPE_6:
            case MatTypeEN12503.TYPE_7:
            case MatTypeEN12503.TYPE_8:
                src = "/figure-tapis-types-1-8.png"
                legend = <Translated keyEn="Dimensions in cm" />
                break
            case MatTypeEN12503.TYPE_9:
            case MatTypeEN12503.TYPE_10:
            case MatTypeEN12503.TYPE_11:
                src = "/figure-tapis-types-9-11.png"
                legend = (
                    <FlexCol>
                        <Translated keyEn="Dimensions in cm" />
                        <ul>
                            <li>
                                a - <Translated keyEn="length of the mat" />
                            </li>
                            <li>
                                b - <Translated keyEn="width of the mat" />
                            </li>
                        </ul>
                    </FlexCol>
                )
                break
            case MatTypeEN12503.TYPE_12:
                src = "/figure-tapis-types-12.png"
                legend = (
                    <FlexCol>
                        <Translated keyEn="Dimensions in cm" />
                        <ul>
                            <li>
                                a - <Translated keyEn="length of the mat" />
                            </li>
                            <li>
                                b - <Translated keyEn="width of the mat" />
                            </li>
                            <li>
                                1-8 -{" "}
                                <Translated keyEn="positions of the test zones" />
                            </li>
                        </ul>
                    </FlexCol>
                )
                break
            case MatTypeEN12503.TYPE_EN12572:
                src = "/figure-tapis-type-en12572.png"
                legend = (
                    <FlexCol>
                        <Translated keyEn="Dimensions in m" />
                    </FlexCol>
                )
        }
        return (
            <Popover
                title={
                    <span
                        style={{
                            textTransform: "uppercase",
                        }}
                    >
                        Sports mat layout for <b>Type #{sportsMatType}</b>
                    </span>
                }
                content={
                    <FlexCol>
                        <img
                            src={src}
                            style={{
                                maxWidth: "500px",
                            }}
                        />
                        <div>
                            <b>
                                <Translated keyEn="Legend" />
                            </b>
                            :<br />
                            {legend}
                        </div>
                    </FlexCol>
                }
                // open
                trigger="click"
            >
                <Button
                    type="text"
                    style={{
                        height: "fit-content",
                    }}
                >
                    <FlexCol
                        style={{
                            gap: 0,
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <img
                                src={src}
                                style={{
                                    width: "100%",
                                }}
                            />
                        </div>
                        <span>
                            <Translated keyEn="Layout" />
                        </span>
                    </FlexCol>
                </Button>
            </Popover>
        )
    }, [sportsMatType, normType])

    const memoSportsMatTargetImpactorDiameter =
        useMemo((): MeanWithStd | null => {
            if (sportsMatType === null || normType !== NormType.EN_12503) {
                return null
            }
            if (sportsMatType === MatTypeEN12503.TYPE_12) {
                return null
            }
            return {
                mean: 150,
                std: 0.5,
            }
        }, [sportsMatType, normType])

    const memoSportsMatTargetImpactorMass = useMemo((): {
        mean: number
        std: number
    } | null => {
        if (sportsMatType === null || normType !== NormType.EN_12503) {
            return null
        }
        switch (sportsMatType) {
            case MatTypeEN12503.TYPE_1:
            case MatTypeEN12503.TYPE_2:
            case MatTypeEN12503.TYPE_3:
                return {
                    mean: 10,
                    std: 0.1,
                }
            case MatTypeEN12503.TYPE_4:
            case MatTypeEN12503.TYPE_5:
            case MatTypeEN12503.TYPE_6:
                return {
                    mean: 20,
                    std: 0.2,
                }
            case MatTypeEN12503.TYPE_7:
            case MatTypeEN12503.TYPE_8:
            case MatTypeEN12503.TYPE_9:
            case MatTypeEN12503.TYPE_10:
            case MatTypeEN12503.TYPE_11:
                return {
                    mean: 30,
                    std: 0.3,
                }
            case MatTypeEN12503.TYPE_12:
                return {
                    mean: 8,
                    std: 0.05,
                }
            case MatTypeEN12503.TYPE_EN12572:
                return {
                    mean: 30,
                    std: 0.3,
                }
        }
        return null
    }, [sportsMatType, normType])

    const memoSportsMatTypeName = useMemo((): string | null => {
        if (sportsMatType === null) {
            return null
        }
        let name = `Type ${sportsMatType}`
        if (sportsMatType === MatTypeEN12503.TYPE_EN12572) {
            name = `Type "EN12572"`
        }
        return name
    }, [sportsMatType])

    const memoSportsMatTargetFFH = useMemo((): MeanWithStd | null => {
        if (sportsMatType === null || normType !== NormType.EN_12503) {
            return null
        }
        if (sportsMatType === MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503) {
            return null
        }
        switch (sportsMatType) {
            case MatTypeEN12503.TYPE_1:
                return {
                    mean: 150,
                    std: 1.0,
                }
            case MatTypeEN12503.TYPE_2:
                return {
                    mean: 300,
                    std: 1.0,
                }
            case MatTypeEN12503.TYPE_3:
                return {
                    mean: 400,
                    std: 1.0,
                }
            case MatTypeEN12503.TYPE_4:
            case MatTypeEN12503.TYPE_5:
            case MatTypeEN12503.TYPE_6:
            case MatTypeEN12503.TYPE_7:
            case MatTypeEN12503.TYPE_8:
                return {
                    mean: 800,
                    std: 1.0,
                }
            case MatTypeEN12503.TYPE_9:
            case MatTypeEN12503.TYPE_10:
                return {
                    mean: 1200,
                    std: 1.0,
                }
            case MatTypeEN12503.TYPE_11:
            case MatTypeEN12503.TYPE_12:
                return null
            case MatTypeEN12503.TYPE_EN12572:
                return {
                    mean: 220,
                    std: 0.3,
                }
        }
    }, [sportsMatType, normType])

    const memoSportsMatTargetNbZones = useMemo((): number | null => {
        if (sportsMatType === null || normType !== NormType.EN_12503) {
            return null
        }
        if (sportsMatType === MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503) {
            return null
        }
        switch (sportsMatType) {
            case MatTypeEN12503.TYPE_1:
            case MatTypeEN12503.TYPE_2:
            case MatTypeEN12503.TYPE_3:
            case MatTypeEN12503.TYPE_4:
            case MatTypeEN12503.TYPE_5:
            case MatTypeEN12503.TYPE_6:
            case MatTypeEN12503.TYPE_7:
            case MatTypeEN12503.TYPE_8:
            case MatTypeEN12503.TYPE_9:
            case MatTypeEN12503.TYPE_10:
            case MatTypeEN12503.TYPE_11:
            case MatTypeEN12503.TYPE_12:
                return 8
            case MatTypeEN12503.TYPE_EN12572:
                return 5
        }
    }, [sportsMatType, normType])

    // DELETE EQUIPMENT REQUEST
    const onDelete = useCallback(async () => {
        if (memoEquipmentUUID === null) {
            return
        }
        deleteEquipment(memoEquipmentUUID)
    }, [memoEquipmentUUID])
    // DELETE EQUIPMENT RESPONSE
    useEffect(() => {
        if (deleteEquipmentAck === null) {
            return
        }
        antdMessage.info(`Equipment deleted!`)
        if (siteUUIDStr !== null) {
            setTimeout(() => {
                history.push(`/sites/${siteUUIDStr}`)
            }, 1000)
        } else {
            console.warn(
                `EquipmentPage: onDelete: received delteEquipmentAck but siteUUID is null`,
            )
        }
    }, [siteUUIDStr, deleteEquipmentAck])

    const memoEquipmentIsMutated = useMemo(() => {
        if (equipment === null) {
            return false
        }
        if (
            equipmentName === null ||
            equipmentRef === null ||
            equipmentManufacturer === null ||
            // equipmentInstallationDate === null || // might be null
            maxFreeFallHeight === null ||
            floorRef === null ||
            floorManufacturer === null ||
            // floorDate === null || // might be null
            floorKind === null ||
            floorSubstrat === null ||
            floorCondition === null ||
            // sportsMatType === null || // might be null
            sportsMatDimensionLength === null ||
            sportsMatDimensionWidth === null ||
            sportsMatDimensionThicknessSide1 === null ||
            sportsMatDimensionThicknessSide2 === null ||
            sportsMatDimensionThicknessSide3 === null ||
            sportsMatDimensionThicknessSide4 === null ||
            sportsMatMetalFrameIsInPlace === null ||
            trampolineImpactorWeightStr === null
        ) {
            return false
        }

        let prevEquipmentDateInt = equipment.equipment_installation_date
        let currEquipmentDateInt: number = 0
        if (equipmentInstallationDate !== null) {
            currEquipmentDateInt = equipmentInstallationDate.toDate().getTime()
        }
        let prevFloorDateInt = equipment.floor_installation_date
        let currFloorDateInt: number = 0
        if (floorDate !== null) {
            currFloorDateInt = floorDate.toDate().getTime()
        }

        let prevPicturesStr = equipment.pictures
            .map((p) => pbUUIDToUuid(p))
            .join(",")
        let currPicturesStr = pictures.map((p) => pbUUIDToUuid(p)).join(",")

        let isMutated =
            equipment.equipment_name !== (equipmentName ?? "") ||
            equipment.equipment_reference !== equipmentRef ||
            equipment.equipment_manufacturer !== equipmentManufacturer ||
            prevEquipmentDateInt !== currEquipmentDateInt ||
            equipment.equipment_ffh_max.toFixed(2) !== maxFreeFallHeight ||
            equipment.floor_reference !== floorRef ||
            equipment.floor_manufacturer !== floorManufacturer ||
            prevFloorDateInt !== currFloorDateInt ||
            equipment.floor_kind !== floorKind ||
            equipment.floor_substrat !== floorSubstrat ||
            equipment.floor_condition !== floorCondition ||
            prevPicturesStr !== currPicturesStr ||
            equipment.sports_mat_type !==
                (sportsMatType ?? MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503) ||
            equipment.sports_mat_length !== sportsMatDimensionLength ||
            equipment.sports_mat_width !== sportsMatDimensionWidth ||
            equipment.sports_mat_thickness_side_one !==
                sportsMatDimensionThicknessSide1 ||
            equipment.sports_mat_thickness_side_two !==
                sportsMatDimensionThicknessSide2 ||
            equipment.sports_mat_thickness_side_three !==
                sportsMatDimensionThicknessSide3 ||
            equipment.sports_mat_thickness_side_four !==
                sportsMatDimensionThicknessSide4 ||
            equipment.sports_mat_metal_frame_is_present !==
                sportsMatMetalFrameIsInPlace ||
            equipment.trampoline_impactor_weight.toFixed(2) !==
                trampolineImpactorWeightStr
        // if (isMutated) {
        //     console.debug(`Equipment: isMutated: ${isMutated}
        //         equipment: ${equipment.equipment_name} vs ${equipmentName}
        //         ref: ${equipment.equipment_reference}  vs ${equipmentRef}
        //         manufacturer: ${equipment.equipment_manufacturer} vs ${equipmentManufacturer}
        //         installed_at: ${prevEquipmentDateInt} vs ${currEquipmentDateInt}
        //         ffh: ${equipment.equipment_ffh_max} vs ${maxFreeFallHeight}
        //         floor_ref: ${equipment.floor_reference} vs ${floorRef}
        //         floor_manufacturer: ${equipment.floor_manufacturer} vs ${floorManufacturer}
        //         floor_installed_at: ${prevFloorDateInt} vs ${currFloorDateInt}
        //         floor_kind: ${equipment.floor_kind} vs ${floorKind}
        //         floor_substrat: ${equipment.floor_substrat} vs ${floorSubstrat}
        //         floor_condition: ${equipment.floor_condition} vs ${floorCondition}
        //         picture: ${prevPicturesStr} vs ${currPicturesStr}
        //         sports_mat_type: ${equipment.sports_mat_type} vs ${sportsMatType}
        //         sports_mat_length: ${equipment.sports_mat_length} vs ${sportsMatDimensionLength}
        //         sports_mat_width: ${equipment.sports_mat_width} vs ${sportsMatDimensionWidth}
        //         sports_mat_thickness_side_one: ${equipment.sports_mat_thickness_side_one} vs ${sportsMatDimensionThicknessSide1}
        //         sports_mat_thickness_side_two: ${equipment.sports_mat_thickness_side_two} vs ${sportsMatDimensionThicknessSide2}
        //         sports_mat_thickness_side_three: ${equipment.sports_mat_thickness_side_three} vs ${sportsMatDimensionThicknessSide3}
        //         sports_mat_thickness_side_four: ${equipment.sports_mat_thickness_side_four} vs ${sportsMatDimensionThicknessSide4}
        //         sports_mat_metal_frame_is_present: ${equipment.sports_mat_metal_frame_is_present} vs ${sportsMatMetalFrameIsInPlace}
        //         trampoline_impactor_weight: ${equipment.trampoline_impactor_weight} vs ${trampolineImpactorWeightStr}
        //         `)
        // }
        return isMutated
    }, [
        equipment,
        memoEquipmentUUID,
        equipmentName,
        equipmentRef,
        equipmentManufacturer,
        equipmentInstallationDate,
        maxFreeFallHeight,
        floorRef,
        floorManufacturer,
        floorDate,
        floorKind,
        floorSubstrat,
        floorCondition,
        pictures,
        sportsMatType,
        sportsMatDimensionLength,
        sportsMatDimensionWidth,
        sportsMatDimensionThicknessSide1,
        sportsMatDimensionThicknessSide2,
        sportsMatDimensionThicknessSide3,
        sportsMatDimensionThicknessSide4,
        sportsMatMetalFrameIsInPlace,
        trampolineImpactorWeightStr,
    ])

    // UPDATE EQUIPMENT REQUEST
    const onUpdate = useDebounce(async () => {
        if (equipment === null) {
            return
        }
        let equipmentInstallationDateInt: number | null = null
        if (equipmentInstallationDate !== null) {
            equipmentInstallationDateInt = equipmentInstallationDate
                .toDate()
                .getTime()
        }
        let floorInstalledAt: number | null = null
        if (floorDate !== null) {
            floorInstalledAt = floorDate.toDate().getTime()
        }
        let updatedEquipment = equipment.clone()
        if (equipmentName !== null) {
            updatedEquipment.equipment_name = equipmentName
        }
        if (equipmentRef !== null) {
            updatedEquipment.equipment_reference = equipmentRef
        }
        if (equipmentManufacturer !== null) {
            updatedEquipment.equipment_manufacturer = equipmentManufacturer
        }
        if (equipmentInstallationDateInt !== null) {
            updatedEquipment.equipment_installation_date =
                equipmentInstallationDateInt
        }
        if (maxFreeFallHeight !== null) {
            updatedEquipment.equipment_ffh_max = parseFloat(maxFreeFallHeight)
        }
        if (floorRef !== null) {
            updatedEquipment.floor_reference = floorRef
        }
        if (floorManufacturer !== null) {
            updatedEquipment.floor_manufacturer = floorManufacturer
        }
        if (floorInstalledAt !== null) {
            updatedEquipment.floor_installation_date = floorInstalledAt
        }
        if (floorKind !== null) {
            updatedEquipment.floor_kind = floorKind
        }
        if (floorSubstrat !== null) {
            updatedEquipment.floor_substrat = floorSubstrat
        }
        if (floorCondition !== null) {
            updatedEquipment.floor_condition = floorCondition
        }
        updatedEquipment.pictures = pictures
        updatedEquipment.sports_mat_type =
            sportsMatType ?? MatTypeEN12503.UNKNOWN_MAT_TYPE_EN_12503
        if (sportsMatDimensionLength !== null) {
            updatedEquipment.sports_mat_length = sportsMatDimensionLength
        }
        if (sportsMatDimensionWidth !== null) {
            updatedEquipment.sports_mat_width = sportsMatDimensionWidth
        }
        if (sportsMatDimensionThicknessSide1 !== null) {
            updatedEquipment.sports_mat_thickness_side_one =
                sportsMatDimensionThicknessSide1
        }
        if (sportsMatDimensionThicknessSide2 !== null) {
            updatedEquipment.sports_mat_thickness_side_two =
                sportsMatDimensionThicknessSide2
        }
        if (sportsMatDimensionThicknessSide3 !== null) {
            updatedEquipment.sports_mat_thickness_side_three =
                sportsMatDimensionThicknessSide3
        }
        if (sportsMatDimensionThicknessSide4 !== null) {
            updatedEquipment.sports_mat_thickness_side_four =
                sportsMatDimensionThicknessSide4
        }
        if (sportsMatMetalFrameIsInPlace !== null) {
            updatedEquipment.sports_mat_metal_frame_is_present =
                sportsMatMetalFrameIsInPlace
        }
        if (trampolineImpactorWeightStr !== null) {
            updatedEquipment.trampoline_impactor_weight = parseFloat(
                trampolineImpactorWeightStr,
            )
        }
        updatedEquipment.updated_at = Date.now()
        updateEquipment(updatedEquipment)
        externallySetEquipment(updatedEquipment)
    })
    // UPDATE EQUIPMENT RESPONSE
    useEffect(() => {
        if (memoEquipmentUUID === null || updatedEquipmentAck === null) {
            return
        }
        // UPD 2024-09-06: do not re-fetch equipment to save bandwidth
        // and to avoid input "glitch" when the user is typing
        //
        // use the externallySetEquipment() to update the local state
        //
        // // getEquipment(uuidToPbUUID(memoEquipmentUUID))
    }, [updatedEquipmentAck, memoEquipmentUUID])

    useEffect(() => {
        console.log(
            `EquipmentPage: memoEquipmentIsMutated:`,
            memoEquipmentIsMutated,
        )
        if (memoEquipmentIsMutated === false) {
            return
        }
        onUpdate()
    }, [memoEquipmentIsMutated])

    const memoSportsMatInformationElement = useMemo(() => {
        if (sportsMatType === null) {
            return null
        }
        return (
            <Row gutter={[10, 10]} justify="space-between">
                <Col xs={18}>
                    <FlexCol>
                        <Alert
                            message={
                                <>
                                    Mat of <b>{memoSportsMatTypeName}</b> is
                                    required to be tested on{" "}
                                    <b>{memoSportsMatTargetNbZones}</b> zones:
                                    (see layout)
                                </>
                            }
                            showIcon
                            type="info"
                        />
                        <Alert
                            message={
                                <>
                                    Mat <b>{memoSportsMatTypeName}</b> is
                                    required to be tested with an impactor of{" "}
                                    {memoSportsMatTargetImpactorDiameter !==
                                    null ? (
                                        <>
                                            diameter&nbsp;
                                            <b>
                                                {memoSportsMatTargetImpactorDiameter.mean.toFixed(
                                                    0,
                                                )}
                                                &nbsp;± &nbsp;
                                                {memoSportsMatTargetImpactorDiameter.std.toFixed(
                                                    0,
                                                )}
                                            </b>
                                            &nbsp; mm
                                        </>
                                    ) : (
                                        <>unknown diameter</>
                                    )}
                                    {memoSportsMatTargetImpactorMass !==
                                    null ? (
                                        <>
                                            {" "}
                                            and mass of&nbsp;
                                            <b>
                                                {memoSportsMatTargetImpactorMass?.mean.toFixed(
                                                    0,
                                                )}
                                                &nbsp;±&nbsp;
                                                {memoSportsMatTargetImpactorMass?.std.toFixed(
                                                    1,
                                                )}
                                            </b>
                                            &nbsp; kg
                                        </>
                                    ) : (
                                        <>and unknown mass</>
                                    )}
                                </>
                            }
                            showIcon
                            type="info"
                        />
                    </FlexCol>
                </Col>
                <Col xs={6}>
                    <FlexRow style={{ justifyContent: "center" }}>
                        {memoSportsMatLayoutElement}
                    </FlexRow>
                </Col>
            </Row>
        )
    }, [sportsMatType, memoSportsMatTypeName, memoSportsMatLayoutElement])

    const memoDimensionsDataElement = useMemo(() => {
        if (normType === NormType.EN_1177) {
            return null
        }

        return (
            <div>
                <UnderlinedSectionTitle>
                    <Translated keyEn="Dimensions" />
                </UnderlinedSectionTitle>
                <Row
                    gutter={[10, 10]}
                    style={{
                        marginTop: 20,
                    }}
                >
                    {/* 2 dimensions */}
                    <Col xs={24} md={12}>
                        <LabeledInputInCM
                            label="Length"
                            value={sportsMatDimensionLength}
                            setValue={setSportsMatDimensionLength}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LabeledInputInCM
                            label="Width"
                            value={sportsMatDimensionWidth}
                            setValue={setSportsMatDimensionWidth}
                        />
                    </Col>
                    {/* 4 thicknesses: sports mats and airbags */}
                    {(normType === NormType.EN_12503 ||
                        normType === NormType.EN_14960) && (
                        <>
                            <Col xs={12} md={4}>
                                <LabeledInputInMM
                                    label="Thickness 1"
                                    value={sportsMatDimensionThicknessSide1}
                                    setValue={
                                        setSportsMatDimensionThicknessSide1
                                    }
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <LabeledInputInMM
                                    label="Thickness 2"
                                    value={sportsMatDimensionThicknessSide2}
                                    setValue={
                                        setSportsMatDimensionThicknessSide2
                                    }
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <LabeledInputInMM
                                    label="Thickness 3"
                                    value={sportsMatDimensionThicknessSide3}
                                    setValue={
                                        setSportsMatDimensionThicknessSide3
                                    }
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <LabeledInputInMM
                                    label="Thickness 4"
                                    value={sportsMatDimensionThicknessSide4}
                                    setValue={
                                        setSportsMatDimensionThicknessSide4
                                    }
                                />
                            </Col>
                            {/* Mean thickness */}
                            <Col xs={12} md={8}>
                                <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated keyEn="Mean thickness" />
                                    </Label>
                                    <Input
                                        size="large"
                                        value={
                                            memoSportsMatThicknessMeanAndStd
                                                ? `${memoSportsMatThicknessMeanAndStd.mean.toFixed(0)} ± ${memoSportsMatThicknessMeanAndStd.std.toFixed(0)}`
                                                : ""
                                        }
                                        disabled
                                        addonAfter="mm"
                                    />
                                </FlexCol>
                            </Col>
                        </>
                    )}
                    {/* Metal frame switch for EN12503/EN12572 */}
                    {normType === NormType.EN_12503 && (
                        <Col>
                            <Label>
                                <Translated keyEn="Metal frame in place" />?
                            </Label>
                            <Switch
                                checked={sportsMatMetalFrameIsInPlace === true}
                                onChange={(value) => {
                                    setSportsMatMetalFrameIsInPlace(value)
                                }}
                            />
                        </Col>
                    )}
                    {/* Trampoline impactor weight */}
                    {normType === NormType.EN_ISO_23659 && (
                        <Col>
                            <LabeledInput
                                translatableLabel={"Impactor weight"}
                                addonAfter="kg"
                                value={trampolineImpactorWeightStr ?? ""}
                                setValue={(value) => {
                                    if (value === null || value === "") {
                                        setTrampolineImpactorWeightStr(null)
                                        return
                                    }
                                    setTrampolineImpactorWeightStr(value)
                                }}
                            />
                        </Col>
                    )}
                </Row>
            </div>
        )
    }, [
        normType,
        sportsMatDimensionLength,
        sportsMatDimensionWidth,
        sportsMatMetalFrameIsInPlace,
        sportsMatDimensionThicknessSide1,
        sportsMatDimensionThicknessSide2,
        sportsMatDimensionThicknessSide3,
        sportsMatDimensionThicknessSide4,
        memoSportsMatThicknessMeanAndStd,
        trampolineImpactorWeightStr,
    ])

    if (equipment === null) {
        return (
            <FlexCol
                style={{
                    width: "100%",
                    height: "60vh",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <>
            <FlexCol
                style={{
                    width: "100%",
                    maxWidth: MAX_WIDTH_CENTRAL_CONTAINER,
                    margin: "auto",
                    gap: 30,
                    marginBottom: "2rem",
                }}
            >
                <ErrorAlert error={error} />
                <SimplifiedBreadcrumb
                    previousItems={[
                        {
                            href: `/sites/${siteUUIDStr}`,
                            label: siteName,
                        },
                    ]}
                    currentItem={{ label: equipmentName }}
                />
                <Row
                    justify="space-between"
                    style={{
                        marginTop: "1rem",
                    }}
                >
                    <Col>
                        <FlexCol style={{ gap: 0 }}>
                            <Label>
                                <Translated keyEn="Equipment" />
                            </Label>
                            <Typography.Text
                                style={{
                                    fontSize: "2rem",
                                }}
                            >
                                {equipmentName}
                            </Typography.Text>
                        </FlexCol>
                    </Col>
                    <Col>
                        <FlexCol style={{ gap: 3 }}>
                            {memoNormTypeElement}
                            {memoMethodElement}
                            {memoIsLabTestElement}
                        </FlexCol>
                    </Col>
                </Row>
                {/* Equipment name */}
                <Row gutter={[10, 10]}>
                    <Col flex="auto">
                        <LabeledInput
                            inputStyle={{ width: "100%" }}
                            translatableLabel={"Equipment"}
                            value={equipmentName}
                            setValue={setEquipmentName}
                        />
                    </Col>
                    {normType === NormType.EN_12503 && (
                        <Col flex={"auto"}>
                            <Label>
                                <Translated keyEn="Type" />
                            </Label>
                            <Select
                                size="large"
                                value={sportsMatType}
                                onChange={(value) => {
                                    setSportsMatType(value)
                                }}
                                options={[
                                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                ].map((t) => ({
                                    label: `Type "${t}": ${sportsMatTypeDescriptionStr(
                                        t,
                                    )}`,
                                    value: t,
                                }))}
                                style={{
                                    width: "100%",
                                }}
                            />
                        </Col>
                    )}
                    <Col xs={24}>
                        {normType === NormType.EN_12503 && (
                            <RequirementsAlertSM smType={sportsMatType} />
                        )}
                    </Col>
                    <Col xs={24}>
                        {normType === NormType.EN_12503 &&
                            memoSportsMatInformationElement}
                    </Col>
                </Row>
                {/* New Zone */}
                <FlexCol>
                    <Label>
                        <Translated keyEn="New zone" />
                    </Label>
                    <Space.Compact
                        style={{
                            width: "100%",
                        }}
                    >
                        <Input
                            size="large"
                            value={newZoneName ?? ""}
                            onChange={(e) => {
                                setNewZoneName(e.target.value)
                            }}
                            placeholder={"New zone"}
                        />
                        <NewZoneButton
                            equipmentUUID={equipment.uuid}
                            zoneName={newZoneName}
                            zoneFFH={
                                memoSportsMatTargetFFH
                                    ? memoSportsMatTargetFFH.mean
                                    : null
                            }
                        />
                    </Space.Compact>
                    {normType === NormType.EN_12503 &&
                        memoSportsMatTargetFFH !== null && (
                            <div>
                                <InfoCircleTwoTone />
                                <i>
                                    <Translated keyEn="Newly created zone will have a predefined target FFH of" />{" "}
                                    <b>
                                        {memoSportsMatTargetFFH.mean.toFixed(0)}
                                        &nbsp;±&nbsp;
                                        {memoSportsMatTargetFFH.std.toFixed(1)}
                                    </b>
                                    &nbsp;cm (<b>{memoSportsMatTypeName}</b>)
                                </i>{" "}
                            </div>
                        )}
                </FlexCol>
                {/* Equipment zones */}
                <FlexCol>
                    <Label>
                        <Translated keyEn="Tested zones" />
                    </Label>
                    {memoZonesTable}
                </FlexCol>
                {/* Equipment picture */}
                <Label>
                    <Translated keyEn="Picture" />
                </Label>
                <UploadImageListV2
                    pictures={pictures}
                    setPictures={setPictures}
                />
                {/* Dimensions Data */}
                {memoDimensionsDataElement}
                {/* Equipment data */}
                <div>
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Equipment" />
                    </UnderlinedSectionTitle>
                    <Row
                        gutter={[10, 10]}
                        style={{
                            marginTop: 20,
                        }}
                    >
                        <Col xs={24} md={12}>
                            <LabeledInput
                                translatableLabel="Reference"
                                value={equipmentRef}
                                setValue={setEquipmentRef}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <LabeledInput
                                translatableLabel="Manufacturer"
                                value={equipmentManufacturer}
                                setValue={setEquipmentManufacturer}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn={"Installation date"} />
                                </Label>
                                <DatePicker
                                    size="large"
                                    value={equipmentInstallationDate}
                                    onChange={(value) => {
                                        setEquipmentDate(value)
                                    }}
                                    variant="filled"
                                />
                            </FlexCol>
                        </Col>
                        <Col xs={24} md={12}>
                            <FlexCol style={{ gap: 0 }}>
                                <Label>
                                    <Translated keyEn={"Free Fall Height"} />
                                </Label>
                                <Input
                                    size="large"
                                    value={maxFreeFallHeight ?? ""}
                                    onChange={(value) => {
                                        setMaxFreeFallHeight(value.target.value)
                                    }}
                                    variant="filled"
                                    addonAfter="m"
                                />
                            </FlexCol>
                        </Col>
                    </Row>
                </div>
                {/* Floor data */}
                {normType === NormType.EN_1177 && (
                    <div>
                        <UnderlinedSectionTitle>
                            <Translated keyEn="Floor" />
                        </UnderlinedSectionTitle>
                        <Row
                            gutter={[10, 10]}
                            style={{
                                marginTop: 10,
                            }}
                        >
                            <Col xs={24} md={12}>
                                <LabeledInput
                                    translatableLabel="Reference"
                                    value={floorRef}
                                    setValue={setFloorRef}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <LabeledInput
                                    translatableLabel="Manufacturer"
                                    value={floorManufacturer}
                                    setValue={setFloorManufacturer}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <LabeledInput
                                    translatableLabel="Kind"
                                    value={floorKind}
                                    setValue={setFloorKind}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <LabeledInput
                                    translatableLabel="Substrat"
                                    value={floorSubstrat}
                                    setValue={setFloorSubstrat}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <FlexCol style={{ gap: 0 }}>
                                    <Label>
                                        <Translated
                                            keyEn={"Installation date"}
                                        />
                                    </Label>
                                    <DatePicker
                                        size="large"
                                        value={floorDate}
                                        onChange={(value) => {
                                            setFloorDate(value)
                                        }}
                                        variant="filled"
                                    />
                                </FlexCol>
                            </Col>
                            <Col xs={24} md={12}>
                                <LabeledInput
                                    translatableLabel="Condition"
                                    value={floorCondition}
                                    setValue={setFloorCondition}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
                {/* Specimen data */}
                {/* <div></div> */}
                {/* Delete & Save */}
                <FlexRow
                    style={{
                        alignItems: "center",
                        alignSelf: "flex-end",
                    }}
                >
                    <Popconfirm
                        title={
                            <>
                                <Translated keyEn="Are you sure you want to delete this site" />
                                ?
                            </>
                        }
                        onConfirm={onDelete}
                    >
                        <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            size="small"
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                    fontSize: "0.8rem",
                                }}
                            >
                                <Translated keyEn="Delete" />
                            </span>
                        </Button>
                    </Popconfirm>
                    <div
                        style={{
                            width: 200,
                        }}
                    >
                        <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            size="large"
                            block
                            disabled={!memoEquipmentIsMutated}
                            onClick={onUpdate}
                        >
                            <span
                                style={{
                                    textTransform: "uppercase",
                                }}
                            >
                                <Translated keyEn="Save" />
                            </span>
                        </Button>
                    </div>
                </FlexRow>
                <EntityTimestamps entity={equipment} />
            </FlexCol>
            <DataTreeDrawer
                site={site}
                selectedKey={
                    memoEquipmentUUID ? pbUUIDToUuid(memoEquipmentUUID) : null
                }
            />
        </>
    )
}
