import {
    FC,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
} from "react"
import {
    UCError,
    UCPayload,
    UCPayloadType,
} from "../../generated/proto-ts/main"
import { Mutex, withTimeout, MutexInterface } from "async-mutex"
import {
    ICloudUsercommContextProviderContextType,
    cloudUsercommContext,
    cloudUsercommStateReducer,
} from "./cloudUsercommCommon"

const DEFAULT_WS_RECONNECT_DELAY_MS = 3000

export const useCloudUsercommContextWS =
    (): ICloudUsercommContextProviderContextType => {
        const context = useContext(cloudUsercommContext)
        if (context === undefined) {
            throw new Error(
                "useCloudUsercommContextWS must be used within a CloudUsercommProviderWS",
            )
        }
        return context
    }

export const CloudUsercommProviderWS: FC<{
    children: ReactNode
}> = ({ children }) => {
    const [state, dispatch] = useReducer(cloudUsercommStateReducer, {
        socket: undefined,
        socketIsConnected: false,

        // Storage message queue
        recvMessageQueue: [],
        emitMessageQueue: [],
    })

    const emitMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 1000))

    useEffect(() => {
        if (state.emitMessageQueue.length === 0) {
            // console.log("CloudWSv2_PB: emit loop: no commands to send")
            return
        }
        if (state.socket === undefined) {
            console.log(
                "CloudWSv2_PB: emit loop: socket is null. will not send commands",
            )
            return
        }
        if (state.socket.readyState !== WebSocket.OPEN) {
            console.log(
                `CloudWSv2_PB: emit loop: socket is not in OPEN state (${state.socket.readyState}). will not send commands`,
            )
            return
        }
        // let t = Date.now()
        // console.log("CloudWSv2_PB: emit loop: acquiring mutex..")
        emitMutexRef.current
            .acquire()
            .then((release) => {
                // console.log(
                //     "CloudWSv2_PB: emit loop: mutex acquired after ",
                //     Date.now() - t,
                //     "ms",
                // )
                if (state.socket === undefined) {
                    console.log(
                        "CloudWSv2_PB: emit loop: socket is null. will not send commands",
                    )
                    return
                }
                for (let msg of state.emitMessageQueue) {
                    // console.log(
                    //     "CloudWSv2_PB: emit loop: sending command",
                    //     msg.type,
                    // )
                    try {
                        let frame = msg.serializeBinary()
                        state.socket!.send(frame)
                        _consumeEmitMessage(msg.uuid)
                        release()
                    } catch (e: any) {
                        console.log(
                            "CloudWSv2_PB: emit loop: error sending message",
                            e.message,
                        )
                    }
                }
            })
            .catch((e: any) => {
                // console.warn(
                //     "CloudWSv2_PB: emit loop: error acquiring mutex",
                //     e.message,
                // )
            })
        return () => {
            emitMutexRef.current.cancel()
        }
    }, [state.socket, state.emitMessageQueue])

    const socketConnect = useCallback(() => {
        console.debug(`CloudWSv2_PB: about to connect to websocket`)
        const _socket = new WebSocket(
            `wss://${window.location.hostname}/api/ws`,
        )
        _socket.binaryType = "arraybuffer"
        setSocket(_socket)
    }, [])

    useEffect(() => {
        socketConnect()
    }, [])

    useEffect(() => {
        if (state.socket === undefined) {
            return
        }
        state.socket.onopen = (e) => {
            console.debug(`CloudWSv2_PB: opened websocket`)
            // emit PONG message to fire emitQueue useEffect loop
            addEmitMessage(
                new UCPayload({
                    type: UCPayloadType.CTRL_PONG,
                }),
            )
            setSocketIsConnected(true)
        }
        state.socket.onclose = (e) => {
            console.debug(`CloudWSv2_PB: disconnected from websocket`)
            setSocketIsConnected(false)
            setTimeout(() => {
                socketConnect()
            }, DEFAULT_WS_RECONNECT_DELAY_MS)
        }
        state.socket.onerror = (e) => {
            console.warn(`CloudWSv2_PB: error event from websocket`, e)
        }
        state.socket.onmessage = (e) => {
            if (e.data instanceof ArrayBuffer === false) {
                console.log(
                    `CloudWSv2_PB: received non-arraybuffer message`,
                    e.data,
                )
                return
            }
            let ucPayload: UCPayload | null = null
            try {
                ucPayload = UCPayload.deserializeBinary(new Uint8Array(e.data))
            } catch (e) {
                console.error(
                    `CloudWSv2_PB: failed to deserialize UCPayload from websocket`,
                    e,
                )
                return
            }

            switch (ucPayload.type) {
                case UCPayloadType.CTRL_PING:
                    console.debug(
                        `CloudWSv2_PB: received PING, will respond with PONG..`,
                    )
                    addEmitMessage(
                        new UCPayload({
                            type: UCPayloadType.CTRL_PONG,
                        }),
                    )
                    return
            }
            if (ucPayload.type === UCPayloadType.SICO_ERROR) {
                let pbError = UCError.deserializeBinary(ucPayload.data)
                console.error(
                    `CloudWSv2_PB: received SICO_ERROR`,
                    pbError.value,
                )
                // return
            }

            if (ucPayload.request_type < 64) {
                console.warn(
                    `CloudWSv2_PB: non-storage message`,
                    ucPayload.toObject(),
                )
                return
            }

            // Storage messages
            _addRecvMessage(ucPayload)
        }
    }, [state.socket])

    const _addRecvMessage = useCallback((payload: UCPayload) => {
        dispatch({ type: "ADD_MESSAGE_TO_RECV_QUEUE", payload })
    }, [])

    const consumeRecvMessage = useCallback((uuid: UCPayload["uuid"]) => {
        dispatch({ type: "CONSUME_MESSAGE_FROM_RECV_QUEUE", payload: uuid })
    }, [])

    const addEmitMessage = useCallback((payload: UCPayload) => {
        dispatch({ type: "ADD_MESSAGE_TO_EMIT_QUEUE", payload })
    }, [])

    const _consumeEmitMessage = useCallback((uuid: UCPayload["uuid"]) => {
        dispatch({ type: "CONSUME_MESSAGE_FROM_EMIT_QUEUE", payload: uuid })
    }, [])

    const setSocket = useCallback(
        (socket: WebSocket) => {
            dispatch({ type: "SET_SOCKET", payload: socket })
        },
        [state.socket, dispatch],
    )

    const setSocketIsConnected = useCallback(
        (isConnected: boolean) => {
            dispatch({ type: "SET_SOCKET_IS_CONNECTED", payload: isConnected })
        },
        [state.socketIsConnected, dispatch],
    )

    return (
        <cloudUsercommContext.Provider
            value={{
                ...state,
                setSocketIsConnected,
                consumeRecvMessage,
                addEmitMessage,
            }}
        >
            {children}
        </cloudUsercommContext.Provider>
    )
}
