import { FC, useEffect, useMemo, useState } from "react"
import { ReactComponent as HicIcon } from "../../svg/hic-icon.svg"
import { FlexRow, FlexCol } from "./common"
import { useUsercommContextBLE } from "../../usercomm/local/ble/usercommProviderBLE"
import { SwapOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faBatteryEmpty,
    faBatteryFull,
    faBatteryHalf,
    faBatteryQuarter,
    faBatteryThreeQuarters,
} from "@fortawesome/free-solid-svg-icons"
import {
    COLOR_BG_GRAY,
    COLOR_BG_GREEN,
    COLOR_BG_ORANGE,
} from "../../utils/utils"

const BatteryLevelWidget: FC<{
    batteryLevel: number
}> = ({ batteryLevel }) => {
    const [battLvlIcon, setBattLvlIcon] = useState(faBatteryEmpty)
    const [battLvlColor, setBattLvlColor] = useState("red")
    useEffect(() => {
        if (batteryLevel < 15) {
            setBattLvlIcon(faBatteryEmpty)
            setBattLvlColor("red")
        } else if (batteryLevel < 33) {
            setBattLvlIcon(faBatteryQuarter)
            setBattLvlColor(COLOR_BG_ORANGE)
        } else if (batteryLevel < 66) {
            setBattLvlIcon(faBatteryHalf)
            setBattLvlColor(COLOR_BG_ORANGE)
        } else if (batteryLevel < 95) {
            setBattLvlIcon(faBatteryThreeQuarters)
            setBattLvlColor(COLOR_BG_GREEN)
        } else {
            setBattLvlIcon(faBatteryFull)
            setBattLvlColor(COLOR_BG_GREEN)
        }
    }, [batteryLevel])

    return (
        <FlexCol>
            <Tooltip overlay={<>{batteryLevel.toFixed(0)}%</>}>
                <FontAwesomeIcon icon={battLvlIcon} color={battLvlColor} />
            </Tooltip>
        </FlexCol>
    )
}

export const DevicesConnectionWidgetBLE: FC = () => {
    const {
        hicState,
        hicConfig,
        stationSensors,
        stationConfig,
        emitGetHICConfig,
    } = useUsercommContextBLE()

    const memoHicIsConnected = useMemo(() => {
        if (hicState === null) {
            return false
        }
        return hicState.is_connected
    }, [hicState?.is_connected])

    const memoHicSerialNumber = useMemo(() => {
        if (!memoHicIsConnected || !hicConfig) {
            return "N/A"
        }
        console.log(
            `DeviceConnectionWidgetBLE: hicConfig:`,
            hicConfig.toObject(),
        )
        return hicConfig.serial_number
    }, [memoHicIsConnected, hicConfig])

    const memoHicBatteryLevel = useMemo((): number => {
        if (hicState == null || !hicState.is_connected || !memoHicIsConnected) {
            return -1
        }
        return hicState.battery_level_perc
    }, [hicState, memoHicIsConnected])

    const memoStationIsConnected = useMemo(() => {
        if (stationSensors === null) {
            return false
        }
        return true
    }, [stationSensors])

    const memoStationSerialNumber = useMemo(() => {
        if (!stationConfig) {
            return "N/A"
        }
        console.log(
            `DeviceConnectionWidgetBLE: stationConfig:`,
            stationConfig.toObject(),
        )
        return stationConfig.serial_number
    }, [stationConfig])

    const memoStationBatteryLevel = useMemo((): number => {
        if (!memoStationIsConnected || !stationSensors) {
            return -1
        }
        return stationSensors.battery_level
    }, [memoStationIsConnected, stationSensors])

    useEffect(() => {
        if (memoHicIsConnected) {
            emitGetHICConfig()
        }
    }, [memoHicIsConnected])

    return (
        <FlexRow
            style={{
                alignItems: "end",
            }}
        >
            <FlexRow
                style={{
                    gap: 0,
                }}
            >
                <FlexCol
                    style={{
                        alignItems: "center",
                        padding: 5,
                        gap: 0,
                    }}
                >
                    <HicIcon
                        style={{
                            fill: memoHicIsConnected
                                ? COLOR_BG_GREEN
                                : COLOR_BG_GRAY,
                        }}
                        width={40}
                        height={40}
                    />
                    <span
                        style={{
                            fontSize: "0.8rem",
                            fontStyle: "italic",
                        }}
                    >
                        {memoHicIsConnected ? memoHicSerialNumber : "N/C"}
                    </span>
                </FlexCol>
                {memoHicBatteryLevel > 0 && (
                    <div style={{ marginLeft: -10 }}>
                        <BatteryLevelWidget
                            batteryLevel={memoHicBatteryLevel}
                        />
                    </div>
                )}
            </FlexRow>
            <FlexRow style={{ gap: 0 }}>
                <FlexCol
                    style={{
                        alignItems: "center",
                        padding: 5,
                        gap: 0,
                    }}
                >
                    <Link to="/connect">
                        <SwapOutlined
                            style={{
                                color: memoStationIsConnected
                                    ? COLOR_BG_GREEN
                                    : COLOR_BG_GRAY,
                                fontSize: "37px",
                            }}
                        />
                    </Link>
                    <span
                        style={{
                            fontSize: "0.8rem",
                            fontStyle: "italic",
                        }}
                    >
                        {memoStationIsConnected
                            ? memoStationSerialNumber
                            : "N/C"}
                    </span>
                </FlexCol>
                {memoStationBatteryLevel > 0 && (
                    <div style={{ marginTop: -2, marginLeft: -20 }}>
                        <BatteryLevelWidget
                            batteryLevel={memoStationBatteryLevel}
                        />
                    </div>
                )}
            </FlexRow>
        </FlexRow>
    )
}
