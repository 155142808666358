import { DoubleRightOutlined } from "@ant-design/icons"
import { Tooltip, Tag, Button, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useState, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import { EResultTagAdequacy } from "../../../components/commons-ts/common"
import { UUID, MatTypeEN12503, Zone } from "../../../generated/proto-ts/main"
import { MeanWithStd } from "../../../types"
import { useUsercommEquipmentChildrenRecursiveBLE } from "../../../usercomm/local/ble/usercommAsyncRequestBLE"
import { Translated } from "../../../utils/translated"
import { pbUUIDToUuid, COLOR_BG_ROW_DELETED } from "../../../utils/utils"
import { renderMeanStd } from "../../../utils/utils_tsx"
import { getZoneResultSM } from "../../../calculus/calculus_SM"
import { IZoneResult } from "../../../calculus/types"
import { TableImageThumbnail } from "../../../components/commons-ts/tableImageThumbnail"
import { useUsercommEquipmentChildrenRecursiveBimodal } from "../../../usercomm/common/usercommAsyncRequestBimodal"

export const EquipmentZonesTableSM: FC<{
    equipmentUUID: UUID | null
    zones: Zone[] | null
    smType: MatTypeEN12503 | null
    smThickness: MeanWithStd | null
}> = ({ equipmentUUID, zones, smType, smThickness }) => {
    const [zoneResultsMap, setZoneResultsMap] = useState<Record<
        string,
        IZoneResult
    > | null>(null)
    const [zoneImpactsMap, getEntitiesRecursiveRequest] =
        useUsercommEquipmentChildrenRecursiveBLE()

    useEffect(() => {
        getEntitiesRecursiveRequest(equipmentUUID)
    }, [equipmentUUID])

    useEffect(() => {
        if (zoneImpactsMap === null) {
            return
        }
        let _zoneResultsMap: Record<string, IZoneResult> = {}
        for (let zoneUUIDStr of Object.keys(zoneImpactsMap)) {
            let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
            if (zoneImpacts === undefined) {
                continue
            }
            let result = getZoneResultSM(
                zoneImpacts,
                smType,
                smThickness ? smThickness.mean : null,
            )
            if (result === null) {
                continue
            }
            _zoneResultsMap[zoneUUIDStr] = result
        }
        setZoneResultsMap(_zoneResultsMap)
    }, [zoneImpactsMap, smType, smThickness])

    const columns = useMemo((): ColumnsType<Zone> => {
        return [
            {
                title: "📷", // Photo
                render: (_, zone) => {
                    let pictureUUID = zone.pictures[0]
                    if (pictureUUID === undefined) {
                        return <div style={{ width: "3rem" }} />
                    }
                    return (
                        <Link to={`/zones/${pbUUIDToUuid(zone.uuid)}`}>
                            <TableImageThumbnail
                                pictureUUID={pictureUUID}
                                alt={zone.zone_name}
                            />
                        </Link>
                    )
                },
            },
            {
                key: "zone",
                title: "Zone",
                ellipsis: true,
                render: (_, zone) => {
                    return (
                        <Link
                            to={`/zones/${pbUUIDToUuid(zone.uuid)}`}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            {zone.zone_name}
                        </Link>
                    )
                },
            },
            {
                title: (
                    <Tooltip overlay={<Translated keyEn="# Impacts" />}>
                        # Imp
                    </Tooltip>
                ),
                render: (_, zone) => {
                    if (zoneImpactsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <span>0</span>
                    }
                    let nbImpacts = 0
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at === 0) {
                            nbImpacts++
                        }
                    }
                    return <span>{nbImpacts}</span>
                },
            },
            // {
            //     key: "ffh",
            //     title: (
            //         <Tooltip
            //             overlay={
            //                 <Translated keyEn="Target Free Fall Height, m" />
            //             }
            //         >
            //             <div>
            //                 <Translated keyEn="Target FFH, m" />
            //             </div>
            //         </Tooltip>
            //     ),
            //     render: (_, zone) => {
            //         let valueMeters = zone.zone_ffh_max / 100
            //         if (valueMeters === 0) {
            //             return "N/A"
            //         }
            //         return <span>{valueMeters.toFixed(2)}</span>
            //     },
            // },
            {
                key: "measuredFFH",
                title: (
                    <div>
                        <Tooltip
                            overlay={
                                <Translated keyEn="Max Measured Free Fall Height, m" />
                            }
                        >
                            <div>
                                <Translated keyEn="Height, m" />
                            </div>
                        </Tooltip>
                    </div>
                ),
                render: (_, zone) => {
                    if (zoneImpactsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    let heightValues: number[] = []
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at !== 0) {
                            continue
                        }
                        if (impact.impact_ffh === 0) {
                            continue
                        }
                        heightValues.push(impact.impact_ffh)
                    }
                    if (heightValues.length === 0) {
                        return <Tag>N/A</Tag>
                    }
                    let max = Math.max(...heightValues)
                    return <span>{max.toFixed(2)}</span>
                },
            },
            {
                key: "gmax",
                title: <Translated keyEn="Gmax" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null || result.gmax === null) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.gmax, 3)
                },
            },
            {
                key: "deflectionDistanceMM",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Deflection distance, mm" />}
                    >
                        <div>
                            <Translated keyEn="Def. mm" />
                        </div>
                    </Tooltip>
                ),
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (
                        result === null ||
                        result.deflectionDistanceMM === null
                    ) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.deflectionDistanceMM, 3)
                },
            },
            {
                key: "deflectionDistancePerc",
                title: (
                    <Tooltip
                        overlay={
                            <Translated keyEn="Deflection percentage, %" />
                        }
                    >
                        <div>
                            <Translated keyEn="Def. %" />
                        </div>
                    </Tooltip>
                ),
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (
                        result === null ||
                        result.deflectionDistancePerc === null
                    ) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.deflectionDistancePerc, 3)
                },
            },
            {
                key: "resiliencePerc",
                title: (
                    <Tooltip
                        overlay={
                            <Translated keyEn="Resilience percentage, %" />
                        }
                    >
                        <div>
                            <Translated keyEn="Res. %" />
                        </div>
                    </Tooltip>
                ),
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null || result.resiliencePerc === null) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.resiliencePerc, 3)
                },
            },
            {
                key: "result",
                title: <Translated keyEn="Result" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null) {
                        return <Tag>N/A</Tag>
                    }
                    return <EResultTagAdequacy result={result.result} />
                },
            },
            {
                title: "",
                width: "2rem",
                render: (_, zone) => {
                    return (
                        <Link to={`/zones/${pbUUIDToUuid(zone.uuid)}`}>
                            <Button type="link" size="small">
                                <DoubleRightOutlined />
                            </Button>
                        </Link>
                    )
                },
            },
        ]
    }, [zoneImpactsMap, zoneResultsMap, smType, smThickness])

    return (
        <Table
            dataSource={zones ?? []}
            columns={columns}
            loading={zoneResultsMap === null}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            onRow={(zone) => {
                return {
                    style: {
                        backgroundColor:
                            zone.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                    },
                }
            }}
            footer={() => (
                <i>
                    <Translated keyEn="Table of zones for EN 12503 - SPORTS MATS" />
                </i>
            )}
        />
    )
}

export const EquipmentZonesTableSM_Print: FC<{
    equipmentUUID: UUID | null
    zones: Zone[] | null
    smType: MatTypeEN12503 | null
    smThickness: MeanWithStd | null
}> = ({ equipmentUUID, zones, smType, smThickness }) => {
    const [zoneResultsMap, setZoneResultsMap] = useState<Record<
        string,
        IZoneResult
    > | null>(null)
    const [zoneImpactsMap, getEntitiesRecursiveRequest] =
        useUsercommEquipmentChildrenRecursiveBimodal()

    useEffect(() => {
        getEntitiesRecursiveRequest(equipmentUUID)
    }, [equipmentUUID])

    useEffect(() => {
        if (zoneImpactsMap === null) {
            return
        }
        let _zoneResultsMap: Record<string, IZoneResult> = {}
        for (let zoneUUIDStr of Object.keys(zoneImpactsMap)) {
            let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
            if (zoneImpacts === undefined) {
                continue
            }
            let result = getZoneResultSM(
                zoneImpacts,
                smType,
                smThickness ? smThickness.mean : null,
            )
            if (result === null) {
                continue
            }
            _zoneResultsMap[zoneUUIDStr] = result
        }
        setZoneResultsMap(_zoneResultsMap)
    }, [zoneImpactsMap, smType, smThickness])

    const columns = useMemo((): ColumnsType<Zone> => {
        return [
            {
                key: "zone",
                title: "Zone",
                ellipsis: true,
                render: (_, zone) => {
                    return zone.zone_name
                },
                sorter: (a, b) => a.zone_name.localeCompare(b.zone_name),
                defaultSortOrder: "ascend",
            },
            {
                title: "# Impacts",
                render: (_, zone) => {
                    if (zoneImpactsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <span>0</span>
                    }
                    let nbImpacts = 0
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at === 0) {
                            nbImpacts++
                        }
                    }
                    return <span>{nbImpacts}</span>
                },
            },
            {
                key: "measuredFFH",
                title: <Translated keyEn="Height, m" />,
                render: (_, zone) => {
                    if (zoneImpactsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    let heightValues: number[] = []
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at !== 0) {
                            continue
                        }
                        if (impact.impact_ffh === 0) {
                            continue
                        }
                        heightValues.push(impact.impact_ffh)
                    }
                    if (heightValues.length === 0) {
                        return <Tag>N/A</Tag>
                    }
                    let max = Math.max(...heightValues)
                    return <span>{max.toFixed(2)}</span>
                },
            },
            {
                key: "gmax",
                title: <Translated keyEn="Gmax" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null || result.gmax === null) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.gmax, 3)
                },
            },
            {
                key: "deflectionDistanceMM",
                title: <Translated keyEn="Deflection, mm" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (
                        result === null ||
                        result.deflectionDistanceMM === null
                    ) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.deflectionDistanceMM, 3)
                },
            },
            {
                key: "deflectionDistancePerc",
                title: <Translated keyEn="Deflection, %" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (
                        result === null ||
                        result.deflectionDistancePerc === null
                    ) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.deflectionDistancePerc, 3)
                },
            },
            {
                key: "resiliencePerc",
                title: <Translated keyEn="Resilience, %" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null || result.resiliencePerc === null) {
                        return <Tag>N/A</Tag>
                    }
                    return renderMeanStd(result.resiliencePerc, 3)
                },
            },
            {
                key: "result",
                title: <Translated keyEn="Result" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null) {
                        return <Tag>N/A</Tag>
                    }
                    return <EResultTagAdequacy result={result.result} />
                },
            },
        ]
    }, [zoneImpactsMap, zoneResultsMap, smType, smThickness])

    return (
        <Table
            dataSource={zones ?? []}
            columns={columns}
            loading={zoneResultsMap === null}
            pagination={false}
            rowKey="uuid"
            size="small"
            bordered
        />
    )
}
