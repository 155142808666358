import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import "./index.css"
import "./styles/non-tailwind.css"
import "./styles/paper.css"
import "react-toastify/dist/ReactToastify.css"
import { App } from "./app"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import { ConfigProvider as AntdThemeConfigProvider, theme } from "antd"
import { UsercommProviderBLE } from "./usercomm/local/ble/usercommProviderBLE"
import {
    COLOR_BG_BLUE,
    COLOR_BG_DARK_BLUE,
    COLOR_BG_LIGHT_BLUE,
} from "./utils/utils"
import { ToastContainer } from "react-toastify"
import React from "react"
import { toastNotifyUpdateIsInstalled } from "./utils/toast"
import { TranslationProvider } from "./providers/translationProvider"
import { CloudUsercommProviderWS } from "./usercomm/cloud/cloudUsercommProviderWS"
import { SyncronizationProvider } from "./providers/syncronizationProvider"

// Flags emoji polyfill for Chrome/Windows
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill"
polyfillCountryFlagEmojis()

const SubUsercommProvider = React.memo(() => (
    <AntdThemeConfigProvider
        componentSize="large"
        theme={{
            algorithm: theme.defaultAlgorithm,
            token: {
                sizeStep: 6,
                sizeUnit: 2,
                fontSize: 16,
                colorBgBase: "#fff",
                colorBgContainer: "#fff",
                colorBgLayout: "#fff",
                colorPrimary: COLOR_BG_BLUE,
                colorLink: COLOR_BG_BLUE,
                fontFamily: "Verdana, sans-serif",
                borderRadius: 8,
            },
            components: {
                Layout: {
                    siderBg: COLOR_BG_BLUE,
                    triggerBg: COLOR_BG_LIGHT_BLUE,
                    headerBg: "#fff",
                    headerHeight: 74,
                },
                Menu: {
                    darkItemColor: "#fff",
                    darkItemBg: COLOR_BG_BLUE,
                    darkItemSelectedBg: COLOR_BG_LIGHT_BLUE,
                    darkItemHoverBg: COLOR_BG_LIGHT_BLUE,
                    darkSubMenuItemBg: COLOR_BG_DARK_BLUE,
                    darkPopupBg: COLOR_BG_DARK_BLUE,
                },
                Tree: {
                    nodeSelectedBg: COLOR_BG_LIGHT_BLUE,
                },
            },
        }}
    >
        <ToastContainer
            style={{
                marginTop: "4rem",
            }}
        />
        <App />
    </AntdThemeConfigProvider>
))

ReactDOM.render(
    <Router>
        <CloudUsercommProviderWS>
            <UsercommProviderBLE>
                <SyncronizationProvider>
                    <TranslationProvider>
                        <SubUsercommProvider />
                    </TranslationProvider>
                </SyncronizationProvider>
            </UsercommProviderBLE>
        </CloudUsercommProviderWS>
    </Router>,
    document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        console.log(
            `Index: serviceWorkerRegistration: onUpdate: `,
            registration,
        )
        if (registration.waiting) {
            console.log(
                `Index: serviceWorkerRegistration: sending SKIP_WAITING`,
            )
            registration.waiting.postMessage({
                type: "SKIP_WAITING",
            })
        }
        toastNotifyUpdateIsInstalled()
    },
})
