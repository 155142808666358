import {
    FC,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react"
import {
    UCError,
    HICConfig,
    HICState,
    StationConfig,
    StationSensors,
    UCPayload,
    UCPayloadType,
    UCAck,
    UUID,
    ReverseGeocodeRequest,
    CombinedStationSensorsAndHICState,
} from "../../../generated/proto-ts/main"
import { v4 as uuidv4, parse as uuidParse } from "uuid"
import { Mutex, withTimeout, MutexInterface } from "async-mutex"
import { crc16ccitt } from "crc"
import {
    IUsercommContextProviderContextType,
    usercommContext,
    usercommStateReducer,
} from "../usercommCommon"
import {
    equalUint8Array,
    handleReverseGeocodeRequest,
} from "../../common/usercommUtils"
import pako from "pako"
import { message as antdMessage } from "antd"
import platform from "platform"

const DEFAULT_EMIT_MUTEX_TIMEOUT = 1e3
const DEFAULT_DEVICE_ADVERTISEMENT_SEARCH_INTERVAL = 5e3
const DEFAULT_DEVICE_GATT_GET_PRIMARY_SERVICE_TIMEOUT = 3e3
const DEFAULT_DEVICE_GATT_CONNECTION_SLEEP_TIME = 100
const BLE_NORMENJEU_MANUFACTURER_ID = 0x1189
const BLE_HIC_SERVICE_UUID = 0x181c // User Data Service
// export const BLE_HIC_SERVICE_UUID = "0000181c-0000-1000-8000-00805f9b34fb" // User Data Service
const BLE_SOCI_CHARACTERISTIC_UUID = "0000856d-0000-1000-8000-00805f9b34fb"
const BLE_SICO_CHARACTERISTIC_UUID = "0000169e-0000-1000-8000-00805f9b34fb"
const BLE_HRBT_CHARACTERISTIC_UUID = "00002aea-0000-1000-8000-00805f9b34fb"

const BLE_MIN_MTU = 182 // Mac OS MTU is 182 (lowest among all platforms)
const BLE_MAX_MTU = 512 - 3 // BLE definition allows up to 512 bytes

const UC_BLE_START: Uint8Array = new Uint8Array([0x55, 0xaa, 0x55, 0xaa])
const UC_BLE_STOP: Uint8Array = new Uint8Array([0xaa, 0x55, 0xaa, 0x55])

const BLE_INTER_CHUNK_DELAY_MS = 20 // ~25 kB/s MAX
const BLE_INTER_MESSAGE_DELAY_MS = 100

const uint8ArraysAreEqual = (a: Uint8Array, b: Uint8Array) => {
    if (a.length !== b.length) {
        return false
    }
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false
        }
    }
    return true
}

export const navigatorSupportsWebBle = () => {
    return "bluetooth" in navigator
}

export const getBleDeviceCandidates = async () => {
    if (!navigatorSupportsWebBle()) {
        throw new Error("Web Bluetooth is not supported")
    }
    if (navigator.bluetooth.getDevices === undefined) {
        return []
    }
    const devices = await navigator.bluetooth.getDevices()
    let candidateDevices: (BluetoothDevice | null)[] = []
    for (let i = 0; i < devices.length; i++) {
        let d = devices[i]
        if (d.name && d.name.startsWith("LU")) {
            candidateDevices.push(d)
        }
    }
    return devices
}

export const requestBleDevice = async () => {
    if (!navigatorSupportsWebBle()) {
        throw new Error("Web Bluetooth is not supported")
    }
    const device = await navigator.bluetooth.requestDevice({
        filters: [
            {
                manufacturerData: [
                    {
                        companyIdentifier: BLE_NORMENJEU_MANUFACTURER_ID,
                    },
                ],
            },
        ],
        optionalServices: [BLE_HIC_SERVICE_UUID],
    })
    return device
}

const sleep = async (ms: number) => {
    return await new Promise((resolve) => setTimeout(resolve, ms))
}

export const useUsercommContextBLE =
    (): IUsercommContextProviderContextType => {
        const context = useContext(usercommContext)
        if (context === undefined) {
            throw new Error(
                "useSocketContext must be used within a SocketContextProvider",
            )
        }
        return context
    }

export const UsercommProviderBLE: FC<{
    children: ReactNode
}> = ({ children }) => {
    // Important! Two notification characteristics on a single device causes unknown GATT error on Androind
    const [bleIsPaired, setBleIsPaired] = useState(false)
    const [bleIsConnecting, setBleIsConnecting] = useState(false)
    const [state, dispatch] = useReducer(usercommStateReducer, {
        socket: undefined,
        socketIsConnected: false,
        bleDevice: undefined,
        bleIsConnected: false,

        hicState: null,
        hicConfig: null,
        stationConfig: null,
        stationSensors: null,

        // Consumables
        hicConfigSetAckConsumable: null,
        stationConfigSetAckConsumable: null,
        hicRawMeasurementConsumable: null,

        // Storage message queue
        recvMessageQueue: [],
        emitMessageQueue: [],

        emitMtu: BLE_MAX_MTU,
        recvMtu: BLE_MIN_MTU,
    })

    const sociRecvBufferRef = useRef<Uint8Array>(
        new Uint8Array(10 * 1024 * 1024),
    ) // 10 MB
    const sociRecvBufferOffsetRef = useRef<number>(0)
    const sociRecvPayloadLengthRef = useRef<number>(0)
    const sociRecvEffectiveMTURef = useRef<number>(0)
    const sociRecvTsRef = useRef<number>(0)

    const recvMutexRef = useRef<MutexInterface>(new Mutex())
    const emitMutexRef = useRef<MutexInterface>(
        withTimeout(new Mutex(), DEFAULT_EMIT_MUTEX_TIMEOUT),
    )

    const setBleIsConnected = useCallback(
        (isConnected: boolean) => {
            dispatch({ type: "SET_BLE_IS_CONNECTED", payload: isConnected })
        },
        [dispatch],
    )

    useEffect(() => {
        // console.log(`BLE_PB: UsercommProviderBLE mounted; platform:`, platform)
        if (platform !== undefined && platform.os !== undefined) {
            if (
                platform.os.family === "Windows" ||
                platform.os.family === "Android"
            ) {
                console.warn(
                    `BLE_PB: ${platform.os.family} platform detected; setting BLE_MAX_MTU to 512-2`,
                )
                dispatch({ type: "SET_RECV_MTU", payload: 512 - 2 })
                return
            }
            // MacOS: keep RECV_MTU at 182: OK
            //
            // TODO: Linux: set RECV_MTU to 512-2 (check)
            // TODO: iOS: determine the RECV_MTU and set accordingly
        }
    }, [platform])

    // WRITE to characteristic
    const sicoWriteToCharacteristic = useCallback(
        async (
            characteristic: BluetoothRemoteGATTCharacteristic | null,
            frame: Uint8Array,
        ) => {
            if (characteristic === null) {
                console.warn(
                    "sicoWriteToCharacteristic: sicoCharacteristic is null",
                )
                return
            }
            let frameLength = frame.length
            let tramView: DataView = new DataView(
                new ArrayBuffer(frameLength + 16),
            )
            // console.debug(
            //     `sicoWriteToCharacteristic: frameLength: ${frameLength}; tramLength: ${tramView.byteLength}`,
            // )
            let offset = 0
            for (let b of UC_BLE_START) {
                tramView.setUint8(offset, b)
                offset++
            }
            tramView.setUint16(offset, state.recvMtu) // notify the receiver of our receive MTU
            offset += 2
            tramView.setUint32(offset, frameLength)
            offset += 4
            for (let i = 0; i < frameLength; i++) {
                tramView.setUint8(offset, frame[i])
                offset++
            }
            let crc = crc16ccitt(frame)
            tramView.setUint16(offset, crc)
            offset += 2
            for (let b of UC_BLE_STOP) {
                tramView.setUint8(offset, b)
                offset++
            }
            try {
                for (let i = 0; i < tramView.byteLength; i += BLE_MAX_MTU) {
                    // Write with emit MTU (receiver supports max value of 512 bytes)
                    let endIdx = i + BLE_MAX_MTU
                    if (endIdx > tramView.byteLength) {
                        endIdx = tramView.byteLength
                    }
                    let chunk = new Uint8Array(tramView.buffer.slice(i, endIdx))
                    await characteristic.writeValue(chunk)
                    await sleep(BLE_INTER_CHUNK_DELAY_MS)
                    // let ratio = endIdx / tramView.byteLength
                    // let percInt = Math.floor(ratio * 100)
                    // if (percInt % 2 === 0) {
                    //     setEmitRatio(percInt / 100)
                    // }
                }
            } catch (e: any) {
                console.warn("sicoWriteToCharacteristic: error occurred", e)
            }
        },
        [state.recvMtu],
    )

    // RECV QUEUE
    const processSociEventCallback = useCallback(
        async (event: Event) => {
            try {
                if (event.target === null) {
                    return
                }
                let v = (event.target as BluetoothRemoteGATTCharacteristic)
                    .value
                if (!v) {
                    return
                }
                let d = new Uint8Array(v.buffer)
                if (sociRecvBufferOffsetRef.current === 0) {
                    sociRecvTsRef.current = Date.now()
                    sociRecvEffectiveMTURef.current = 0
                    sociRecvPayloadLengthRef.current = 0
                    // setRecvRatio(0)
                }
                if (d.length > sociRecvEffectiveMTURef.current) {
                    sociRecvEffectiveMTURef.current = d.length
                }
                // console.debug("BLE_PB: received char raw data", d.length)

                sociRecvBufferRef.current.set(
                    d,
                    sociRecvBufferOffsetRef.current,
                )
                sociRecvBufferOffsetRef.current += d.length
                // console.debug(
                //     `BLE_PB: received data (offset = ${sociRecvBufferOffsetRef.current}): `,
                //     sociRecvBufferRef.current.slice(0, sociRecvBufferOffsetRef.current),
                // )
                if (
                    !uint8ArraysAreEqual(
                        sociRecvBufferRef.current.slice(0, 4),
                        UC_BLE_START,
                    )
                ) {
                    console.warn(
                        "BLE_PB: received data does not start with UC_BLE_START: dropping the buffer",
                    )
                    sociRecvBufferOffsetRef.current = 0
                    // setRecvRatio(0)
                    return
                }
                if (sociRecvBufferRef.current.length < 10) {
                    // console.log(
                    //     `BLE_PB: received data is less than 10 bytes: tram length is unavailable yet`,
                    // )
                    return
                }
                if (sociRecvPayloadLengthRef.current === 0) {
                    sociRecvPayloadLengthRef.current = new DataView(
                        sociRecvBufferRef.current.buffer,
                    ).getUint32(6)
                } else {
                    let recvPayloadLength = sociRecvBufferOffsetRef.current - 16
                    let ratio =
                        recvPayloadLength / sociRecvPayloadLengthRef.current
                    let percInt = Math.floor(ratio * 100)
                    if (percInt % 2 === 0) {
                        // setRecvRatio(percInt / 100)
                    }
                }
                if (
                    !uint8ArraysAreEqual(
                        sociRecvBufferRef.current.slice(
                            sociRecvBufferOffsetRef.current - 4,
                            sociRecvBufferOffsetRef.current,
                        ),
                        UC_BLE_STOP,
                    )
                ) {
                    // console.debug(
                    //     "BLE_PB: received data does not end with UC_BLE_STOP: more data is expected",
                    // )
                    return
                }
                let tramView = new DataView(
                    sociRecvBufferRef.current.slice(
                        0,
                        sociRecvBufferOffsetRef.current,
                    ).buffer,
                )
                // setRecvRatio(1)
                sociRecvBufferOffsetRef.current = 0
                if (
                    sociRecvPayloadLengthRef.current !==
                    tramView.byteLength - 16
                ) {
                    console.warn(
                        `BLE_PB: received tram length and length header do not match: ${sociRecvPayloadLengthRef.current} !== ${tramView.byteLength - 16}`,
                    )
                }
                let tramPayload = new Uint8Array(
                    tramView.buffer.slice(
                        10,
                        10 + sociRecvPayloadLengthRef.current,
                    ),
                )
                let tramCrc = tramView.getUint16(
                    10 + sociRecvPayloadLengthRef.current,
                )
                // console.debug(
                //     "BLE_PB: received tram",
                //     sociRecvPayloadLengthRef.current,
                //     tramPayload.length,
                //     tramCrc,
                // )

                let localCrc = crc16ccitt(tramPayload)
                if (tramCrc !== localCrc) {
                    console.warn("BLE_PB: CRC mismatch: ", tramCrc, localCrc)
                }

                // UPD 2024-09-08: Decompress the payload
                // if tramPayload.slice(0, 3) === [0x1f, 0x8b, 0x08] // GZIP
                if (
                    equalUint8Array(
                        tramPayload.slice(0, 3),
                        new Uint8Array([0x1f, 0x8b, 0x08]),
                    )
                ) {
                    try {
                        let inflatedTramPayload = pako.ungzip(tramPayload)
                        let cr =
                            100 *
                            (1 -
                                tramPayload.length / inflatedTramPayload.length)
                        console.debug(
                            `BLE_PB: received GZIP compressed payload: CR (Gain): ${cr.toFixed(0)}%`,
                        )
                        if (cr < 0) {
                            console.warn(
                                `BLE_PB: negative CR: original size: ${tramPayload.length}; inflated size: ${inflatedTramPayload.length}`,
                            )
                        }
                        tramPayload = inflatedTramPayload
                    } catch (e: any) {
                        console.error(
                            `BLE_PB: failed to decompress GZIP payload`,
                            e.message,
                        )
                    }
                } else {
                    // console.debug(`BLE_PB: received uncompressed payload`)
                }

                let ucPayload: UCPayload | null = null
                try {
                    ucPayload = UCPayload.deserializeBinary(tramPayload)
                } catch (e) {
                    console.error(
                        `BLE_PB: failed to deserialize UCPayload from BLE SOCI characteristic`,
                        e,
                    )
                    return
                }
                if (ucPayload === null) {
                    console.error(`BLE_PB: ucPayload is null`)
                    return
                }

                console.debug(
                    `BLE_PB: UCPayload Type #${ucPayload.type} (${ucPayload.request_type})`,
                )

                // Set BLE is connected on every received message
                setBleIsConnected(true)

                let ack: UCAck | null = null
                // UPD 2024-09-04: payload.type is either the request_type, either error (0)
                // payload.request_type is always the request_type, even if error is set
                // allows to propagate the error towards the requester and to enable error handling
                //
                // some low-index (< 10) commands do not support error handling, so handle the case of an error
                // received to such a request
                if (ucPayload.request_type === 0) {
                    // retrocompatibility
                    // firmware versions before 2024-09-04
                    ucPayload.request_type = ucPayload.type
                }
                if (
                    ucPayload.type === UCPayloadType.SICO_ERROR &&
                    ucPayload.request_type < 10 // all types before SICO_RESET_REVERSE_GEOCODE_CACHE [10]
                ) {
                    let error = UCError.deserializeBinary(ucPayload.data)
                    console.error(`BLE_PB: SICO_ERROR: ${error.value}`)
                    return
                }

                if (ucPayload.request_type < 64) {
                    // Responses to SICO commands
                    switch (ucPayload.request_type) {
                        // Response to SICO GetHICConfigCommand
                        case UCPayloadType.SICO_GET_HIC_CONFIG_COMMAND:
                            let hicConfig = HICConfig.deserializeBinary(
                                ucPayload.data,
                            )
                            dispatch({
                                type: "SET_HIC_CONFIG",
                                payload: hicConfig,
                            })
                            // also add message to recv queue
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        // Response to SICO GetStationConfigCommand
                        case UCPayloadType.SICO_GET_STATION_CONFIG_COMMAND:
                            let stationConfig = StationConfig.deserializeBinary(
                                ucPayload.data,
                            )
                            dispatch({
                                type: "SET_STATION_CONFIG",
                                payload: stationConfig,
                            })
                            // also add message to recv queue
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        // Response to SICO SetHICConfigCommand
                        case UCPayloadType.SICO_SET_HIC_CONFIG_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            dispatch({
                                type: "SET_HIC_CONFIG_SET_ACK_CONSUMABLE",
                                payload: ack.value,
                            })
                            break
                        // Response to SICO SetStationConfigCommand
                        case UCPayloadType.SICO_SET_STATION_CONFIG_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            dispatch({
                                type: "SET_STATION_CONFIG_SET_ACK_CONSUMABLE",
                                payload: ack.value,
                            })
                            break
                        // Response to SICO DropHICCommand
                        case UCPayloadType.SICO_DROP_HIC_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            console.debug(
                                `BLE_PB: SICO_DROP_HIC_COMMAND: ${ack.value}`,
                            )
                            break
                        // Response to SICO RestartStationCommand
                        case UCPayloadType.SICO_RESTART_STATION_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            console.debug(
                                `BLE_PB: SICO_RESTART_STATION_COMMAND: ${ack.value}`,
                            )
                            break
                        // Response to SICO RebootStationCommand
                        case UCPayloadType.SICO_REBOOT_STATION_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            console.debug(
                                `BLE_PB: SICO_REBOOT_STATION_COMMAND: ${ack.value}`,
                            )
                            break
                        // Response to SICO HaltStationCommand
                        case UCPayloadType.SICO_HALT_STATION_COMMAND:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            console.debug(
                                `BLE_PB: SICO_HALT_STATION_COMMAND: ${ack.value}`,
                            )
                            break
                        // Response to SICO AddReverseGeocodeEntity
                        case UCPayloadType.SICO_ADD_REVERSE_GEOCODE_ENTITY:
                            ack = UCAck.deserializeBinary(ucPayload.data)
                            console.debug(
                                `BLE_PB: SICO_ADD_REVERSE_GEOCODE_ENTITY: ${ack.value}`,
                            )
                            break
                        // Response to SICO LegacyHostAPIRequest
                        case UCPayloadType.SICO_LEGACY_HOST_API_REQUEST:
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        // MTU test
                        // case UCPayloadType.SICO_MTU_TEST:
                        //     let mtuTestResponse = MTUTestResponse.deserializeBinary(
                        //         ucPayload.data,
                        //     )
                        //     let _writeMtu = mtuTestResponse.received_size + 10
                        //     let _readMtu = sociRecvEffectiveMTURef.current
                        //     console.log(
                        //         `BLE_PB: received mtu test response: received size: ${mtuTestResponse.received_size}; Write MTU: ${_writeMtu}; Read MTU: ${_readMtu}; Initial MTU: ${payloadMtu}`,
                        //     )
                        //     dispatch({
                        //         type: "SET_MTU",
                        //         payload: _writeMtu,
                        //     })
                        //     break
                        // Echo test
                        case UCPayloadType.SICO_ECHO_TEST:
                            let dMs = Date.now() - sociRecvTsRef.current
                            if (dMs > 0) {
                                let throughputKBps =
                                    tramView.byteLength / 1024 / (dMs / 1000)
                                console.debug(
                                    `BLE_PB: ECHO: received ${tramView.byteLength} bytes in ${dMs} ms: ${throughputKBps} kiB/s`,
                                )
                            }
                            break
                        // Get Releases
                        case UCPayloadType.SICO_GET_RELEASES:
                            console.debug(
                                `BLE_PB: received get releases command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        // Rename release
                        case UCPayloadType.SICO_RENAME_RELEASE:
                            console.debug(
                                `BLE_PB: received rename release command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_SET_RELEASE:
                            console.debug(
                                `BLE_PB: received set release command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_DELETE_RELEASE:
                            console.debug(
                                `BLE_PB: received delete release command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_DOWNLOAD_RELEASE_BY_URI:
                            console.debug(
                                `BLE_PB: received download release by URI command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_BASH_CMD:
                            console.debug(`BLE_PB: received bash command`)
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_WPA_GET_STATUS:
                            console.debug(`BLE_PB: received get status command`)
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_WPA_GET_NETWORKS:
                            console.log(`BLE_PB: received get networks command`)
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_WPA_SET_NETWORKS:
                            console.log(`BLE_PB: received set networks command`)
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_WPA_GET_SCAN_RESULTS:
                            console.log(
                                `BLE_PB: received get scan results command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_WPA_REASSOCIATE:
                            console.log(`BLE_PB: received reassociate command`)
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_NETWORK_GET_PRIMARY_IP_ADDRESSES:
                            console.log(
                                `BLE_PB: received get primary ip addresses command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_GET_STATION_STATE:
                            console.log(
                                `BLE_PB: received get station state command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_GNSS_UBLOX_GET_STATE:
                            console.log(
                                `BLE_PB: received get GNSS UBLOX state command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_GNSS_UBLOX_GET_ALP_FILES:
                            console.log(
                                `BLE_PB: received get GNSS UBLOX ALP files command`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_GNSS_UBLOX_SET_ALP_FILE:
                            console.log(
                                `BLE_PB: received set GNSS UBLOX ALP file ACK`,
                            )
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_GNSS_UBLOX_WRITE_RTCM:
                            console.log(`BLE_PB: received write RTCM ACK`)
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        case UCPayloadType.SICO_GNSS_UBLOX_RESET:
                            console.log(`BLE_PB: received GNSS UBLOX reset ACK`)
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        default:
                            console.warn(
                                `BLE_PB: unhandled message (<0x40)`,
                                ucPayload.type,
                            )
                            break
                    }
                } else if (ucPayload.request_type >= 128) {
                    switch (ucPayload.request_type) {
                        // SOCI Events
                        case UCPayloadType.SOCI_HIC_STATE_EVENT:
                            let hicState = HICState.deserializeBinary(
                                ucPayload.data,
                            )
                            dispatch({
                                type: "SET_HIC_STATE",
                                payload: hicState,
                            })
                            break
                        case UCPayloadType.SOCI_STATION_SENSORS_EVENT:
                            let stationSensors =
                                StationSensors.deserializeBinary(ucPayload.data)
                            dispatch({
                                type: "SET_STATION_SENSORS",
                                payload: stationSensors,
                            })
                            break
                        case UCPayloadType.SOCI_COMBINED_STATION_SENSORS_AND_HIC_STATE_EVENT:
                            let combinedMessage =
                                CombinedStationSensorsAndHICState.deserializeBinary(
                                    ucPayload.data,
                                )
                            dispatch({
                                type: "SET_HIC_STATE",
                                payload: combinedMessage.hic_state,
                            })
                            dispatch({
                                type: "SET_STATION_SENSORS",
                                payload: combinedMessage.station_sensors,
                            })
                            break
                        case UCPayloadType.SOCI_HIC_MEASUREMENT_EVENT:
                            let measurementUUID = UUID.deserializeBinary(
                                ucPayload.data,
                            )
                            console.log(
                                `BLE_PB: received hic measurement event`,
                                measurementUUID,
                            )
                            dispatch({
                                type: "SET_HIC_RAW_MEASUREMENT_CONSUMABLE",
                                payload: measurementUUID,
                            })
                            break
                        case UCPayloadType.SOCI_REVERSE_GEOCODE_REQUEST:
                            let reverseGeocodeRequest =
                                ReverseGeocodeRequest.deserializeBinary(
                                    ucPayload.data,
                                )
                            console.log(
                                `BLE_PB: received reverse geocode request`,
                                reverseGeocodeRequest,
                            )
                            handleReverseGeocodeRequest(
                                reverseGeocodeRequest,
                            ).then((reverseGeocodeResponse) => {
                                if (reverseGeocodeResponse === null) {
                                    console.warn(
                                        `BLE_PB: reverse geocode response is null`,
                                    )
                                    return
                                }
                                console.log(
                                    `BLE_PB: reverse geocode response`,
                                    reverseGeocodeResponse,
                                )
                                addEmitMessage(
                                    new UCPayload({
                                        uuid: undefined,
                                        type: UCPayloadType.SICO_ADD_REVERSE_GEOCODE_ENTITY,
                                        data: reverseGeocodeResponse.serializeBinary(),
                                    }),
                                )
                            })

                            break
                        case UCPayloadType.SOCI_BASH_STDOUT:
                            dispatch({
                                type: "ADD_MESSAGE_TO_RECV_QUEUE",
                                payload: ucPayload,
                            })
                            break
                        default:
                            console.warn(
                                `BLE_PB: unhandled message (>0x80)`,
                                ucPayload.uuid.value,
                                ucPayload.type,
                            )
                            break
                    }
                } else {
                    // Storage messages
                    dispatch({
                        type: "ADD_MESSAGE_TO_RECV_QUEUE",
                        payload: ucPayload,
                    })
                }
                return
            } catch (e: any) {
                console.error(
                    "BLE_PB: error processing received data",
                    e.message,
                )
            }
        },
        [dispatch],
    )

    const handleGattServiceDisconnected = useCallback(
        async (event: Event) => {
            console.log("BLE_PB: GATT service disconnected")
            dispatch({ type: "SET_STATION_SENSORS", payload: null })
            dispatch({ type: "SET_HIC_STATE", payload: null })
            setBleIsConnected(false)
        },
        [dispatch, setBleIsConnected],
    )

    // READ and NOTIFY from characteristics
    const handleSociCharacteristicValueChanged = (event: Event) => {
        // let t0 = Date.now()
        recvMutexRef.current.acquire().then((release) => {
            // let tA = Date.now()
            // console.debug(
            //     `BLE_PB: SOCI received char value changed event: mutex acquired after ${tA - t0}ms`,
            // )
            processSociEventCallback(event).finally(() => {
                // let tR = Date.now()
                // console.debug(
                //     `BLE_PB: SOCI: received char value changed event: process callback took ${tR - tA}ms`,
                // )
                release()
            })
        })
    }

    const handleHrbtCharacteristicValueChanged = (event: Event) => {
        if (event.target === null) {
            return
        }
        let v = (event.target as BluetoothRemoteGATTCharacteristic).value
        if (!v) {
            return
        }
        let hrbtIdx = new DataView(v.buffer).getUint16(0)
        console.debug("BLE_PB: HRBT #", hrbtIdx)
    }

    const startNotificationsCallback = useCallback(
        async (bleGattSnap: BluetoothRemoteGATTServer): Promise<boolean> => {
            if (bleGattSnap.connected === false) {
                return false
            }
            console.debug(
                `BLE_PB: STARTUP: about to get primary service ${BLE_HIC_SERVICE_UUID}`,
            )
            let service: BluetoothRemoteGATTService | null = null
            try {
                // UPD 2024-09-08: getPrimaryService might get "stuck" on some platforms, e.g. Windows
                // for unknown reason (implementation issue?)
                // so we need to race it with a timeout
                service = await Promise.any([
                    bleGattSnap.getPrimaryService(BLE_HIC_SERVICE_UUID),
                    (async function () {
                        await sleep(
                            DEFAULT_DEVICE_GATT_GET_PRIMARY_SERVICE_TIMEOUT,
                        )
                        return null
                    })(),
                ])
            } catch (e: any) {
                console.log(
                    `BLE_PB: STARTUP: error getting primary service ${BLE_HIC_SERVICE_UUID}`,
                    e.message,
                )
            }
            if (service === null) {
                console.warn(
                    `BLE_PB: STARTUP: primary service ${BLE_HIC_SERVICE_UUID} is null`,
                )
                return false
            }

            let sociCharacteristic: BluetoothRemoteGATTCharacteristic | null =
                null
            // let hrbtCharacteristic: BluetoothRemoteGATTCharacteristic | null =
            //     null
            try {
                sociCharacteristic = await service.getCharacteristic(
                    BLE_SOCI_CHARACTERISTIC_UUID,
                )
                sociCharacteristic.oncharacteristicvaluechanged =
                    handleSociCharacteristicValueChanged
                await sociCharacteristic.startNotifications()
                // console.log(
                //     `BLE_PB: STARTUP:  characteristic with value changed handler`,
                //     sociCharacteristic,
                // )
            } catch (e: any) {
                console.warn(
                    `BLE_PB: STARTUP: error starting notifications for soci characteristic`,
                    e.message,
                )
            }
            return sociCharacteristic !== null

            // UPD 2024-09-04: HRBT characteristic is not used anymore
            // SOCI messages 128 (HIC State) and 129 (Sensors) make sure
            // that at least some data is sent ping-ponged over the channel
            //
            // try {
            //     hrbtCharacteristic = await service.getCharacteristic(
            //         BLE_HRBT_CHARACTERISTIC_UUID,
            //     )
            //     hrbtCharacteristic.oncharacteristicvaluechanged =
            //         handleHrbtCharacteristicValueChanged
            //     await hrbtCharacteristic.startNotifications()
            //     console.log(
            //         `BLE_PB: STARTUP: hrbt characteristic with value changed handler`,
            //         hrbtCharacteristic,
            //     )
            // } catch (e: any) {
            //     console.log(
            //         `BLE_PB: STARTUP:  error starting notifications for hrbt characteristic`,
            //         e.message,
            //     )
            // }
            // return sociCharacteristic !== null && hrbtCharacteristic !== null
        },
        [],
    )

    // EMIT QUEUE
    const processEmitQueueCallback = useCallback(
        async (emitQueueSnap: UCPayload[]) => {
            if (emitQueueSnap.length === 0) {
                return
            }
            console.debug(
                `BLE_PB: SICO: about to process EMIT_QUEUE length ${emitQueueSnap.length}`,
            )
            if (
                state.bleDevice === undefined ||
                state.bleDevice.gatt === undefined
            ) {
                return
            }
            if (state.bleDevice.gatt.connected === false) {
                // the message won't be sent and consumed, but it will
                // stay in the queue until the queue is updated (messages added)
                return
            }
            let service =
                await state.bleDevice.gatt.getPrimaryService(
                    BLE_HIC_SERVICE_UUID,
                )
            let sicoCharacteristic = await service.getCharacteristic(
                BLE_SICO_CHARACTERISTIC_UUID,
            )

            for (let ucPayload of emitQueueSnap) {
                let frame = ucPayload.serializeBinary()
                if (frame.length > 2 * BLE_MAX_MTU - 16) {
                    // compress (gzip deflate) the payload
                    // if the payload is larger than the MTU
                    // and is not of an exception type
                    if (
                        // TODO: enable SICO compression
                        false && // disable compression for now as this will make front-end incompatible with older firmware versions
                        ucPayload.type !== UCPayloadType.SICO_ECHO_TEST && // echo tests are all zeros, compression will defy the point of the test
                        ucPayload.type !== UCPayloadType.SICO_SET_RELEASE && // releases are already compressed
                        ucPayload.type !==
                            UCPayloadType.SICO_GNSS_UBLOX_SET_ALP_FILE // ALP files are already compressed
                    ) {
                        let compressedFrame = pako.gzip(frame)
                        let compressionGain =
                            100 * (1 - compressedFrame.length / frame.length)
                        console.debug(
                            `BLE_PB: SICO: compressing payload: CR (Gain): ${compressionGain.toFixed(0)}%`,
                        )
                        frame = compressedFrame
                    }
                }
                console.debug(
                    `BLE_PB: SICO: sending command of type #${ucPayload.type}: ${frame.length} bytes`,
                )
                try {
                    await sicoWriteToCharacteristic(sicoCharacteristic, frame)
                } catch (e: any) {
                    console.error(
                        "BLE_PB: SICO: error sending command",
                        e.message,
                    )
                } finally {
                    await sleep(BLE_INTER_MESSAGE_DELAY_MS)
                    dispatch({
                        type: "CONSUME_MESSAGE_FROM_EMIT_QUEUE",
                        payload: ucPayload.uuid,
                    })
                }
            }
        },
        [
            state.bleDevice,
            state.bleDevice?.gatt,
            state.bleDevice?.gatt?.connected,
        ],
    )

    useEffect(() => {
        let emitQueueLength = state.emitMessageQueue.length
        if (emitQueueLength === 0) {
            return
        }
        // console.debug(`BLE_PB: SICO: EMIT_QUEUE length ${emitQueueLength}`)
        // let t0 = Date.now()
        emitMutexRef.current
            .acquire()
            .then(async (release) => {
                // let tA = Date.now()
                // console.debug(
                //     `BLE_PB: SICO: EMIT_QUEUE: mutex acquired after ${Date.now() - t0}ms`,
                // )
                processEmitQueueCallback(state.emitMessageQueue).finally(() => {
                    // let tR = Date.now()
                    // console.debug(
                    //     `BLE_PB: SICO: processing EMIT_QUEUE (n=${emitQueueLength}) took ${tR - tA}ms `,
                    // )
                    release()
                })
            })
            .catch((e: any) => {
                // console.warn(
                //     `BLE_PB: SICO: emit mutex could not be acquired: ${e.message}`,
                // )
            })
        return () => {
            emitMutexRef.current.cancel()
        }
    }, [state.emitMessageQueue])

    // GATT
    // Connect
    const connectBleGattMutex = useRef<MutexInterface>(new Mutex())
    const connectBleGattCallback = useCallback(
        async (bleDeviceSnap: BluetoothDevice) => {
            let shouldRetry = false
            if (connectBleGattMutex.current.isLocked()) {
                console.log(
                    "BLE_PB: connectBleGattCallback: mutex is locked, will not connect",
                )
                return
            }
            let release = await connectBleGattMutex.current.acquire()
            setBleIsConnecting(true)
            try {
                if (bleDeviceSnap.gatt === undefined) {
                    return
                }
                if (bleDeviceSnap.watchAdvertisements !== undefined) {
                    bleDeviceSnap.onadvertisementreceived =
                        handleOnAdvertisementReceivedCallback
                    await bleDeviceSnap.watchAdvertisements()
                }
                setBleIsPaired(true)
                try {
                    await sleep(5 * DEFAULT_DEVICE_GATT_CONNECTION_SLEEP_TIME)
                    let gatt = bleDeviceSnap.gatt
                    if (gatt.connected === false) {
                        // Note: this call will completely freeze the browser (on Windows) if called on
                        // a device which did not yet properly advertisze itself
                        // It is an open issue on Chrome/Chromium: https://issues.chromium.org/issues/40502943
                        gatt = await bleDeviceSnap.gatt.connect()
                    }
                    gatt.device.ongattserverdisconnected =
                        handleGattServiceDisconnected
                    let isSuccess = await startNotificationsCallback(gatt)
                    console.debug(
                        `BLE_PB: startNotificationsCallback: success:`,
                        isSuccess,
                    )
                    setBleDevice(bleDeviceSnap) // should it be here or right after gatt.conect() ?
                    if (isSuccess) {
                        setBleIsConnected(true)
                        setBleIsConnecting(false)
                        console.log("BLE_PB: connected to GATT", gatt)
                    } else {
                        console.warn(
                            "BLE_PB: failed to start notifications, will disconnect from GATT",
                            gatt,
                        )
                        gatt.disconnect()
                        shouldRetry = true
                    }
                } catch (e: any) {
                    let msg = `BLE_PB: error connecting to GATT: ${e.message}`
                    console.error(msg)
                    shouldRetry = true
                    // antdMessage.error(msg)
                    // setBleIsConnecting(false)
                    // setBleIsConnected(false)
                }
            } finally {
                release()
            }
            if (shouldRetry) {
                await sleep(DEFAULT_DEVICE_GATT_CONNECTION_SLEEP_TIME)
                // window.location.reload() // TODO: whis works too, is it better?
                return connectBleGattCallback(bleDeviceSnap) // recursive
            }
        },
        [setBleIsConnected, setBleIsPaired],
    )
    // Disconnect
    const disconnectBleGattCallback = useCallback(async () => {
        console.log("BLE_PB: about to disconnect from GATT..")
        if (state.bleDevice === undefined) {
            console.debug(
                `BLE_PB: cannot disconnect from gatt as device is undefined`,
            )
            setBleIsConnected(false)
            return
        }
        if (state.bleDevice.gatt === undefined) {
            console.debug(
                `BLE_PB: cannot disconnect from gatt as gatt is undefined`,
            )
            setBleIsConnected(false)
            return
        }
        if (state.bleDevice.gatt.connected === false) {
            console.log("BLE_PB: already disconnected from GATT")
            setBleIsConnected(false)
            return
        }
        try {
            state.bleDevice.gatt.disconnect()
            console.log("BLE_PB: disconnected from GATT", state.bleDevice.gatt)
            setBleIsConnected(false)
        } catch (e: any) {
            console.error("BLE_PB: error disconnecting from GATT", e.message)
        }
    }, [state.bleDevice])

    const forgetBleGattCallback = useCallback(async () => {
        if (
            state.bleDevice === undefined ||
            state.bleDevice.forget === undefined
        ) {
            return
        }
        await state.bleDevice.forget()
        console.log("BLE_PB: forgot GATT", state.bleDevice)
        setBleDevice(undefined)
    }, [state.bleDevice])

    const handleOnAdvertisementReceivedCallback = useCallback(
        async (event: BluetoothAdvertisingEvent) => {
            console.debug(
                `BLE_PB: ADVERTISEMENT received; RSSI: ${event.rssi}`,
                event,
            )
            for (let serviceUUID of event.uuids) {
                let serviceUUIDStr = serviceUUID.toString()
                let serviceUUIDInt = parseInt(serviceUUIDStr, 16)
                if (serviceUUIDInt === BLE_HIC_SERVICE_UUID) {
                    console.log("BLE_PB: ADVERTISEMENT contains HIC service")
                    await connectBleGattCallback(event.device)
                    return
                }
            }
        },
        [connectBleGattCallback],
    )

    const subscribeAdvertisementsMutex = useRef<MutexInterface>(new Mutex())
    const subscribeForKnownDevicesAdvertisementsCallback =
        useCallback(async () => {
            if (subscribeAdvertisementsMutex.current.isLocked()) {
                console.log(
                    "BLE_PB: subscribeForKnownDevicesAdvertisementsCallback: mutex is locked, will not subscribe",
                )
                return
            }
            let release = await subscribeAdvertisementsMutex.current.acquire()
            try {
                let deviceCandidates = await getBleDeviceCandidates()
                console.log(
                    "BLE_PB: SEARCH: device candidates:",
                    deviceCandidates,
                )
                if (deviceCandidates.length === 0) {
                    setBleIsPaired(false)
                    return
                }
                for (let deviceCandidate of deviceCandidates) {
                    if (deviceCandidate.watchAdvertisements !== undefined) {
                        deviceCandidate.watchAdvertisements()
                    }
                    deviceCandidate.onadvertisementreceived =
                        handleOnAdvertisementReceivedCallback
                    setBleIsPaired(true)
                }
            } finally {
                release()
            }
        }, [])

    useEffect(() => {
        if (state.bleIsConnected) {
            return
        }
        subscribeForKnownDevicesAdvertisementsCallback()
        // Note: the following code seems to be unnesassary
        // as it does not help on Windows to force reconnect to the device
        // as we do not want to risk the browser and force gatt.connect() (see above)
        //
        // On Android, advertisements fire the appropriate handler once the device is in range
        // so no need to force the connection
        //
        // TODO: check behavior on Linux, MacOS and iOS
        //
        // let t = setInterval(
        //     () => {},
        //     DEFAULT_DEVICE_ADVERTISEMENT_SEARCH_INTERVAL,
        // )
        // return () => {
        //     clearInterval(t)
        // }
    }, [state.bleIsConnected])

    useEffect(() => {
        const onBeforeUnload = async (e: Event) => {
            console.log(`BLE_PB: UsercommProviderBLE onBeforeUnload fired!`)
            await disconnectBleGattCallback()
            // e.preventDefault() // uncomment to prevent immediate page reload (thru user confirmation)
        }
        window.addEventListener("beforeunload", onBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload)
        }
    }, [disconnectBleGattCallback])

    // Internal state
    //
    const consumeHICRawMeasurement = useCallback(() => {
        dispatch({ type: "SET_HIC_RAW_MEASUREMENT_CONSUMABLE", payload: null })
    }, [])

    const consumeHICConfigSetAck = useCallback(() => {
        dispatch({ type: "SET_HIC_CONFIG_SET_ACK_CONSUMABLE", payload: null })
    }, [])

    const consumeStationConfigSetAck = useCallback(() => {
        dispatch({
            type: "SET_STATION_CONFIG_SET_ACK_CONSUMABLE",
            payload: null,
        })
    }, [])

    const emitGetHICConfig = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_GET_HIC_CONFIG_COMMAND,
        })
        console.log(`BLE_PB: sending get hic config`, ucPayload.toObject())
        addEmitMessage(ucPayload)
    }, [])

    const emitGetStationConfig = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_GET_STATION_CONFIG_COMMAND,
        })
        console.log(`BLE_PB: sending get station config`, ucPayload.toObject())
        addEmitMessage(ucPayload)
    }, [])

    const emitSetHICConfig = useCallback((config: HICConfig, uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_SET_HIC_CONFIG_COMMAND,
            data: config.serializeBinary(),
        })
        console.log(`BLE_PB: sending set hic config`, config.toObject())
        addEmitMessage(ucPayload)
    }, [])

    const emitSetStationConfig = useCallback(
        (config: StationConfig, uuid?: string) => {
            const ucPayload = new UCPayload({
                uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
                type: UCPayloadType.SICO_SET_STATION_CONFIG_COMMAND,
                data: config.serializeBinary(),
            })
            console.log(
                `BLE_PB: sending set station config`,
                config.toObject(),
                ucPayload.toObject(),
            )
            addEmitMessage(ucPayload)
        },
        [],
    )

    const emitDropHIC = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_DROP_HIC_COMMAND,
        })
        console.log(
            `BLE_PB: sending start hic measurement`,
            ucPayload.toObject(),
        )
        addEmitMessage(ucPayload)
    }, [])

    const emitRestartStation = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_RESTART_STATION_COMMAND,
        })
        console.log(
            `BLE_PB: sending restart station command`,
            ucPayload.toObject(),
        )
        addEmitMessage(ucPayload)
    }, [])

    const emitRebootStation = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_REBOOT_STATION_COMMAND,
        })
        console.log(
            `BLE_PB: sending reboot station command`,
            ucPayload.toObject(),
        )
        addEmitMessage(ucPayload)
    }, [])

    const emitHaltStation = useCallback((uuid?: string) => {
        const ucPayload = new UCPayload({
            uuid: new UUID({ value: uuidParse(uuid || uuidv4()) }),
            type: UCPayloadType.SICO_HALT_STATION_COMMAND,
        })
        console.log(
            `BLE_PB: sending halt station command`,
            ucPayload.toObject(),
        )
        addEmitMessage(ucPayload)
    }, [])

    const consumeRecvMessage = useCallback(
        (uuid: UCPayload["uuid"]) => {
            dispatch({ type: "CONSUME_MESSAGE_FROM_RECV_QUEUE", payload: uuid })
        },
        [dispatch],
    )

    const addEmitMessage = useCallback(
        (payload: UCPayload) => {
            dispatch({ type: "ADD_MESSAGE_TO_EMIT_QUEUE", payload })
        },
        [dispatch],
    )

    const setBleDevice = useCallback(
        (device: BluetoothDevice | undefined) => {
            dispatch({ type: "SET_BLE_DEVICE", payload: device })
        },
        [dispatch],
    )

    return (
        <usercommContext.Provider
            value={{
                ...state,
                socket: undefined,
                socketIsConnected: false,

                setSocketIsConnected: (isConnected: boolean) => {},
                setBleDevice,
                setBleIsConnected,

                emitGetHICConfig,
                emitSetHICConfig,
                emitGetStationConfig,
                emitSetStationConfig,
                emitDropHIC,
                emitRestartStation,
                emitRebootStation,
                emitHaltStation,

                consumeHICConfigSetAck,
                consumeStationConfigSetAck,
                consumeHICRawMeasurement,

                consumeRecvMessage,
                addEmitMessage,

                bleIsPaired,
                bleIsConnecting,
                connectBleGatt: connectBleGattCallback,
                disconnectBleGatt: disconnectBleGattCallback,
                forgetBleGatt: forgetBleGattCallback,

                // emitMtuTest,
            }}
        >
            {children}
        </usercommContext.Provider>
    )
}
