import { FC, useCallback, useEffect, useMemo, useState } from "react"
import {
    AutoComplete,
    Button,
    Space,
    Tooltip,
    message as antdMessage,
} from "antd"
import { AimOutlined } from "@ant-design/icons"
import { GoogleMapsMarker, GoogleMapsReactSignedIn } from "./googleMaps"
import { useGooglePlacesServices } from "./googlePlacesServices"
import { FlexCol } from "../common"
import { useUsercommContextBLE } from "../../../usercomm/local/ble/usercommProviderBLE"
import { IPApiResult, getIPLocation } from "../../../utils/ipapi"
import { COLOR_BG_RED } from "../../../utils/utils"
import { ICoordinate } from "../../../types"

export const GoogleAddressWidget: FC<{
    address: string | null
    setAddress: (address: string | null) => void
    markerCoordinates?: { lat: number; lng: number }[]
}> = ({ address, setAddress, markerCoordinates }) => {
    const {
        sessionToken,
        autocompleteService,
        placesService,
        geocoderService,
    } = useGooglePlacesServices()

    const [placePredictions, setPlacePredictions] = useState<
        google.maps.places.AutocompletePrediction[]
    >([])

    const [selectedCoordinates, setSelectedCoordinates] =
        useState<ICoordinate | null>(null)
    const [biasGeoposition, setBiasGeoposition] = useState<ICoordinate | null>(
        null,
    )

    const [ipApiResult, setIpApiResult] = useState<IPApiResult | null>(null)

    const [value, setValue] = useState<string>("")
    const { stationSensors } = useUsercommContextBLE()

    useEffect(() => {
        getIPLocation().then((ipApiResult) => {
            setIpApiResult(ipApiResult)
        })
    }, [])

    const memoMarkerBarycenter = useMemo(() => {
        if (markerCoordinates === undefined || markerCoordinates.length === 0) {
            return null
        }
        let sumLat = 0
        let sumLng = 0
        let cnt = 0
        for (let coord of markerCoordinates) {
            sumLat += coord.lat
            sumLng += coord.lng
            cnt++
        }
        let meanLat = sumLat / cnt
        let meanLng = sumLng / cnt
        return {
            coords: {
                latitude: meanLat,
                longitude: meanLng,
            },
        }
    }, [markerCoordinates])

    useEffect(() => {
        if (memoMarkerBarycenter !== null) {
            setBiasGeoposition(memoMarkerBarycenter)
            return
        }
        if (stationSensors !== null) {
            if (
                stationSensors.gnss_lat !== 0 &&
                stationSensors.gnss_lon !== 0
            ) {
                setBiasGeoposition({
                    coords: {
                        latitude: stationSensors.gnss_lat,
                        longitude: stationSensors.gnss_lon,
                    },
                })
                return
            }
        }
        if (ipApiResult !== null) {
            setBiasGeoposition({
                coords: {
                    latitude: ipApiResult.latitude,
                    longitude: ipApiResult.longitude,
                },
            })
            return
        }
    }, [memoMarkerBarycenter, ipApiResult, stationSensors])

    const getPlacePredictionsOnChangeMutCB = useCallback(
        (value: string) => {
            if (autocompleteService === null || sessionToken === null) {
                return
            }
            autocompleteService.getPlacePredictions(
                {
                    sessionToken: sessionToken,
                    input: value,
                    locationBias: biasGeoposition
                        ? new google.maps.Circle({
                              center: {
                                  lat: biasGeoposition.coords.latitude,
                                  lng: biasGeoposition.coords.longitude,
                              },
                              radius: 1000,
                          })
                        : undefined,
                },
                (predictions, status) => {
                    if (
                        status !== google.maps.places.PlacesServiceStatus.OK ||
                        predictions === null
                    ) {
                        antdMessage.warning(
                            `GooglePlaces PlcaePredictions NOK: ${status}`,
                        )
                        return
                    }
                    setPlacePredictions(predictions)
                },
            )
        },
        [autocompleteService, sessionToken, biasGeoposition],
    )

    const biasGeopositionOnClickMutCB = useCallback(() => {
        if (biasGeoposition === null) {
            return
        }
        if (geocoderService !== null) {
            geocoderService.geocode(
                {
                    location: {
                        lat: biasGeoposition.coords.latitude,
                        lng: biasGeoposition.coords.longitude,
                    },
                },
                (results, status) => {
                    console.log(`GooglePlaces: nearbySearch`, results)
                    if (
                        status !== google.maps.GeocoderStatus.OK ||
                        results === null
                    ) {
                        antdMessage.warning(
                            `GooglePlaces Geocoder NOK: ${status}`,
                        )
                        return
                    }
                    let firstResult = results[0]
                    if (firstResult === undefined) {
                        return
                    }
                    setValue(firstResult.formatted_address ?? "")
                    setAddress(firstResult.formatted_address ?? "")
                    if (
                        firstResult.geometry !== undefined &&
                        firstResult.geometry.location !== undefined
                    ) {
                        let latLng = firstResult.geometry.location
                        setSelectedCoordinates({
                            coords: {
                                latitude: latLng.lat(),
                                longitude: latLng.lng(),
                            },
                        })
                    }
                },
            )
        }
    }, [biasGeoposition, geocoderService])

    useEffect(() => {
        if (address === "" || geocoderService === null) {
            return
        }
        geocoderService.geocode(
            {
                address: address,
            },
            (results, status) => {
                console.log(`GooglePlaces: geocode`, results)
                if (
                    status !== google.maps.GeocoderStatus.OK ||
                    results === null
                ) {
                    antdMessage.warning(`GooglePlaces Geocoder NOK: ${status}`)
                    return
                }
                let firstResult = results[0]
                if (firstResult === undefined) {
                    return
                }
                setValue(firstResult.formatted_address ?? "")
                if (
                    firstResult.geometry !== undefined &&
                    firstResult.geometry.location !== undefined
                ) {
                    let latLng = firstResult.geometry.location
                    setSelectedCoordinates({
                        coords: {
                            latitude: latLng.lat(),
                            longitude: latLng.lng(),
                        },
                    })
                }
            },
        )
    }, [address, geocoderService])

    useEffect(() => {
        if (selectedCoordinates !== null) {
            return
        }
        if (markerCoordinates !== undefined && markerCoordinates.length > 0) {
            let sumLat = 0
            let sumLng = 0
            let cnt = 0
            for (let coord of markerCoordinates) {
                sumLat += coord.lat
                sumLng += coord.lng
                cnt++
            }
            let meanLat = sumLat / cnt
            let meanLng = sumLng / cnt
            setSelectedCoordinates({
                coords: {
                    latitude: meanLat,
                    longitude: meanLng,
                },
            })
            return
        }
        if (biasGeoposition !== null) {
            setSelectedCoordinates(biasGeoposition)
        }
    }, [selectedCoordinates, markerCoordinates, biasGeoposition])

    return (
        <FlexCol>
            <Space.Compact style={{ width: "100%" }}>
                <AutoComplete
                    allowClear
                    style={{ width: "100%" }}
                    value={value}
                    onChange={(value) => {
                        if (value === "") {
                            setAddress("")
                            setSelectedCoordinates(null)
                        }
                        getPlacePredictionsOnChangeMutCB(value)
                        setValue(value)
                    }}
                    onSelect={(_, option) => {
                        console.log(`GooglePlaces: onSelect`, option)
                        setAddress(option.value)
                    }}
                    options={placePredictions.map((item) => ({
                        key: item.place_id,
                        value: item.description,
                    }))}
                />
                <Tooltip
                    title="Auto-detect address"
                    overlayInnerStyle={{ textAlign: "center" }}
                >
                    <Button
                        type={"default"}
                        onClick={() => {
                            biasGeopositionOnClickMutCB()
                        }}
                    >
                        <AimOutlined />
                    </Button>
                </Tooltip>
            </Space.Compact>
            {selectedCoordinates !== null && (
                <div
                    style={{
                        width: "100%",
                        height: "300px",
                    }}
                >
                    <GoogleMapsReactSignedIn
                        center={{
                            lat: selectedCoordinates.coords.latitude,
                            lng: selectedCoordinates.coords.longitude,
                        }}
                        defaultZoom={15}
                    >
                        <GoogleMapsMarker
                            lat={selectedCoordinates.coords.latitude}
                            lng={selectedCoordinates.coords.longitude}
                        />
                        {markerCoordinates &&
                            markerCoordinates.map((coord, index) => (
                                <GoogleMapsMarker
                                    key={index}
                                    lat={coord.lat}
                                    lng={coord.lng}
                                    fontSize="2rem"
                                    color={COLOR_BG_RED}
                                />
                            ))}
                    </GoogleMapsReactSignedIn>
                </div>
            )}
        </FlexCol>
    )
}
