import { WarningFilled, DoubleRightOutlined } from "@ant-design/icons"
import { Tooltip, Tag, Button, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import { EResultTagAdequacy } from "../../../components/commons-ts/common"
import { UUID, Zone } from "../../../generated/proto-ts/main"
import { useUsercommEquipmentChildrenRecursiveBLE } from "../../../usercomm/local/ble/usercommAsyncRequestBLE"
import { Translated } from "../../../utils/translated"
import { pbUUIDToUuid, COLOR_BG_ROW_DELETED } from "../../../utils/utils"
import {
    getCFH_Global,
    getZoneResultPF_CFH,
} from "../../../calculus/calculus_PF"
import { TableImageThumbnail } from "../../../components/commons-ts/tableImageThumbnail"
import { useUsercommEquipmentChildrenRecursiveBimodal } from "../../../usercomm/common/usercommAsyncRequestBimodal"

export const EquipmentZonesTableAB: FC<{
    equipmentUUID: UUID | null
    zones: Zone[] | null
}> = ({ equipmentUUID, zones }) => {
    const [zoneImpactsMap, getEntitiesRecursiveRequest] =
        useUsercommEquipmentChildrenRecursiveBimodal()

    useEffect(() => {
        getEntitiesRecursiveRequest(equipmentUUID)
    }, [equipmentUUID])

    const columns = useMemo((): ColumnsType<Zone> => {
        return [
            {
                title: "📷", // Photo
                render: (_, zone) => {
                    let pictureUUID = zone.pictures[0]
                    if (pictureUUID === undefined) {
                        return <div style={{ width: "3rem" }} />
                    }
                    return (
                        <Link to={`/zones/${pbUUIDToUuid(zone.uuid)}`}>
                            <TableImageThumbnail
                                pictureUUID={pictureUUID}
                                alt={zone.zone_name}
                            />
                        </Link>
                    )
                },
            },
            {
                key: "zone",
                title: "Zone",
                ellipsis: true,
                render: (_, zone) => {
                    return (
                        <Link
                            to={`/zones/${pbUUIDToUuid(zone.uuid)}`}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            {zone.zone_name}
                        </Link>
                    )
                },
            },
            {
                title: <Translated keyEn="# Impacts" />,
                render: (_, zone) => {
                    if (zoneImpactsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <span>0</span>
                    }
                    let nbImpacts = 0
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at === 0) {
                            nbImpacts++
                        }
                    }
                    return <span>{nbImpacts}</span>
                },
            },
            {
                key: "floorThickness",
                title: (
                    <Tooltip overlay={"Ground thickness in mm"}>
                        <Translated keyEn="Thickness" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    return <span>{zone.floor_thickness}</span>
                },
            },
            {
                key: "ffh",
                title: (
                    <Tooltip overlay={"Authorized Free Fall Height in m"}>
                        <Translated keyEn="FFH" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    let valueMeters = zone.zone_ffh_max / 100
                    return <span>{valueMeters.toFixed(2)}</span>
                },
            },
            // {
            //     key: "gmaxCFH",
            //     title: (
            //         <Tooltip
            //             overlay={translated(
            //                 "Critical Fall Height (calculated using Gmax) in m",
            //             )}
            //         >
            //             <Translated keyEn="CFHgmax" />
            //         </Tooltip>
            //     ),
            //     render: (_, zone) => {
            //         if (zone.impacts === undefined) {
            //             return null
            //         }
            //         let gmaxCFH = getCFH_Gmax(zone.impacts)
            //         if (gmaxCFH === null) {
            //             return null
            //         }
            //         return (
            //             <span>
            //                 {gmaxCFH.mean.toFixed(2)}
            //                 {gmaxCFH.count && `[${gmaxCFH.count}]`}
            //                 {gmaxCFH.count && gmaxCFH.count < 3 && (
            //                     <Tooltip
            //                         overlay={
            //                             <Translated keyEn="Not enough impacts (<3) to compute a reliable mean and standard deviation" />
            //                         }
            //                     >
            //                         <WarningFilled
            //                             style={{
            //                                 color: "orange",
            //                             }}
            //                         />
            //                     </Tooltip>
            //                 )}
            //             </span>
            //         )
            //     },
            // },
            // {
            //     key: "hicCFH",
            //     title: (
            //         <Tooltip
            //             overlay={translated(
            //                 "Critical Fall Height (calculated using HIC) in m",
            //             )}
            //         >
            //             <Translated keyEn="CFHhic" />
            //         </Tooltip>
            //     ),
            //     render: (_, zone) => {
            //         if (zone.impacts === undefined) {
            //             return null
            //         }
            //         let hicCFH = getCFH_HIC(zone.impacts)
            //         if (hicCFH === null) {
            //             return null
            //         }
            //         return (
            //             <span>
            //                 {hicCFH.mean.toFixed(2)}
            //                 {hicCFH.count && `[${hicCFH.count}]`}
            //                 {hicCFH.count && hicCFH.count < 3 && (
            //                     <Tooltip
            //                         overlay={
            //                             <Translated keyEn="Not enough impacts (<3) to compute a reliable mean and standard deviation" />
            //                         }
            //                     >
            //                         <WarningFilled
            //                             style={{
            //                                 color: "orange",
            //                             }}
            //                         />
            //                     </Tooltip>
            //                 )}
            //             </span>
            //         )
            //     },
            // },
            {
                key: "CFH",
                title: (
                    <Tooltip overlay={"Critical Fall Height in m"}>
                        <Translated keyEn="CFH" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    if (zoneImpactsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return null
                    }
                    if (zoneImpacts === undefined) {
                        return null
                    }
                    let cfh = getCFH_Global(zoneImpacts)
                    if (cfh === null) {
                        return null
                    }
                    let cfhMeanM = cfh.mean / 100
                    return (
                        <span>
                            {cfhMeanM.toFixed(1)}{" "}
                            {cfh.count && `[${cfh.count}]`}
                            {cfh.count && cfh.count < 3 && (
                                <Tooltip
                                    overlay={
                                        <Translated keyEn="Not enough impacts (<3) to compute a reliable mean and standard deviation" />
                                    }
                                >
                                    <WarningFilled
                                        style={{
                                            color: "orange",
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </span>
                    )
                },
            },
            {
                key: "result",
                title: (
                    <Tooltip overlay={<Translated keyEn="Result" />}>
                        <Translated keyEn="Result" />
                    </Tooltip>
                ),
                render: (_, zone) => {
                    if (zoneImpactsMap === null) {
                        return null
                    }
                    let zoneImpacts = zoneImpactsMap[pbUUIDToUuid(zone.uuid)]
                    if (zoneImpacts === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    let result = getZoneResultPF_CFH(zone, zoneImpacts)
                    if (result === null) {
                        return <Tag>N/A</Tag>
                    }
                    return <EResultTagAdequacy result={result.result} />
                },
            },
            {
                title: "",
                width: "2rem",
                render: (_, zone) => {
                    return (
                        <Link to={`/zones/${pbUUIDToUuid(zone.uuid)}`}>
                            <Button type="link" size="small">
                                <DoubleRightOutlined />
                            </Button>
                        </Link>
                    )
                },
            },
        ]
    }, [zones, zoneImpactsMap])

    return (
        <Table
            dataSource={zones ?? []}
            loading={zones === null || zoneImpactsMap === null}
            columns={columns}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            onRow={(zone) => {
                return {
                    style: {
                        backgroundColor:
                            zone.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                    },
                }
            }}
            footer={() => (
                <i>
                    <Translated keyEn="Table of zones for EN 14960 - AIRBAGS" />
                </i>
            )}
        />
    )
}
