import { FC, useCallback, useEffect, useRef, useState } from "react"
import {
    useUsercommBashCommandBLE,
    useUsercommGetStationStateBLE,
    useUsercommSitesBLE,
} from "../usercomm/local/ble/usercommAsyncRequestBLE"
import {
    FlexCol,
    FlexRow,
    UnderlinedSectionTitle,
} from "../components/commons-ts/common"
import {
    BashStdout,
    UCPayload,
    UCPayloadType,
} from "../generated/proto-ts/main"
import { useUsercommContextBLE } from "../usercomm/local/ble/usercommProviderBLE"
import { Button, Checkbox, Col, Divider, Input, Row } from "antd"
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite"
import { toastNotifyUpdateIsInstalled } from "../utils/toast"
import { MutexInterface, Mutex } from "async-mutex"
import {
    COLOR_BG_DARK_BLUE,
    COLOR_BG_LIGHT_BLUE,
    colorHexToRgba,
} from "../utils/utils"
import { Translated } from "../utils/translated"

const RemoteBashTerminal: FC = () => {
    const { recvMessageQueue, consumeRecvMessage } = useUsercommContextBLE()
    const [cmdAck, sendCmd] = useUsercommBashCommandBLE()
    const [chunks, setChunks] = useState<BashStdout[]>([])
    const [content, setContent] = useState<string>("")
    const [input, setInput] = useState<string>("")
    const [isInteractive, setIsInteractive] = useState<boolean>(false)

    const asyncMutexRef = useRef<MutexInterface>(new Mutex())

    useEffect(() => {
        sendCmd("", false)
    }, [])

    useEffect(() => {
        let _chunks = [...chunks]
        if (asyncMutexRef.current.isLocked()) {
            return
        }
        asyncMutexRef.current.acquire().then((release) => {
            for (let msg of recvMessageQueue) {
                if (msg.type === UCPayloadType.SOCI_BASH_STDOUT) {
                    consumeRecvMessage(msg.uuid)
                    let payload = BashStdout.deserializeBinary(msg.data)
                    _chunks.push(payload)
                }
            }
            release()
        })
        setChunks(_chunks)
    }, [recvMessageQueue])

    useEffect(() => {
        let _content = ""
        chunks.sort((a, b) => a.sequence - b.sequence)
        for (let chunk of chunks) {
            _content += chunk.stdout
        }
        // console.log(`Debug: RemoteBashTerminal: content:`, _content)
        _content += input + "█"
        setContent(_content)
    }, [chunks, input])

    return (
        <FlexCol>
            <Button
                onClick={() => {
                    setChunks([])
                    sendCmd("", isInteractive)
                }}
            >
                Clear
            </Button>
            <div
                style={{
                    backgroundColor: colorHexToRgba(COLOR_BG_LIGHT_BLUE, 0.1),
                    color: COLOR_BG_DARK_BLUE,
                    padding: "1rem",
                    height: "20rem",
                    overflowY: "auto",
                }}
            >
                <pre>{content}</pre>
            </div>
            <FlexRow>
                <Input
                    onPressEnter={async (e) => {
                        const cmd = e.currentTarget.value
                        setInput("")
                        sendCmd(cmd, isInteractive)
                        let _chunks = [...chunks]
                        let lastChunk = _chunks[_chunks.length - 1]
                        _chunks.push(
                            new BashStdout({
                                sequence: lastChunk.sequence + 0.1,
                                stdout: cmd + "\n",
                            }),
                        )
                        setChunks(_chunks)
                    }}
                    onChange={(e) => setInput(e.target.value)}
                    value={input}
                />
                <FlexCol
                    style={{
                        justifyContent: "center",
                        alignItems: "left",
                        gap: 3,
                    }}
                >
                    <span>Interactive</span>
                    <Checkbox
                        title="Interactive"
                        checked={isInteractive}
                        onChange={(e) => setIsInteractive(e.target.checked)}
                    />
                </FlexCol>
            </FlexRow>
        </FlexCol>
    )
}

export const DebugPage: FC = () => {
    const {
        recvMessageQueue,
        emitMessageQueue,
        bleDevice,
        bleIsConnected,
        addEmitMessage,
    } = useUsercommContextBLE()

    const [stationState, getStationState] = useUsercommGetStationStateBLE()

    const [sites, getSites] = useUsercommSitesBLE()
    const [siteObjects, setSiteObjects] = useState<any[]>([])

    const [targetDataBlobSizeKB, setTargetDataBlobSizeKB] = useState(12)
    const [emitThroughputKBps, setEmitThroughputKBps] = useState<number | null>(
        null,
    )
    const [emitElapsedTimeSec, setEmitElapsedTimeSec] = useState<number | null>(
        null,
    )
    const [emitDataSizeKB, setEmitDataSizeKB] = useState<number>(0)
    const [emitTs, setEmitTs] = useState<number | null>(null)

    const [swWaiting, setSwWaiting] = useState<ServiceWorker | null>(null)

    const onRequestSites = useCallback(() => {
        getSites()
    }, [])
    useEffect(() => {
        if (sites === null) {
            return
        }
        const siteObjects = sites.map((site) => site.toObject())
        setSiteObjects(siteObjects)
    }, [sites])

    const onEmitDataBlob = useCallback(() => {
        const data = new Uint8Array(targetDataBlobSizeKB * 1024)
        const payload = new UCPayload({
            type: UCPayloadType.SICO_ECHO_TEST,
            data: data,
        })
        addEmitMessage(payload)
        setEmitTs(Date.now())
        setEmitDataSizeKB(targetDataBlobSizeKB)
    }, [targetDataBlobSizeKB, emitMessageQueue])

    useEffect(() => {
        if (emitTs === null) {
            return
        }
        const elapsedSec = (Date.now() - emitTs) / 1024
        const emittedKB = emitDataSizeKB * 0
        const throughputKBps = emittedKB / elapsedSec
        // console.log(
        //     `DebugPage: EMIT throughputKBps: ${throughputKBps} (${emittedKB} KB in ${elapsedSec} sec) [${emitRatio.toFixed(2)}]`,
        // )
        setEmitThroughputKBps(throughputKBps)
        setEmitElapsedTimeSec(elapsedSec)
    }, [emitTs, emitDataSizeKB])

    return (
        <>
            <UnderlinedSectionTitle>
                <Translated keyEn="Debug" />
            </UnderlinedSectionTitle>
            <FlexCol>
                <h2>Remote Bash Terminal</h2>
                <RemoteBashTerminal />
                <Divider />
                <h2>BLE</h2>
                <Row gutter={[10, 10]}>
                    <Col xs={12}>
                        <FlexCol>
                            <h3>Sites</h3>
                            <JsonView
                                data={siteObjects}
                                shouldExpandNode={allExpanded}
                                style={defaultStyles}
                            />
                            <Button block onClick={() => onRequestSites()}>
                                Request Sites
                            </Button>
                        </FlexCol>
                    </Col>
                    <Col xs={12}>
                        <FlexCol>
                            <span>Emit Data</span>
                            <Input
                                style={{ width: "100%" }}
                                value={targetDataBlobSizeKB.toString()}
                                onChange={(e) => {
                                    let value = e.target.value
                                    setTargetDataBlobSizeKB(
                                        value ? parseInt(value) : 0,
                                    )
                                }}
                                suffix="kB"
                            />
                            <Button block onClick={() => onEmitDataBlob()}>
                                Emit Data
                            </Button>
                        </FlexCol>
                    </Col>
                    {/* <Col xs={12}>
                    <FlexCol>
                        <h3>MTU Test</h3>
                        <Button block onClick={() => emitMtuTest()}>
                            MTU Test
                        </Button>
                    </FlexCol>
                </Col> */}
                </Row>
                <Divider />
                <h2>Station</h2>
                <FlexCol>
                    <Button onClick={() => getStationState()}>
                        Get Station State
                    </Button>
                    <JsonView
                        data={
                            stationState === null ? {} : stationState.toObject()
                        }
                        shouldExpandNode={allExpanded}
                        style={defaultStyles}
                    />
                </FlexCol>
            </FlexCol>
        </>
    )
}
