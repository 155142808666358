import { StorageEntityType } from "./generated/proto-ts/main"

export interface ICloudError {
    Reason: string
}

export enum ECloudRole {
    SuperAdmin = "superadmin",
    Admin = "admin",
    User = "user",
}

export enum ECloudReleaseChannel {
    Beta = "beta",
    Prod = "prod",
}

interface ICloudUserRole {
    UUID: string
    Name: ECloudRole
}

interface ICloudUserReleaseChannel {
    UUID: string
    Name: ECloudReleaseChannel
}

export interface ICloudUser {
    UUID: string
    Username: string
    Password: string
    Email: string
    PhoneNumber: string
    LastName: string
    FirstName: string
    Enterprise: {
        UUID: string
        Name: string
    }
    Roles: ICloudUserRole[] | null
    ReleaseChannels: ICloudUserReleaseChannel[] | null
    PictureUUID: string | null
}

export interface ICloudBruteClass {
    ID: number
    KeyEn: string
    GmaxLower: number
    GmaxUpper: number
}
export interface ICloudBruteUser {
    UserUUID: string
    GmaxValue: number
    GmaxRank: number
    BruteRank: number
    GlobalGmaxRank: number
    GlobalBruteRank: number
    BruteClass: ICloudBruteClass
}

export interface ICloudDeviceType {
    UUID: string
    Name: string
    LaunchDate: string
    EndDate: string
    Description: string
}

export interface ICloudEnterprise {
    UUID: string
    Name: string
}

export interface ICloudDevice {
    UUID: string
    SerialNumber: string
    Name: string
    DeviceType: ICloudDeviceType | null
    Enterprise: ICloudEnterprise | null
}

export enum ECloudDeviceEventType {
    Unknown = 0,
    Revision = 1,
    SAV = 2,
    Metrology = 3,
    Lugnium = 4,
}

export enum ECloudDeviceEventState {
    Unknown = 0,
    Pending = 1,
    InProgress = 2,
    Completed = 3,
}

export interface ICloudDeviceEvent {
    UUID: string
    DeviceUUID: string
    ID: number
    EventType: ECloudDeviceEventType
    EventState: ECloudDeviceEventState
    StartDate: string | null
    EndDate: string | null
    Description: string
    Assignee: string
    Flags: number
    IsGmaxHigh: boolean
    Files: ICloudFile[]
}

export interface ICloudFile {
    UUID: string
    LegacyID: number
    Extension: string
    MimeType: string
    OriginalName: string
}

export interface ICloudTranslation {
    UUID: string | null
    Language: string
    KeyEN: string
    Value: string
    Description: string
}

export interface MeanWithStd {
    mean: number
    std: number
    count?: number
}

export interface IKinematicPoint {
    g: number
    a: number
    v: number
    d: number
    t: number
}

export interface IRawGenericStorageEntity {
    uuid: string
    parent_uuid: string | null
    entity_type: StorageEntityType
    created_at: number
    updated_at: number
    deleted_at: number
}

export enum ESyncState {
    ALREADY_SYNCED = 0,

    SYNCING_UP = 1,
    SYNC_UP_COMPLETED = 4,
    WONT_SYNC_UP = 5,

    SYNCING_DOWN = 2,
    SYNC_DOWN_COMPLETED = 3,
    WONT_SYNC_DOWN = 6,
}

export enum ESyncDisposition {
    LOCAL = 0,
    REMOTE = 1,
    BOTH = 2,
    UNKNOWN = 3,
}

export interface ISyncEntityLocationAndState {
    syncState: ESyncState
    isLocal: boolean
    isRemote: boolean
}

export interface ISyncAggregatedProgress {
    progressDown: number
    progressUp: number
    disposition: ESyncDisposition
}

export interface IImpactPoints {
    aq_points: number[]
    ax_points: number[]
    ay_points: number[]
    az_points: number[]
}

export interface ICoordinate {
    coords: { latitude: number; longitude: number }
}
