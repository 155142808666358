import {
    DeleteFilled,
    DoubleRightOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons"
import { Tooltip, Popconfirm, Button, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useMemo } from "react"
import { Link } from "react-router-dom"
import {
    EResultTagAdequacy,
    FlexRow,
} from "../../../components/commons-ts/common"
import { sportsMatTypeDescriptionStr } from "../../../components/commons-ts/tags"
import {
    Equipment,
    Impact,
    MatTypeEN12503,
} from "../../../generated/proto-ts/main"
import { Translated } from "../../../utils/translated"
import { pbUUIDToUuid, COLOR_BG_ROW_DELETED } from "../../../utils/utils"
import { getImpactResultSM } from "../../../calculus/calculus_SM"

export const ZoneImpactsTableSM: FC<{
    impacts: Impact[] | null
    equipment: Equipment | null
    currentImpactUUID?: string | null
    onDeleteImpact?: (impact: Impact | null) => void
}> = ({ impacts, equipment, currentImpactUUID, onDeleteImpact }) => {
    const memoSportsMatType = useMemo((): MatTypeEN12503 | null => {
        if (equipment === null) {
            return null
        }
        return equipment.sports_mat_type
    }, [equipment?.sports_mat_type])

    const memoSportsMatThicknessMean = useMemo((): number | null => {
        if (equipment === null) {
            return null
        }
        let thicknesses: number[] = []
        if (equipment.sports_mat_thickness_side_one !== 0) {
            thicknesses.push(equipment.sports_mat_thickness_side_one)
        }
        if (equipment.sports_mat_thickness_side_two !== 0) {
            thicknesses.push(equipment.sports_mat_thickness_side_two)
        }
        if (equipment.sports_mat_thickness_side_three !== 0) {
            thicknesses.push(equipment.sports_mat_thickness_side_three)
        }
        if (equipment.sports_mat_thickness_side_four !== 0) {
            thicknesses.push(equipment.sports_mat_thickness_side_four)
        }
        let mean = thicknesses.reduce((a, b) => a + b, 0) / thicknesses.length
        let std = Math.sqrt(
            thicknesses
                .map((x) => Math.pow(x - mean, 2))
                .reduce((a, b) => a + b, 0) / thicknesses.length,
        )
        if (isNaN(mean) || isNaN(std)) {
            return null
        }
        return mean
    }, [
        equipment?.sports_mat_thickness_side_one,
        equipment?.sports_mat_thickness_side_two,
        equipment?.sports_mat_thickness_side_three,
        equipment?.sports_mat_thickness_side_four,
    ])

    const columns: ColumnsType<Impact> = useMemo(() => {
        if (impacts === null) {
            return []
        }
        let impactIds = impacts.map((impact) => pbUUIDToUuid(impact.uuid))
        return [
            {
                title: "#",
                render: (_, impact) => {
                    return impactIds.indexOf(pbUUIDToUuid(impact.uuid)) + 1
                },
                // sorter: (a, b) => (a.id ?? 0) - (b.id ?? 0),
            },
            {
                key: "ffh",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Free Fall Height, m" />}
                    >
                        <div>
                            <Translated keyEn="Height, m" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (impact.impact_ffh === 0) {
                        return null
                    }
                    return <span>{impact.impact_ffh.toFixed(2)}</span>
                },
                sorter: (a, b) => a.impact_ffh - b.impact_ffh,
                defaultSortOrder: "ascend",
            },
            {
                key: "gmax",
                title: <Translated keyEn="Gmax" />,
                render: (_, impact) => {
                    if (impact.impact_gmax === 0) {
                        return "N/A"
                    } else {
                        return <span>{impact.impact_gmax.toFixed(1)}</span>
                    }
                },
                sorter: (a, b) => a.impact_gmax - b.impact_gmax,
            },
            {
                key: "deflectionDistance",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Deflection Distance" />}
                    >
                        <div>
                            <Translated keyEn="Def. mm" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (impact.impact_deflection === 0) {
                        return "N/A"
                    } else {
                        let impactDeflectionMM = impact.impact_deflection * 1e3
                        return <span>{impactDeflectionMM.toFixed(1)}</span>
                    }
                },
            },
            {
                key: "deflectionPercentage",
                title: (
                    <Tooltip overlay={<Translated keyEn="Deflection %" />}>
                        <div>
                            <Translated keyEn="Def. %" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (
                        impact.impact_deflection === 0 ||
                        memoSportsMatThicknessMean === null
                    ) {
                        return "N/A"
                    } else {
                        let impactDeflectionPerc =
                            (impact.impact_deflection /
                                memoSportsMatThicknessMean) *
                            100
                        return <span>{impactDeflectionPerc.toFixed(0)}</span>
                    }
                },
            },
            {
                key: "resilience",
                title: (
                    <Tooltip overlay={<Translated keyEn="Resilience, %" />}>
                        <div>
                            <Translated keyEn="Res. %" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (impact.impact_resilience === null) {
                        return "N/A"
                    } else {
                        let resiliencePerc = impact.impact_resilience * 100
                        return <span>{resiliencePerc.toFixed(0)}</span>
                    }
                },
            },
            {
                key: "timestamp",
                title: <Translated keyEn="Date" />,
                render: (_, impact) => {
                    if (impact.created_at === 0) {
                        return null
                    }
                    return new Date(impact.created_at).toLocaleString()
                },
                sorter: (a, b) => a.created_at - b.created_at,
                defaultSortOrder: "descend",
            },
            {
                key: "result",
                title: <Translated keyEn="Result" />,
                render: (_, impact) => {
                    if (equipment === null) {
                        return null
                    }
                    let result = getImpactResultSM(
                        impact,
                        memoSportsMatType,
                        memoSportsMatThicknessMean,
                    )
                    return <EResultTagAdequacy result={result} />
                },
            },
            {
                title: "",
                width: "2rem",
                render: (_, impact) => {
                    return (
                        <FlexRow style={{ gap: 0 }}>
                            {onDeleteImpact !== undefined && (
                                <Popconfirm
                                    title={
                                        <>
                                            <Translated keyEn="Are you sure you want to delete this impact" />
                                            ?
                                        </>
                                    }
                                    onConfirm={() => {
                                        onDeleteImpact(impact)
                                    }}
                                >
                                    <Button
                                        danger
                                        type="link"
                                        size="small"
                                        icon={<DeleteFilled />}
                                    />
                                </Popconfirm>
                            )}
                            {currentImpactUUID === undefined ||
                            currentImpactUUID !== pbUUIDToUuid(impact.uuid) ? (
                                <Link
                                    to={`/impacts/${pbUUIDToUuid(impact.uuid)}`}
                                >
                                    <Button type="link" size="small">
                                        <DoubleRightOutlined />
                                    </Button>
                                </Link>
                            ) : (
                                <FlexRow
                                    style={{
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CheckCircleOutlined />
                                </FlexRow>
                            )}
                        </FlexRow>
                    )
                },
            },
        ]
    }, [
        impacts,
        currentImpactUUID,
        memoSportsMatType,
        memoSportsMatThicknessMean,
    ])

    if (memoSportsMatType === null) {
        return null
    }

    return (
        <Table
            dataSource={impacts ?? []}
            loading={impacts === null}
            columns={columns}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            style={{
                width: "100%",
            }}
            onRow={(impact) => {
                return {
                    style: {
                        backgroundColor:
                            impact.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                        fontWeight:
                            pbUUIDToUuid(impact.uuid) === currentImpactUUID
                                ? 700
                                : 400,
                    },
                }
            }}
            footer={() => {
                return (
                    <i>
                        <Translated keyEn="Table of impacts: Sports Mat" />{" "}
                        <b>
                            Type {memoSportsMatType}:{" "}
                            {sportsMatTypeDescriptionStr(memoSportsMatType)}
                        </b>
                    </i>
                )
            }}
        />
    )
}
