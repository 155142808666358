import { Impact, Zone } from "../generated/proto-ts/main"
import { MeanWithStd } from "../types"
import { Point, findParabolicAandC } from "./goldenSectionSearch"
import { pbUUIDToUuid } from "../utils/utils"
import { solveQuadraticEquation } from "./common"
import {
    EResult,
    IZoneResult,
    IEquipmentResult,
    EN_1177_TARGET_GMAX,
    EN_1177_TARGET_HIC,
    EN_1177_TOLERANCE_HIC,
    EN_1177_TOLERANCE_GMAX,
} from "./types"

// PlaygroundFloor - Adequacy
export const getGmaxPFAdq = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length === 0) {
        return null
    }
    let gmaxValues: number[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_gmax !== 0) {
            gmaxValues.push(impact.impact_gmax)
        }
    }
    gmaxValues.sort((a, b) => b - a)
    gmaxValues = gmaxValues.slice(0, 3)
    let count = gmaxValues.length
    let meanGmax = gmaxValues.reduce((a, b) => a + b) / count
    let stdGmax = Math.sqrt(
        gmaxValues
            .map((x) => Math.pow(x - meanGmax, 2))
            .reduce((a, b) => a + b, 0) / count,
    )
    return {
        mean: meanGmax,
        std: stdGmax,
        count,
    }
}
export const getHicPFAdq = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length === 0) {
        return null
    }
    let hicValues: number[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_hic !== 0) {
            hicValues.push(impact.impact_hic)
        }
    }
    hicValues.sort((a, b) => b - a)
    hicValues = hicValues.slice(0, 3)
    let count = hicValues.length
    let meanHic = hicValues.reduce((a, b) => a + b, 0) / count
    let stdHic = Math.sqrt(
        hicValues
            .map((x) => Math.pow(x - meanHic, 2))
            .reduce((a, b) => a + b, 0) / count,
    )
    return {
        mean: meanHic,
        std: stdHic,
        count,
    }
}
export const getImpactResultPFAdq = (impact: Impact): EResult => {
    let resultHic = EResult.NotAdequate
    if (impact.impact_hic < EN_1177_TARGET_HIC - EN_1177_TOLERANCE_HIC) {
        resultHic = EResult.Adequate
    } else if (impact.impact_hic > EN_1177_TARGET_HIC + EN_1177_TOLERANCE_HIC) {
        resultHic = EResult.NotAdequate
    } else {
        resultHic = EResult.ToReview
    }

    let resultGmax = EResult.NotAdequate
    if (impact.impact_gmax < EN_1177_TARGET_GMAX) {
        resultGmax = EResult.Adequate
    } else if (impact.impact_gmax > EN_1177_TARGET_GMAX) {
        resultGmax = EResult.NotAdequate
    } else {
        resultGmax = EResult.ToReview
    }

    let resultGlobal = Math.min(resultHic, resultGmax)

    return resultGlobal
}
export const getZoneResultPFAdq = (impacts: Impact[]): IZoneResult | null => {
    if (impacts === undefined || impacts.length === 0) {
        return null
    }

    let gmaxAdq = getGmaxPFAdq(impacts)
    if (gmaxAdq === null) {
        return null
    }
    let hicAdq = getHicPFAdq(impacts)
    if (hicAdq === null) {
        return null
    }

    let resultHic = EResult.NotAdequate
    if (hicAdq.mean + hicAdq.std < EN_1177_TARGET_HIC - EN_1177_TOLERANCE_HIC) {
        resultHic = EResult.Adequate
    } else if (
        hicAdq.mean + hicAdq.std >
        EN_1177_TARGET_HIC + EN_1177_TOLERANCE_HIC
    ) {
        resultHic = EResult.NotAdequate
    } else {
        resultHic = EResult.ToReview
    }

    let resultGmax = EResult.NotAdequate
    if (
        gmaxAdq.mean + gmaxAdq.std <
        EN_1177_TARGET_GMAX - EN_1177_TOLERANCE_GMAX
    ) {
        resultGmax = EResult.Adequate
    } else if (
        gmaxAdq.mean + gmaxAdq.std >
        EN_1177_TARGET_GMAX + EN_1177_TOLERANCE_GMAX
    ) {
        resultGmax = EResult.NotAdequate
    } else {
        resultGmax = EResult.ToReview
    }

    let resultGlobal = Math.min(resultHic, resultGmax)

    return {
        result: resultGlobal,
        gmax: gmaxAdq,
        hic: hicAdq,
        cfh: null,
        deflectionDistanceMM: null,
        deflectionDistancePerc: null,
        resiliencePerc: null,
        secondaryBounceHeightM: null,
        performanceFactor: null,
    }
}
export const getEquipmentResultPFAdq = (
    zones: Zone[],
    zoneImpactsMap: Record<string, Impact[]>,
): IEquipmentResult | null => {
    if (zones.length === 0) {
        return null
    }
    let result = EResult.Adequate
    for (let zone of zones) {
        if (zone.deleted_at !== null) {
            continue
        }
        let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
        let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
        if (zoneImpacts === undefined) {
            continue
        }
        let zoneResult = getZoneResultPFAdq(zoneImpacts)
        if (zoneResult === null) {
            continue
        }
        if (zoneResult.result === EResult.NotAdequate) {
            result = EResult.NotAdequate
            break
        }
        if (zoneResult.result === EResult.ToReview) {
            result = EResult.ToReview
        }
    }
    return {
        result,
    }
}

// PlaygroundFloor - CFH
export const getCFH_Gmax = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length < 2) {
        return null
    }
    let effectiveImpacts: Impact[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_gmax !== 0) {
            effectiveImpacts.push(impact)
        }
    }
    let count = effectiveImpacts.length
    let heightGmaxPoints: Point[] = []
    for (let impact of effectiveImpacts) {
        heightGmaxPoints.push([impact.impact_ffh, impact.impact_gmax])
    }
    let { equation } = findParabolicAandC(heightGmaxPoints)
    let [_, cfh] = solveQuadraticEquation(equation, EN_1177_TARGET_GMAX)
    if (cfh === null) {
        return null
    }
    return {
        mean: cfh,
        std: 0,
        count,
    }
}
export const getCFH_HIC = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length < 2) {
        return null
    }
    let effectiveImpacts: Impact[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_hic !== 0) {
            effectiveImpacts.push(impact)
        }
    }
    let count = effectiveImpacts.length
    let heightHICPoints: Point[] = []
    for (let impact of effectiveImpacts) {
        heightHICPoints.push([impact.impact_ffh, impact.impact_hic])
    }
    let { equation } = findParabolicAandC(heightHICPoints)
    let [_, cfh] = solveQuadraticEquation(equation, EN_1177_TARGET_HIC)
    if (cfh === null) {
        return null
    }
    return {
        mean: cfh,
        std: 0,
        count,
    }
}
export const getCFH_Global = (impacts: Impact[]): MeanWithStd | null => {
    if (impacts.length < 2) {
        return null
    }
    let effectiveImpacts: Impact[] = []
    for (let impact of impacts) {
        if (impact.deleted_at !== 0) {
            continue
        }
        if (impact.impact_gmax !== 0 && impact.impact_hic !== 0) {
            effectiveImpacts.push(impact)
        }
    }
    let count = effectiveImpacts.length
    // Gmax
    let gmaxHeightPoints: Point[] = []
    for (let impact of effectiveImpacts) {
        gmaxHeightPoints.push([impact.impact_ffh, impact.impact_gmax])
    }
    let { equation: gmaxEquation } = findParabolicAandC(gmaxHeightPoints)
    let [_, gmaxCFH] = solveQuadraticEquation(gmaxEquation, EN_1177_TARGET_GMAX)

    // HIC
    let hicHeightPoints: Point[] = []
    for (let impact of effectiveImpacts) {
        hicHeightPoints.push([impact.impact_ffh, impact.impact_hic])
    }
    let { equation: hicEquation } = findParabolicAandC(hicHeightPoints)
    let [__, hicCFH] = solveQuadraticEquation(hicEquation, EN_1177_TARGET_HIC)

    if (gmaxCFH === null || hicCFH === null) {
        return null
    }
    if (gmaxCFH !== null && hicCFH !== null) {
        let cfh = Math.min(gmaxCFH, hicCFH)
        return {
            mean: cfh,
            std: 0,
            count,
        }
    } else if (gmaxCFH !== null) {
        return {
            mean: gmaxCFH,
            std: 0,
            count,
        }
    } else if (hicCFH !== null) {
        return {
            mean: hicCFH,
            std: 0,
            count,
        }
    }
    return null
}

export const getZoneResultPF_CFH = (
    zone: Zone,
    impacts: Impact[],
): IZoneResult | null => {
    if (impacts.length === 0 || zone.zone_ffh_max === 0) {
        return null
    }
    const targetFFHmetres = zone.zone_ffh_max / 100

    let globalCFH = getCFH_Global(impacts)
    if (globalCFH === null) {
        return null
    }

    let resultGlobal = EResult.ToReview
    if (globalCFH.mean <= targetFFHmetres) {
        resultGlobal = EResult.Adequate
    } else {
        resultGlobal = EResult.NotAdequate
    }

    return {
        result: resultGlobal,
        gmax: null,
        hic: null,
        cfh: globalCFH,
        deflectionDistanceMM: null,
        deflectionDistancePerc: null,
        resiliencePerc: null,
        secondaryBounceHeightM: null,
        performanceFactor: null,
    }
}
