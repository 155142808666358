import { FC, ReactElement, useEffect, useMemo, useState } from "react"
import { useUsercommContextBLE } from "../../usercomm/local/ble/usercommProviderBLE"
import { FlexCol, FlexRow } from "./common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faArrowsAlt,
    faArrowsAltV,
    faArrowsUpDown,
    faDroplet,
    faLocationDot,
    faSatellite,
    faSatelliteDish,
    faTemperatureHalf,
    faThermometer,
} from "@fortawesome/free-solid-svg-icons"
import {
    COLOR_BG_BLUE,
    COLOR_BG_DARK,
    COLOR_BG_GRAY,
    COLOR_BG_GREEN,
    COLOR_BG_LIGHT_BLUE,
    colorHexToRgba,
} from "../../utils/utils"
import { CaretLeftFilled, ColumnHeightOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import { Link } from "react-router-dom"
import { Label } from "./input"
import { Translated } from "../../utils/translated"

const DeviceSensorModal_Lidar: FC<{
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}> = ({ isOpen, setIsOpen }) => {
    const { stationSensors } = useUsercommContextBLE()

    const memoValueStr = useMemo(() => {
        if (stationSensors === null) {
            return "N/A"
        }
        return stationSensors.lidar_distance.toFixed(0) + " cm"
    }, [stationSensors?.lidar_distance])

    return (
        <Modal
            title={
                <div style={{ fontSize: "2rem" }}>
                    <Label>
                        <Translated keyEn="Lidar Distance" />
                    </Label>
                </div>
            }
            width={"90vw"}
            open={isOpen}
            onOk={() => setIsOpen(false)}
            onCancel={() => setIsOpen(false)}
            footer={null}
        >
            <FlexRow
                style={{
                    gap: "6rem",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "60vh",
                }}
            >
                <div style={{ fontSize: "14rem" }}>
                    {
                        <ColumnHeightOutlined
                            style={{
                                fontSize: "14rem",
                            }}
                        />
                    }
                </div>
                <div
                    style={{
                        fontSize: "8rem",
                    }}
                >
                    {memoValueStr}
                </div>
            </FlexRow>
        </Modal>
    )
}

export const DeviceSensorsWidgetBLE: FC = () => {
    const { stationSensors } = useUsercommContextBLE()
    const [temperatureString, setTemperatureString] = useState<string>("-")
    const [humidityString, setHumidityString] = useState<string>("-")
    const [lidarDistanceString, setLidarDistanceString] = useState<string>("-")
    const [isGnssAvailable, setIsGnssAvailable] = useState<boolean>(false)
    const [gnssCoordinatesString, setGnssCoordinatesString] =
        useState<string>("-")

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

    useEffect(() => {
        if (stationSensors === null) {
            return
        }
        const temperature = stationSensors.temperature
        setTemperatureString(temperature.toFixed(0))
        const humidity = stationSensors.relative_humidity
        setHumidityString(humidity.toFixed(0))
        const lidarDistance = stationSensors.lidar_distance
        setLidarDistanceString(lidarDistance.toFixed(0))

        if (
            (stationSensors.gnss_lon === undefined ||
                stationSensors.gnss_lon === 0) &&
            (stationSensors.gnss_lat === undefined ||
                stationSensors.gnss_lat === 0)
        ) {
            setIsGnssAvailable(false)
            setGnssCoordinatesString("N/C")
        } else {
            setIsGnssAvailable(true)
            const gnssCoordinates = `${stationSensors.gnss_lat.toFixed(1)}/${stationSensors.gnss_lon.toFixed(1)}`
            setGnssCoordinatesString(gnssCoordinates)
        }
    }, [stationSensors])

    const iconFontSize = "1.5rem"
    const subscriptFontSize = "0.8rem"

    return (
        <>
            <DeviceSensorModal_Lidar
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
            <div
                style={{
                    width: isCollapsed ? 105 : 250,
                    paddingLeft: 25,
                    paddingTop: 7,
                    paddingBottom: 7,
                    backgroundColor: colorHexToRgba(COLOR_BG_LIGHT_BLUE, 0.2),
                    borderRadius: 50,
                    transition: "width 0.3s",
                }}
            >
                <div
                    style={{
                        position: "relative",
                    }}
                >
                    <FlexRow
                        style={{
                            justifyContent: "start",
                            overflow: "hidden",
                            width: "calc(100% - 45px)",
                        }}
                    >
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsModalOpen(true)
                            }}
                        >
                            <FlexCol
                                style={{
                                    gap: 2,
                                    minWidth: "2rem",
                                    alignItems: "center",
                                }}
                            >
                                <ColumnHeightOutlined
                                    style={{
                                        fontSize: iconFontSize,
                                        color: COLOR_BG_DARK,
                                    }}
                                />
                                <span style={{ fontSize: subscriptFontSize }}>
                                    {lidarDistanceString}cm
                                </span>
                            </FlexCol>
                        </div>
                        <FlexCol
                            style={{
                                gap: 2,
                                minWidth: "2rem",
                                alignItems: "center",
                            }}
                        >
                            <Link to="/settings/device#gnss">
                                <FontAwesomeIcon
                                    icon={faSatelliteDish}
                                    fontSize={iconFontSize}
                                    color={
                                        isGnssAvailable
                                            ? COLOR_BG_GREEN
                                            : COLOR_BG_GRAY
                                    }
                                />
                            </Link>
                            <span style={{ fontSize: subscriptFontSize }}>
                                {gnssCoordinatesString}
                            </span>
                        </FlexCol>
                        <FlexCol
                            style={{
                                gap: 2,
                                minWidth: "2rem",
                                alignItems: "center",
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faTemperatureHalf}
                                fontSize={iconFontSize}
                                color={COLOR_BG_GREEN}
                            />
                            <span style={{ fontSize: subscriptFontSize }}>
                                {temperatureString}&#8451;
                            </span>
                        </FlexCol>
                        <FlexCol
                            style={{
                                gap: 2,
                                minWidth: "2rem",
                                alignItems: "center",
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faDroplet}
                                fontSize={iconFontSize}
                                color={COLOR_BG_BLUE}
                            />
                            <span style={{ fontSize: subscriptFontSize }}>
                                {humidityString}%
                            </span>
                        </FlexCol>
                    </FlexRow>
                    <div
                        style={{
                            backgroundColor: colorHexToRgba(
                                COLOR_BG_LIGHT_BLUE,
                                0.8,
                            ),
                            transform: isCollapsed
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            transition: "transform 0.5s",
                            position: "absolute",
                            right: 0,
                            top: -2,
                            cursor: "pointer",
                            marginRight: 5,
                            borderRadius: "100%",
                            width: 45,
                            height: 45,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={() => {
                            setIsCollapsed(!isCollapsed)
                        }}
                    >
                        <CaretLeftFilled
                            style={{
                                fontSize: "2rem",
                                color: COLOR_BG_BLUE,
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
