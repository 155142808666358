import { createContext } from "react"
import { UCPayload } from "../../generated/proto-ts/main"
import { MAX_MESSAGE_QUEUE_SIZE, Action } from "../common/usercommUtils"

interface ICloudUsercommContextProviderState {
    socket: WebSocket | undefined
    socketIsConnected: boolean

    recvMessageQueue: UCPayload[]
    emitMessageQueue: UCPayload[]
}

export interface ICloudUsercommContextProviderContextType
    extends ICloudUsercommContextProviderState {
    setSocketIsConnected: (isConnected: boolean) => void

    addEmitMessage: (payload: UCPayload) => void
    consumeRecvMessage: (uuid: UCPayload["uuid"]) => void
}

export const cloudUsercommStateReducer = (
    state: ICloudUsercommContextProviderState,
    action: Action,
): ICloudUsercommContextProviderState => {
    switch (action.type) {
        case "SET_SOCKET":
            return {
                ...state,
                socket: action.payload,
            } satisfies typeof state
        case "SET_SOCKET_IS_CONNECTED":
            return {
                ...state,
                socketIsConnected: action.payload,
            } satisfies typeof state
        case "ADD_MESSAGE_TO_RECV_QUEUE":
            let newMessageQueue = [...state.recvMessageQueue, action.payload]
            if (newMessageQueue.length > MAX_MESSAGE_QUEUE_SIZE) {
                newMessageQueue.shift()
            }
            return {
                ...state,
                recvMessageQueue: newMessageQueue,
            } satisfies typeof state
        case "CONSUME_MESSAGE_FROM_RECV_QUEUE":
            const messageUuid = action.payload
            const updatedMessages = state.recvMessageQueue.filter(
                (message) => message.uuid !== messageUuid,
            )
            return {
                ...state,
                recvMessageQueue: updatedMessages,
            } satisfies typeof state
        case "ADD_MESSAGE_TO_EMIT_QUEUE":
            let newEmitMessageQueue = [
                ...state.emitMessageQueue,
                action.payload,
            ]
            if (newEmitMessageQueue.length > MAX_MESSAGE_QUEUE_SIZE) {
                newEmitMessageQueue.shift()
            }
            return {
                ...state,
                emitMessageQueue: newEmitMessageQueue,
            } satisfies typeof state
        case "CONSUME_MESSAGE_FROM_EMIT_QUEUE":
            const emitMessageUuid = action.payload
            const updatedEmitMessages = state.emitMessageQueue.filter(
                (message) => message.uuid !== emitMessageUuid,
            )
            return {
                ...state,
                emitMessageQueue: updatedEmitMessages,
            } satisfies typeof state
        default:
            console.warn(`usercommStateReducer: unknown action type:`, action)
            return state
    }
}

export const cloudUsercommContext = createContext<
    ICloudUsercommContextProviderContextType | undefined
>(undefined)
