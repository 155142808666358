export interface IPApiResult {
    ip: string
    latitude: number
    longitude: number
    country_code: string
}

export const getIPLocation = async (): Promise<IPApiResult> => {
    let resp = await fetch(`https://ipapi.co/json/`)
    if (!resp.ok) {
        throw new Error(`resp nok: ${resp.statusText}`)
    }
    return (await resp.json()) as IPApiResult
}
