import { debounce } from "lodash"
import { useRef, useEffect, useMemo } from "react"
import { ENTITY_SAVE_DEBOUNCE_DELAY_MS } from "./utils"

export const useDebounce = <T extends () => void>(callback: T) => {
    const ref = useRef<T>()

    useEffect(() => {
        ref.current = callback
    }, [callback])

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.()
        }
        return debounce(func, ENTITY_SAVE_DEBOUNCE_DELAY_MS)
    }, [])

    return debouncedCallback
}
