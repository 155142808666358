import { MethodTypeEN1177 } from "../generated/proto-ts/main"

// USER
export const loadLocalStorageUser = (): string | null => {
    let user = localStorage.getItem("normenjeu-user")
    if (user === null) {
        user = localStorage.getItem("x-user")
        if (user !== null) {
            localStorage.setItem("normenjeu-user", user)
        }
    }
    localStorage.removeItem("x-user")
    return user
}

export const saveLocalStorageUser = (user: string | null) => {
    if (user === null) {
        localStorage.removeItem("normenjeu-user")
        return
    }
    localStorage.setItem("normenjeu-user", user)
}

// PREFERENCES
export const loadLocalStorageDefaultSiteMethodType = (): MethodTypeEN1177 => {
    let persistentDefaultmethodStr = localStorage.getItem(
        "normenjeu-preferences-default-method",
    )
    if (persistentDefaultmethodStr) {
        let persistentDefaultmethodInt = parseInt(persistentDefaultmethodStr)
        if (!isNaN(persistentDefaultmethodInt)) {
            return persistentDefaultmethodInt as MethodTypeEN1177
        }
    }
    return MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
}
export const saveLocalStorageDefaultSiteMethodType = (
    defaultMethod: MethodTypeEN1177,
) => {
    localStorage.setItem(
        "normenjeu-preferences-default-method",
        defaultMethod.toString(),
    )
}

// REPORT
// LEFT LOGO
export const loadLocalStorageReportLeftLogoDataURL = (): string | null => {
    return localStorage.getItem("normenjeu-report-left-logo-data-url")
}

export const saveLocalStorageReportLeftLogoDataURL = (
    dataURL: string | null,
) => {
    if (dataURL === null) {
        localStorage.removeItem("normenjeu-report-left-logo-data-url")
        return
    }
    localStorage.setItem("normenjeu-report-left-logo-data-url", dataURL)
}

// LEFT LOGO
export const loadLocalStorageReportRightLogoDataURL = (): string | null => {
    return localStorage.getItem("normenjeu-report-right-logo-data-url")
}

export const saveLocalStorageReportRightLogoDataURL = (
    dataURL: string | null,
) => {
    if (dataURL === null) {
        localStorage.removeItem("normenjeu-report-right-logo-data-url")
        return
    }
    localStorage.setItem("normenjeu-report-right-logo-data-url", dataURL)
}

// LEFT FOOTER TEXT
export const loadLocalStorageReportLeftFooterText = (): string | null => {
    return localStorage.getItem("normenjeu-report-left-footer-text")
}

export const saveLocalStorageReportLeftFooterText = (text: string | null) => {
    if (text === null) {
        localStorage.removeItem("normenjeu-report-left-footer-text")
        return
    }
    localStorage.setItem("normenjeu-report-left-footer-text", text)
}

// RIGHT FOOTER TEXT
export const loadLocalStorageReportRightFooterText = (): string | null => {
    return localStorage.getItem("normenjeu-report-right-footer-text")
}

export const saveLocalStorageReportRightFooterText = (text: string | null) => {
    if (text === null) {
        localStorage.removeItem("normenjeu-report-right-footer-text")
        return
    }
    localStorage.setItem("normenjeu-report-right-footer-text", text)
}

// Translations
// LanguageCode
export const loadLocalStorageLanguageCode = (): string => {
    let languageCode = localStorage.getItem("normenjeu-language-code")
    if (languageCode === null) {
        languageCode = "en"
    }
    return languageCode
}

export const saveLocalStorageLanguageCode = (languageCode: string | null) => {
    if (languageCode === null) {
        localStorage.removeItem("normenjeu-language-code")
        return
    }
    localStorage.setItem("normenjeu-language-code", languageCode)
}
