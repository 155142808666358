import { FC, useCallback, useEffect, useMemo } from "react"
import {
    useUsercommGNSSUbloxGetALPFilesBLE,
    useUsercommGNSSUbloxGetStateBLE,
    useUsercommGNSSUbloxResetBLE,
    useUsercommGNSSUbloxSetALPFileBLE,
} from "../../usercomm/local/ble/usercommAsyncRequestBLE"
import { LockOutlined, ReloadOutlined, UnlockOutlined } from "@ant-design/icons"
import { Typography, Button, Row, Col, Tag, message as antdMessage } from "antd"
import {
    FlexCol,
    FlexRow,
    UnderlinedSectionTitle,
} from "../../components/commons-ts/common"
import { Translated } from "../../utils/translated"
import {
    faSatellite,
    faSatelliteDish,
    faWifi,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { COLOR_BG_GRAY, COLOR_BG_GREEN, COLOR_BG_RED } from "../../utils/utils"
import { GNSSUbloxSetALPFileRequest } from "../../generated/proto-ts/main"

export const decimalCoordinateToDegreesMinutesSeconds = (
    decimalCoordinates: number,
) => {
    const degrees = Math.floor(decimalCoordinates)
    const minutes = Math.floor((decimalCoordinates - degrees) * 60)
    const seconds = Math.floor(
        (decimalCoordinates - degrees - minutes / 60) * 3600,
    )
    return `${degrees}° ${minutes}' ${seconds}''`
}

enum EResetType {
    Cold = 0,
    Warm = 1,
    Full = 2,
}

export const GNSSUbloxSettings: FC = () => {
    const [gnssState, getGnssState] = useUsercommGNSSUbloxGetStateBLE()
    const [alpFiles, getAlpFiles] = useUsercommGNSSUbloxGetALPFilesBLE()
    const [setAlpFileAck, setAlpFile] = useUsercommGNSSUbloxSetALPFileBLE()
    const [resetAck, reset] = useUsercommGNSSUbloxResetBLE()

    useEffect(() => {
        getGnssState()
        getAlpFiles()
        let t = setInterval(() => {
            getGnssState()
        }, 3000)
        return () => {
            clearInterval(t)
        }
    }, [])

    useEffect(() => {
        if (gnssState === null) {
            return
        }
        // console.log(
        //     `GNSSSettingsPage: received gnssUbloxState:`,
        //     gnssUbloxState.toObject(),
        // )
    }, [gnssState])

    useEffect(() => {
        if (resetAck === null) {
            return
        }
        antdMessage.success(`GNSS restarted!`)
    }, [resetAck])

    const fetchAndSetALPFile = useCallback(async () => {
        let currentDays = 0
        if (alpFiles !== null && alpFiles.keys.length > 0) {
            alpFiles.keys.sort()
            for (let alpFileKey of alpFiles.keys) {
                let re = /(\d+)_(\d+)d.alp/
                let match = re.exec(alpFileKey)
                if (match === null) {
                    continue
                }
                currentDays = parseInt(match[2])
            }
        }
        let targetDays = 1
        switch (currentDays) {
            case 0:
                targetDays = 1
                break
            case 1:
                targetDays = 2
                break
            case 2:
                targetDays = 3
                break
            case 3:
                targetDays = 5
                break
            case 5:
                targetDays = 7
                break
            case 7:
                targetDays = 10
                break
            case 10:
                targetDays = 14
                break
            default:
                targetDays = 1
                break
        }
        let resp = await fetch(
            `/api/relay?url=http://alp.u-blox.com/current_${targetDays}d.alp`,
        )
        let lastModifiedStr = resp.headers.get("Last-Modified")
        let lastModifiedIntMs = 0
        if (lastModifiedStr !== null) {
            lastModifiedIntMs = new Date(lastModifiedStr).getTime()
        }
        if (lastModifiedIntMs === 0) {
            console.error("GNSSSettingsPage: fetchALPFile: lastModified is 0")
            return
        }
        let lastModifiedIntSec = Math.floor(lastModifiedIntMs / 1000)
        let data = await resp.arrayBuffer()
        let alpFileRequest = new GNSSUbloxSetALPFileRequest({
            key: `${lastModifiedIntSec}_${targetDays}d.alp`,
            data: new Uint8Array(data),
            offset: 0,
        })
        console.log(
            `GNSSSettingsPage: fetchALPFile: data byteLength: ${data.byteLength}; lastModified: ${lastModifiedIntMs}; alpFile object:`,
            alpFileRequest.toObject(),
        )
        setAlpFile(alpFileRequest)
    }, [alpFiles])
    useEffect(() => {
        if (setAlpFileAck === null) {
            return
        }
        console.log(`GNSSSettingsPage: setALPFileAck:`, setAlpFileAck)
        getAlpFiles()
        antdMessage.success(`ALP file sent!`)
    }, [setAlpFileAck])

    const memoGNSSStatusWidget = useMemo(() => {
        if (gnssState === null) {
            return
        }

        let color = COLOR_BG_GRAY
        if (gnssState.quality > 0 && gnssState.nav_mode > 1) {
            color = COLOR_BG_GREEN
        } else {
            color = COLOR_BG_RED
        }

        let latLonElement = <Tag>N/A</Tag>
        if (gnssState.lon !== 0 && gnssState.lat !== 0) {
            let latDirectionStr = gnssState.lat > 0 ? "N" : "S"
            let lonDirectionStr = gnssState.lon > 0 ? "E" : "W"
            const gnssCoordinates = `${decimalCoordinateToDegreesMinutesSeconds(gnssState.lat)} ${latDirectionStr} ${decimalCoordinateToDegreesMinutesSeconds(gnssState.lon)} ${lonDirectionStr}`
            latLonElement = <Tag>{gnssCoordinates}</Tag>
        }

        let altElement = <Tag>N/A</Tag>
        if (gnssState.alt > 0) {
            altElement = <Tag>{gnssState.alt.toFixed(1)}m</Tag>
        }

        return (
            <FlexCol
                style={{
                    gap: 10,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <FontAwesomeIcon
                    icon={faSatelliteDish}
                    fontSize={"7rem"}
                    color={color}
                />
                <FlexCol
                    style={{
                        gap: 5,
                        justifyContent: "center",
                        alignItems: "center",

                        fontSize: "1.5rem",
                    }}
                >
                    <FlexRow
                        style={{
                            gap: 2,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {latLonElement}
                        {altElement}
                    </FlexRow>
                </FlexCol>
            </FlexCol>
        )
    }, [gnssState])

    const memoStatusElement = useMemo(() => {
        if (gnssState === null) {
            return
        }

        // Nav mode
        let navModeStr = "N/A"
        switch (gnssState.nav_mode) {
            case 1:
                navModeStr = "No fix"
                break
            case 2:
                navModeStr = "2D fix"
                break
            case 3:
                navModeStr = "3D fix"
                break
        }

        // Quality
        let qualityStr = "N/A"
        switch (gnssState.quality) {
            case 0:
                qualityStr = "Invalid"
                break
            case 1:
                qualityStr = "2D/3D"
                break
            case 2:
                qualityStr = "DGNSS"
                break
            case 4:
                qualityStr = "Fixed RTK"
                break
            case 5:
                qualityStr = "Float RTK"
                break
            case 6:
                qualityStr = "Dead Reckoning"
                break
        }

        // ALP status
        let alpStr = "N/A"
        if (gnssState.alp_duration_sec > 0) {
            let alpDurationHours = Math.floor(gnssState.alp_duration_sec / 3600)
            let alpAgeHours = Math.floor(gnssState.alp_age_sec / 3600)
            if (gnssState.alp_age_sec === -1) {
                alpAgeHours = 0
            }
            alpStr = `${alpAgeHours}/${alpDurationHours}h`
        }

        // RTCM status
        let rtcmStr = "N/A"
        if (gnssState.rtcm_age > 0 && gnssState.rtcm_nb_msgs > 0) {
            rtcmStr = `${gnssState.rtcm_age}s | ${gnssState.rtcm_nb_msgs} msgs`
        }
        return (
            <ul>
                <li>
                    <Translated keyEn="DateTime" />: {gnssState.datetime}
                </li>
                <li>
                    <Translated keyEn="NavMode" />: {navModeStr}
                </li>
                <li>
                    <Translated keyEn="Quality" />: {qualityStr}
                </li>
                <li>
                    <Translated keyEn="Satellites" />: {gnssState.nb_sv_used}/
                    {gnssState.nb_sv_visible}
                </li>
                <li>
                    <Translated keyEn="TTFF" />: {gnssState.ttff}
                </li>
                <li>
                    <Translated keyEn="MSSS" />: {gnssState.msss}
                </li>
                <li>
                    <Translated keyEn="ALP" />: {alpStr}
                </li>
                <li>
                    <Translated keyEn="RTCM" />: {rtcmStr}
                </li>
            </ul>
        )
    }, [gnssState])

    const memoAlpFilesElement = useMemo(() => {
        if (alpFiles === null) {
            return
        }
        if (alpFiles.keys.length === 0) {
            return <Translated keyEn="No ALP files" />
        }

        return (
            <ul>
                {alpFiles.keys.map((alpFile, idx) => {
                    return <li key={idx}>{alpFile}</li>
                })}
            </ul>
        )
    }, [alpFiles])

    return (
        <FlexCol
            style={{
                width: "100%",
                margin: "auto",
                gap: 30,
                marginBottom: "2rem",
            }}
        >
            {/* Header */}
            <FlexCol style={{ gap: 0 }}>
                <Typography.Text
                    style={{
                        fontSize: "2rem",
                    }}
                >
                    <Translated keyEn="GPS" />
                </Typography.Text>
            </FlexCol>
            {/* Current STATUS */}
            <div>
                <FlexRow
                    style={{
                        alignItems: "top",
                    }}
                >
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Current status" />
                    </UnderlinedSectionTitle>
                    <Button
                        onClick={async () => {
                            getGnssState()
                        }}
                        icon={<ReloadOutlined />}
                    />
                </FlexRow>
                <FlexCol style={{ gap: 0 }}>
                    <Row align={"middle"} justify={"start"}>
                        <Col xs={12}>
                            <div>{memoStatusElement}</div>
                        </Col>
                        <Col xs={6}>{memoGNSSStatusWidget}</Col>
                    </Row>
                </FlexCol>
            </div>
            {/* ALP Files */}
            <div>
                <FlexRow
                    style={{
                        alignItems: "top",
                    }}
                >
                    <UnderlinedSectionTitle>
                        <Translated keyEn="ALP Files" />
                    </UnderlinedSectionTitle>
                    <Button
                        onClick={async () => {
                            getAlpFiles()
                        }}
                        icon={<ReloadOutlined />}
                    />
                </FlexRow>
                <FlexCol style={{ gap: 0 }}>
                    <div>{memoAlpFilesElement}</div>
                </FlexCol>
                <FlexCol>
                    <Button onClick={fetchAndSetALPFile}>
                        <Translated keyEn="Download ALP File" />
                    </Button>
                </FlexCol>
            </div>
            {/* Restart */}
            <div>
                <FlexRow
                    style={{
                        alignItems: "top",
                    }}
                >
                    <UnderlinedSectionTitle>
                        <Translated keyEn="Restart" />
                    </UnderlinedSectionTitle>
                </FlexRow>
                <Row gutter={[10, 10]}>
                    <Col xs={24} md={12}>
                        <Button
                            block
                            onClick={() => reset(EResetType.Cold)}
                            icon={<ReloadOutlined />}
                        >
                            <Translated keyEn="Cold Restart" />
                        </Button>
                    </Col>
                    <Col xs={24} md={12}>
                        <Button
                            block
                            onClick={() => reset(EResetType.Warm)}
                            icon={<ReloadOutlined />}
                        >
                            <Translated keyEn="Warm Restart" />
                        </Button>
                    </Col>
                    {/* <Col xs={24} md={8}>
                        <Button
                            block
                            danger
                            onClick={() => reset(EResetType.Full)}
                            icon={<ReloadOutlined />}
                        >
                            <Translated keyEn="Full Restart" />
                        </Button>
                    </Col> */}
                </Row>
            </div>
        </FlexCol>
    )
}
