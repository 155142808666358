import { FC, ReactElement, useEffect, useMemo, useState } from "react"
import { SyntheticImpactWithEmbeddedReferences } from "../../usercomm/common/usercommAsyncRequestGeneric"
import {
    COLOR_BG_BLUE,
    COLOR_BG_RED,
    colorHexToRgba,
    pbUUIDToUuid,
} from "../../utils/utils"
import { ICoordinate } from "../../types"
import {
    GoogleMapsMarker,
    GoogleMapsReactSignedIn,
} from "../../components/commons-ts/googleMapsApi/googleMaps"
import { NominatimZoomType } from "../../generated/proto-ts/main"

export const ImpactsSummaryMap: FC<{
    impacts: SyntheticImpactWithEmbeddedReferences[] | null
}> = ({ impacts }) => {
    const [siteImpactsMap, setSiteImpactsMap] = useState<
        Record<string, SyntheticImpactWithEmbeddedReferences[]>
    >({})
    const [globalBarycenter, setGlobalBarycenter] =
        useState<ICoordinate | null>(null)

    useEffect(() => {
        if (impacts === null) {
            return
        }
        // console.log(
        //     `ImpactsSummaryMap: impacts.length=${impacts.length}`,
        //     impacts.map((impact) => impact.impact.toObject()),
        // )
        let _siteImpactsMap: Record<
            string,
            SyntheticImpactWithEmbeddedReferences[]
        > = {}
        for (let impact of impacts) {
            if (impact.impact.deleted_at > 0) {
                continue
            }
            if (impact.site !== null) {
                let siteUUID = pbUUIDToUuid(impact.site.uuid)
                if (_siteImpactsMap[siteUUID] === undefined) {
                    _siteImpactsMap[siteUUID] = []
                }
                _siteImpactsMap[siteUUID].push(impact)
            }
        }
        // console.log(`ImpactsSummaryMap: _siteImpactsMap=`, _siteImpactsMap)

        // Global barycenter
        let globalSumLat = 0
        let globalSumLon = 0
        let globalN = 0
        for (let [siteUUID, siteImpacts] of Object.entries(_siteImpactsMap)) {
            let sumLat = 0
            let sumLon = 0
            let n = 0
            for (let impact of siteImpacts) {
                if (
                    impact.impact.latitude === 0 &&
                    impact.impact.longitude === 0
                ) {
                    continue
                }
                sumLat += impact.impact.latitude
                sumLon += impact.impact.longitude
                n++
            }
            if (n === 0) {
                continue
            }
            let localMeanLat = sumLat / n
            let localMeanLon = sumLon / n
            console.log(
                `ImpactsSummaryMap: adding site (${siteUUID}) barycenter (n=${n}): lat=${localMeanLat}, lon=${localMeanLon}`,
            )
            globalSumLat += localMeanLat
            globalSumLon += localMeanLon
            globalN++
        }
        console.log(`ImpactsSummaryMap: globalN=${globalN}`)
        if (globalN === 0) {
            setGlobalBarycenter(null)
        } else {
            let globalLat = globalSumLat / globalN
            let globalLon = globalSumLon / globalN
            console.log(
                `ImpactsSummaryMap: globalLat=${globalLat}, globalLon=${globalLon}`,
            )
            setGlobalBarycenter({
                coords: {
                    latitude: globalSumLat / globalN,
                    longitude: globalSumLon / globalN,
                },
            })
        }

        setSiteImpactsMap(_siteImpactsMap)
    }, [impacts])

    const memoImpactMarkers = useMemo(() => {
        let markers: ReactElement[] = []
        for (let siteId of Object.keys(siteImpactsMap)) {
            for (let impact of siteImpactsMap[siteId]) {
                let impactMarker = (
                    <GoogleMapsMarker
                        key={pbUUIDToUuid(impact.impact.uuid)}
                        lat={impact.impact.latitude}
                        lng={impact.impact.longitude}
                        color={colorHexToRgba(COLOR_BG_RED, 0.1)}
                    >
                        {/* <ul>
                            <li>
                                <Translated keyEn="Gmax" />:{" "}
                                {impact.impact.impact_gmax}
                            </li>
                            <li>
                                <Translated keyEn="HIC" />:{" "}
                                {impact.impact.impact_hic}
                            </li>
                            <li>
                                <Translated keyEn="Height" />:{" "}
                                {impact.impact.impact_ffh}
                            </li>
                        </ul> */}
                    </GoogleMapsMarker>
                )
                markers.push(impactMarker)
            }
        }
        return markers
    }, [siteImpactsMap])

    const memoSiteMarkers = useMemo(() => {
        let markers: ReactElement[] = []
        for (let siteId of Object.keys(siteImpactsMap)) {
            let site = siteImpactsMap[siteId][0].site
            if (site === undefined || site === null) {
                continue
            }
            let siteImpacts = siteImpactsMap[siteId]
            let sumLat = 0
            let sumLon = 0
            let n = 0
            for (let impact of siteImpacts) {
                if (
                    impact.impact.latitude === 0 &&
                    impact.impact.longitude === 0
                ) {
                    continue
                }
                sumLat += impact.impact.latitude
                sumLon += impact.impact.longitude
                n++
            }
            if (n === 0) {
                continue
            }
            let meanLat = sumLat / n
            let meanLon = sumLon / n
            let siteMarker = (
                <GoogleMapsMarker
                    key={siteId}
                    lat={meanLat}
                    lng={meanLon}
                    color={colorHexToRgba(COLOR_BG_BLUE, 0.5)}
                />
            )
            markers.push(siteMarker)
        }
        return markers
    }, [siteImpactsMap])

    return (
        <div
            style={{
                height: "50vh",
                width: "100%",
                minWidth: "200px",
            }}
        >
            <GoogleMapsReactSignedIn
                center={
                    globalBarycenter !== null
                        ? {
                              lat: globalBarycenter.coords.latitude,
                              lng: globalBarycenter.coords.longitude,
                          }
                        : undefined
                }
                defaultZoom={NominatimZoomType.STATE}
            >
                {/* {memoImpactMarkers} */}
                {memoSiteMarkers}
            </GoogleMapsReactSignedIn>
        </div>
    )
}
