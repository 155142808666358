import {
    DeleteFilled,
    DoubleRightOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons"
import { Tooltip, Popconfirm, Button, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useMemo } from "react"
import { Link } from "react-router-dom"
import { FlexRow } from "../../../components/commons-ts/common"
import { Equipment, Impact } from "../../../generated/proto-ts/main"
import { Translated } from "../../../utils/translated"
import { pbUUIDToUuid, COLOR_BG_ROW_DELETED } from "../../../utils/utils"
import { _calculatePerformanceFactor } from "../../../calculus/calculus_TL"

export const ZoneImpactsTableTL: FC<{
    impacts: Impact[] | null
    equipmentImpactorWeight: number | null
    currentImpactUUID?: string | null
    onDeleteImpact?: (impact: Impact | null) => void
}> = ({
    impacts,
    currentImpactUUID,
    equipmentImpactorWeight,
    onDeleteImpact,
}) => {
    const columns: ColumnsType<Impact> = useMemo(() => {
        if (impacts === null) {
            return []
        }
        let impactIds = impacts.map((impact) => pbUUIDToUuid(impact.uuid))
        let _columns: ColumnsType<Impact> = [
            {
                title: "#",
                render: (_, impact) => {
                    return impactIds.indexOf(pbUUIDToUuid(impact.uuid)) + 1
                },
                // sorter: (a, b) => (a.id ?? 0) - (b.id ?? 0),
            },
            {
                key: "height",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Free Fall Height, m" />}
                    >
                        <div>
                            <Translated keyEn="Height, m" />
                        </div>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (impact.impact_ffh === 0) {
                        return null
                    }
                    return <span>{impact.impact_ffh.toFixed(2)}</span>
                },
                sorter: (a, b) => a.impact_ffh - b.impact_ffh,
            },
            {
                key: "gmax",
                title: <Translated keyEn="Gmax" />,
                render: (_, impact) => {
                    if (impact.impact_gmax === 0) {
                        return null
                    }
                    return <span>{impact.impact_gmax.toFixed(1)}</span>
                },
                sorter: (a, b) => a.impact_gmax - b.impact_gmax,
            },
            {
                key: "rebounce_height",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Rebounce Height, mm" />}
                    >
                        <span>
                            <Translated keyEn="Rebounce, mm" />
                        </span>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (impact.impact_rebounce_height === 0) {
                        return null
                    }
                    let valueMM = 1000 * impact.impact_rebounce_height
                    return <span>{valueMM.toFixed(0)}</span>
                },
            },
            {
                key: "performance_factor",
                title: (
                    <Tooltip
                        overlay={<Translated keyEn="Performance Factor" />}
                    >
                        <span>
                            <Translated keyEn="PF" />
                        </span>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (
                        impact.impact_rebounce_height === 0 ||
                        impact.impact_gmax === 0
                    ) {
                        return null
                    }
                    let performanceFactor = _calculatePerformanceFactor(
                        impact.impact_rebounce_height,
                        impact.impact_gmax,
                    )
                    return <span>{Math.round(performanceFactor)}</span>
                },
            },
            {
                key: "performance_factor_eq_20kgfm",
                title: (
                    <Tooltip
                        overlay={
                            <Translated keyEn="Performance Factor Eq 20kgfm" />
                        }
                    >
                        <span>
                            <Translated keyEn="PFeq20" />
                        </span>
                    </Tooltip>
                ),
                render: (_, impact) => {
                    if (
                        impact.impact_rebounce_height === 0 ||
                        impact.impact_gmax === 0 ||
                        equipmentImpactorWeight === null
                    ) {
                        return null
                    }
                    let performanceFactor = _calculatePerformanceFactor(
                        impact.impact_rebounce_height,
                        impact.impact_gmax,
                    )
                    let performanceFactorEq20kgfm =
                        performanceFactor *
                        (20 / equipmentImpactorWeight / impact.impact_ffh)
                    return <span>{Math.round(performanceFactorEq20kgfm)}</span>
                },
            },
            {
                key: "timestamp",
                title: <Translated keyEn="Date" />,
                render: (_, impact) => {
                    if (impact.created_at === 0) {
                        return null
                    }
                    return new Date(impact.created_at).toLocaleString()
                },
                sorter: (a, b) => a.created_at - b.created_at,
                defaultSortOrder: "descend",
            },
            {
                title: "",
                width: "2rem",
                render: (_, impact) => {
                    return (
                        <FlexRow style={{ gap: 0 }}>
                            {onDeleteImpact !== undefined && (
                                <Popconfirm
                                    title={
                                        <>
                                            <Translated keyEn="Are you sure you want to delete this impact" />
                                            ?
                                        </>
                                    }
                                    onConfirm={() => {
                                        onDeleteImpact(impact)
                                    }}
                                >
                                    <Button
                                        danger
                                        type="link"
                                        size="small"
                                        icon={<DeleteFilled />}
                                    />
                                </Popconfirm>
                            )}
                            {currentImpactUUID === undefined ||
                            currentImpactUUID !== pbUUIDToUuid(impact.uuid) ? (
                                <Link
                                    to={`/impacts/${pbUUIDToUuid(impact.uuid)}`}
                                >
                                    <Button type="link" size="small">
                                        <DoubleRightOutlined />
                                    </Button>
                                </Link>
                            ) : (
                                <FlexRow
                                    style={{
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CheckCircleOutlined />
                                </FlexRow>
                            )}
                        </FlexRow>
                    )
                },
            },
        ]
        return _columns
    }, [impacts, currentImpactUUID, equipmentImpactorWeight])

    return (
        <Table
            dataSource={impacts ?? []}
            loading={impacts === null}
            columns={columns}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            style={{
                width: "100%",
            }}
            onRow={(impact) => {
                return {
                    style: {
                        backgroundColor:
                            impact.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                        fontWeight:
                            pbUUIDToUuid(impact.uuid) === currentImpactUUID
                                ? 700
                                : 400,
                    },
                }
            }}
            footer={() => {
                return (
                    <i>
                        <div>
                            <Translated keyEn="Table of impacts: EN ISO 23659 - TRAMPOLINES" />
                        </div>
                        {equipmentImpactorWeight && (
                            <div>
                                <Translated keyEn="Given the impactor weight of " />
                                <b>{equipmentImpactorWeight.toFixed(2)} kg</b>
                            </div>
                        )}
                    </i>
                )
            }}
        />
    )
}
