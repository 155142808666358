import { DoubleRightOutlined } from "@ant-design/icons"
import { Button, Table, Tag, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, memo, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { Equipment, UUID, Zone } from "../../../generated/proto-ts/main"
import { Translated } from "../../../utils/translated"
import { pbUUIDToUuid, COLOR_BG_ROW_DELETED } from "../../../utils/utils"
import { renderMeanStd } from "../../../utils/utils_tsx"
import { getZoneGmaxSM } from "../../../calculus/calculus_SM"
import {
    getZonePerformanceFactorTL,
    getZoneResultTL,
} from "../../../calculus/calculus_TL"
import { IZoneResult } from "../../../calculus/types"
import { EResultTagTL } from "../../../components/commons-ts/common"
import { TableImageThumbnail } from "../../../components/commons-ts/tableImageThumbnail"
import { useUsercommEquipmentChildrenRecursiveBimodal } from "../../../usercomm/common/usercommAsyncRequestBimodal"

export const EquipmentZonesTableTL: FC<{
    equipmentUUID: UUID | null
    equipmentImpactorWeight: number | null
    zones: Zone[] | null
}> = ({ equipmentUUID, equipmentImpactorWeight, zones }) => {
    const [zoneResultsMap, setZoneResultsMap] = useState<Record<
        string,
        IZoneResult
    > | null>(null)

    const [zoneImpactsMap, getEntitiesRecursiveRequest] =
        useUsercommEquipmentChildrenRecursiveBimodal()

    useEffect(() => {
        getEntitiesRecursiveRequest(equipmentUUID)
    }, [equipmentUUID])

    useEffect(() => {
        if (zoneImpactsMap === null) {
            return
        }
        let _zoneResultsMap: Record<string, IZoneResult> = {}
        for (let zoneUUIDStr of Object.keys(zoneImpactsMap)) {
            let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
            // console.log(
            //     `EquipmentZonesTableTL: zoneImpactsMap`,
            //     zoneImpacts.map((e) => e.toObject()),
            // )
            if (zoneImpacts === undefined) {
                continue
            }
            let result = getZoneResultTL(zoneImpacts, equipmentImpactorWeight)
            if (result === null) {
                continue
            }
            _zoneResultsMap[zoneUUIDStr] = result
        }
        setZoneResultsMap(_zoneResultsMap)
    }, [zoneImpactsMap, equipmentImpactorWeight])

    const columns = useMemo((): ColumnsType<Zone> => {
        return [
            {
                title: "📷", // Photo
                render: (_, zone) => {
                    let pictureUUID = zone.pictures[0]
                    if (pictureUUID === undefined) {
                        return <div style={{ width: "3rem" }} />
                    }
                    return (
                        <Link to={`/zones/${pbUUIDToUuid(zone.uuid)}`}>
                            <TableImageThumbnail
                                pictureUUID={pictureUUID}
                                alt={zone.zone_name}
                            />
                        </Link>
                    )
                },
            },
            {
                key: "zone",
                title: "Zone",
                ellipsis: true,
                render: (_, zone) => {
                    return (
                        <Link
                            to={`/zones/${pbUUIDToUuid(zone.uuid)}`}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            {zone.zone_name}
                        </Link>
                    )
                },
                sorter: (a, b) => a.zone_name.localeCompare(b.zone_name),
                defaultSortOrder: "ascend",
            },
            {
                title: <Translated keyEn="# impacts" />,
                render: (_, zone) => {
                    if (zoneImpactsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return <span>0</span>
                    }
                    let nbImpacts = 0
                    for (let impact of zoneImpacts) {
                        if (impact.deleted_at === 0) {
                            nbImpacts++
                        }
                    }
                    return <span>{nbImpacts}</span>
                },
            },
            {
                key: "gmax",
                title: <Translated keyEn="Gmax" />,
                render: (_, zone) => {
                    if (zoneImpactsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return null
                    }
                    let gmax = getZoneGmaxSM(zoneImpacts)
                    if (gmax === null) {
                        return null
                    }
                    return renderMeanStd(gmax, 3)
                },
            },
            {
                key: "secondary_suspence_height_m",
                title: (
                    <Tooltip overlay="Rebounce Height, mm">
                        <span>
                            <Translated keyEn="Rebounce, mm" />
                        </span>
                    </Tooltip>
                ),
                render: (_, zone) => {
                    if (zoneImpactsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return null
                    }
                    let zoneValues = getZonePerformanceFactorTL(
                        zoneImpacts,
                        equipmentImpactorWeight,
                    )
                    if (
                        zoneValues === null ||
                        zoneValues.secondarySuspenseHeightM === null
                    ) {
                        return null
                    }
                    let valueMM = {
                        mean: 1000 * zoneValues.secondarySuspenseHeightM.mean,
                        std: 1000 * zoneValues.secondarySuspenseHeightM.std,
                        count: zoneValues.secondarySuspenseHeightM.count,
                    }
                    return renderMeanStd(valueMM, 3)
                },
            },
            {
                key: "performance_factor_eq_20kgfm",
                title: (
                    <div>
                        <Tooltip overlay="Performance Factor Eq 20kgfm">
                            <span>
                                <Translated keyEn="PF eq 20" />
                            </span>
                        </Tooltip>
                    </div>
                ),
                render: (_, zone) => {
                    if (zoneImpactsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let zoneImpacts = zoneImpactsMap[zoneUUIDStr]
                    if (zoneImpacts === undefined) {
                        return null
                    }
                    let zoneValues = getZonePerformanceFactorTL(
                        zoneImpacts,
                        equipmentImpactorWeight,
                    )
                    if (
                        zoneValues === null ||
                        zoneValues.performanceFactor === null
                    ) {
                        return null
                    }
                    return renderMeanStd(zoneValues.performanceFactor, 3)
                },
            },
            {
                key: "result",
                title: <Translated keyEn="Result" />,
                render: (_, zone) => {
                    if (zoneResultsMap === null) {
                        return null
                    }
                    let zoneUUIDStr = pbUUIDToUuid(zone.uuid)
                    let result = zoneResultsMap[zoneUUIDStr]
                    if (result === undefined) {
                        return <Tag>N/A</Tag>
                    }
                    if (result === null) {
                        return <Tag>N/A</Tag>
                    }
                    return <EResultTagTL result={result.result} />
                },
            },
            {
                title: "",
                width: "2rem",
                render: (_, zone) => {
                    return (
                        <Link to={`/zones/${pbUUIDToUuid(zone.uuid)}`}>
                            <Button type="link" size="small">
                                <DoubleRightOutlined />
                            </Button>
                        </Link>
                    )
                },
            },
        ]
    }, [zones, zoneImpactsMap, zoneResultsMap, equipmentImpactorWeight])

    return (
        <Table
            dataSource={zones ?? []}
            loading={zones === null || zoneImpactsMap === null}
            columns={columns}
            pagination={false}
            rowKey="uuid"
            size="small"
            scroll={{ x: true }}
            bordered
            onRow={(zone) => {
                return {
                    style: {
                        backgroundColor:
                            zone.deleted_at !== 0
                                ? COLOR_BG_ROW_DELETED
                                : undefined,
                    },
                }
            }}
            footer={() => (
                <i>
                    <div>
                        <Translated keyEn="Table of zones for EN ISO 23659 - TRAMPOLINES" />
                    </div>
                    {equipmentImpactorWeight && (
                        <div>
                            <Translated keyEn="Given the impactor weight of " />
                            <b>{equipmentImpactorWeight.toFixed(2)} kg</b>
                        </div>
                    )}
                </i>
            )}
        />
    )
}
