import {
    CSSProperties,
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react"

import { useHistory } from "react-router-dom"
import { Alert, Button, Col, DatePicker, Input, Radio, Row, Switch } from "antd"

import dayjs from "dayjs"
import { Translated } from "../../utils/translated"
import { FlexCol, FlexRow } from "../../components/commons-ts/common"
import { ErrorAlert } from "../../components/commons-ts/errorAlert"
import { MethodTypeEN1177, NormType, Site } from "../../generated/proto-ts/main"
import { getLocalStorageCloudApiUser } from "../../utils/cloudApiV2"
import { pbUUIDToUuid, uuidToPbUUID } from "../../utils/utils"
import { GoogleAddressWidget } from "../../components/commons-ts/googleMapsApi/googleAddress"
import { useUsercommCreateSiteBimodal } from "../../usercomm/common/usercommAsyncRequestBimodal"
import { v4 as uuidv4 } from "uuid"

export const SiteInitForm: FC<{
    clonedSite: Site | null
    onToggle: (state: boolean) => void
}> = ({ clonedSite, onToggle }) => {
    const [missionNormType, setMissionNormType] = useState<NormType>(
        NormType.EN_1177,
    )
    const [missionMethod, setMissionMethod] = useState<MethodTypeEN1177 | null>(
        MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION,
    )
    const [missionName, setMissionName] = useState<string>("")
    const [clientName, setClientName] = useState<string>("")
    const [siteName, setSiteName] = useState<string>("")
    const [address, setAddress] = useState<string | null>("")
    const [isLabTest, setIsLabTest] = useState<boolean | null>(false)
    const [executionDate, setExecutionDate] = useState<dayjs.Dayjs | null>(
        dayjs(),
    )

    const [userUUID, setUserUUID] = useState<string | null>(null)

    const [createdSiteUUID, createSiteRequest] = useUsercommCreateSiteBimodal()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const history = useHistory()

    useEffect(() => {
        let user = getLocalStorageCloudApiUser()
        if (user !== null && user.UUID !== null) {
            setUserUUID(user.UUID)
        }
    }, [])

    const onSubmit = useCallback(async () => {
        if (userUUID === null) {
            setError("User UUID is required")
            return
        }
        if (siteName === "") {
            setError("Site name is required")
            return
        }

        setIsLoading(true)
        setError(null)

        let method_type = missionMethod
        let isLabTest: boolean | null = null
        if (missionNormType === NormType.EN_12503) {
            method_type = null
            isLabTest = null
        }
        let ts = Date.now()
        let newSite = new Site({
            uuid: uuidToPbUUID(uuidv4()), // IT IS CREATOR'S RESPONSIBILITY TO GENERATE A NEW UUID
            norm_type: missionNormType,
            method_type_en_1177: method_type ?? undefined,
            is_lab_test: isLabTest ?? undefined,
            mission_name: missionName,
            client_name: clientName,
            site_name: siteName,
            address: address ?? "",
            created_at: ts,
            updated_at: ts,
        })
        if (executionDate !== null) {
            newSite.execution_date = executionDate?.toDate().getTime()
        }
        createSiteRequest(userUUID, newSite)
    }, [
        missionNormType,
        missionMethod,
        missionName,
        clientName,
        siteName,
        isLabTest,
        executionDate,
        address,
        userUUID,
    ])
    useEffect(() => {
        if (createdSiteUUID === null) {
            return
        }
        setIsLoading(false)
        history.push(`/sites/${pbUUIDToUuid(createdSiteUUID)}`)
    }, [createdSiteUUID])

    useEffect(() => {
        if (clonedSite === null) {
            return
        }
        setMissionNormType(clonedSite.norm_type)
        setMissionMethod(clonedSite.method_type_en_1177)
        if (clonedSite.execution_date) {
            setExecutionDate(dayjs(clonedSite.execution_date))
        }
        setIsLabTest(clonedSite.is_lab_test)
        setMissionName(clonedSite.mission_name)
        setClientName(clonedSite.client_name)
        setSiteName(clonedSite.site_name)
        setAddress(clonedSite.address)
    }, [clonedSite])

    const memoMissionNormTypeExplicationElement = useMemo(() => {
        let explicationAlert: ReactNode = null
        let logoSrc = ""
        if (missionNormType === NormType.EN_1177) {
            explicationAlert = (
                <Alert
                    message={
                        <>
                            <Translated keyEn="European norm" />{" "}
                            <b>
                                <a
                                    target="_blank"
                                    href="https://www.boutique.afnor.org/en-gb/standard/nf-en-1177-in1/impact-attenuating-playground-surfacing-methods-of-test-for-determination-o/fa203676/366543"
                                >
                                    EN 1177
                                </a>
                            </b>
                            :{" "}
                            <Translated keyEn="HIC values of playground floor elements" />
                        </>
                    }
                    description={
                        <>
                            <Translated keyEn="The test is either performed in a laboratory or on site: please indicate" />
                            <br />
                            <Translated keyEn="2 methods are supported for calculating conformity result:" />
                            <br />
                            <ul>
                                <li>
                                    <Translated keyEn="1: CFH (Critical Fall Height)" />
                                </li>
                                <li>
                                    <Translated keyEn="2: Adequacy (Impact Attenuation)" />
                                </li>
                            </ul>
                            <Translated keyEn="Measured properties:" />
                            <ul>
                                <li>
                                    <Translated keyEn="Gmax, g" />
                                </li>
                                <li>
                                    <Translated keyEn="HIC" />
                                </li>
                            </ul>
                        </>
                    }
                    type="info"
                    showIcon
                />
            )
            logoSrc = "EN-1177-garcon-sols-synthetiques.jpg"
        } else if (missionNormType === NormType.EN_12503) {
            explicationAlert = (
                <Alert
                    message={
                        <>
                            <Translated keyEn="European norm" />{" "}
                            <b>
                                <a
                                    target="_blank"
                                    href="https://www.boutique.afnor.org/en-gb/standard/nf-en-125034/sports-mats-part-4-determination-of-shock-absorption/fa063333/57540"
                                >
                                    EN 12503
                                </a>
                            </b>
                            :{" "}
                            <Translated keyEn="shock absorption of sports mats: gymnastics, pole vault, high jump and judo" />
                            <br />
                            <Translated keyEn="Also used for reception mats in climbing gyms:" />{" "}
                            <b>
                                <a
                                    target="_blank"
                                    href="https://www.boutique.afnor.org/en-gb/standard/nf-en-125722/artificial-climbing-structures-part-2-safety-requirements-and-test-methods-/fa182518/58638"
                                >
                                    EN 12572
                                </a>
                            </b>
                        </>
                    }
                    description={
                        <>
                            <Translated keyEn="The test is performed in a laboratory on a" />{" "}
                            <b>
                                <Translated keyEn="specifically chosen" />
                            </b>{" "}
                            <Translated keyEn="type of sports mat" />
                            <br />
                            <Translated keyEn="Measured properties:" />
                            <ul>
                                <li>
                                    <Translated keyEn="Gmax, g" />
                                </li>
                                <li>
                                    <Translated keyEn="Resilience, %" />
                                </li>
                                <li>
                                    <Translated keyEn="Deflection, % (or mm)" />
                                </li>
                            </ul>
                        </>
                    }
                    type="info"
                    showIcon
                />
            )
            logoSrc = "EN-12503-12572-garcon-sols-gymnastique.jpg"
        } else if (missionNormType === NormType.EN_14960) {
            explicationAlert = (
                <Alert
                    style={{ width: "100%" }}
                    message={
                        <>
                            <Translated keyEn="European norm" />{" "}
                            <b>
                                <a
                                    target="_blank"
                                    href="https://www.boutique.afnor.org/en-gb/standard/nf-en-149601/inflatable-play-equipment-part-1-safety-requirements-and-test-methods/fa191651/1806"
                                >
                                    EN 14960
                                </a>
                            </b>
                            :{" "}
                            <Translated keyEn='inflatable play equipment, aka "airbags"' />
                        </>
                    }
                    description={
                        <>
                            <Translated keyEn="The test is performed in a laboratory on any type of airbag" />
                            <br />
                            <Translated keyEn="Measured properties:" />
                            <ul>
                                <li>
                                    <Translated keyEn="Gmax, g" />
                                </li>
                                <li>
                                    <Translated keyEn="Resilience, %" />
                                </li>
                                <li>
                                    <Translated keyEn="Deflection, % (or mm)" />
                                </li>
                            </ul>
                        </>
                    }
                    type="info"
                    showIcon
                />
            )
            logoSrc = ""
        } else if (missionNormType === NormType.EN_ISO_23659) {
            explicationAlert = (
                <Alert
                    message={
                        <>
                            <Translated keyEn="EN" />{" "}
                            <b>
                                <a
                                    target="_blank"
                                    href="https://www.iso.org/standard/76556.html"
                                >
                                    ISO 23659:2022
                                </a>
                            </b>
                            :{" "}
                            <Translated keyEn="sports and recreational facilities — trampoline parks" />
                        </>
                    }
                    description={
                        <>
                            <Translated keyEn="The test is performed in laboratory or on-site on any type of trampoline" />
                            <br />
                            <Translated keyEn="Measured properties:" />
                            <ul>
                                <li>
                                    <Translated keyEn="Gmax, g" />
                                </li>
                                <li>
                                    <Translated keyEn="Rebounce height, m" />
                                </li>
                                <li>
                                    <Translated keyEn="Performance factor, [0-120]" />
                                </li>
                            </ul>
                        </>
                    }
                    type="info"
                    showIcon
                />
            )
            logoSrc = "EN-ISO-23569-garcon-protection-trampoline.jpg"
        }
        return (
            <Row justify="center" align="middle" gutter={[10, 10]}>
                <Col xs={24} md={18}>
                    {explicationAlert}
                </Col>
                <Col>
                    {logoSrc !== "" && (
                        <img
                            style={{
                                width: "200px",
                                height: "100%",
                                alignSelf: "center",
                            }}
                            src={logoSrc}
                        />
                    )}
                </Col>
            </Row>
        )
    }, [missionNormType])

    let radioButtonStyle: CSSProperties = {
        textAlign: "center",
    }
    return (
        <FlexCol
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
                minHeight: "70vh",
            }}
        >
            <Row gutter={[10, 10]} justify={"space-between"}>
                <Col xs={24}>
                    <ErrorAlert error={error} />
                </Col>
                <Col xs={24}>
                    <Radio.Group
                        value={missionNormType}
                        buttonStyle="solid"
                        size="large"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}
                        onChange={(e) => setMissionNormType(e.target.value)}
                    >
                        <Radio.Button
                            value={NormType.EN_1177}
                            style={radioButtonStyle}
                        >
                            <Translated keyEn="Playground Floor" />
                        </Radio.Button>
                        <Radio.Button
                            value={NormType.EN_12503}
                            style={radioButtonStyle}
                        >
                            <Translated keyEn="Sports Mats" />
                        </Radio.Button>
                        <Radio.Button
                            value={NormType.EN_14960}
                            style={radioButtonStyle}
                        >
                            <Translated keyEn="Airbags" />
                        </Radio.Button>
                        <Radio.Button
                            value={NormType.EN_ISO_23659}
                            style={radioButtonStyle}
                        >
                            <Translated keyEn="Trampolines" />
                        </Radio.Button>
                    </Radio.Group>
                </Col>
                <Col xs={24}>{memoMissionNormTypeExplicationElement}</Col>
                {missionNormType === NormType.EN_1177 && (
                    <Col xs={24}>
                        <Row align="middle" gutter={[10, 10]}>
                            <Col>
                                <FlexCol>
                                    <Translated keyEn="Method" />
                                    <Radio.Group
                                        buttonStyle="solid"
                                        size="large"
                                        value={missionMethod}
                                        onChange={(e) =>
                                            setMissionMethod(e.target.value)
                                        }
                                    >
                                        <Radio.Button
                                            value={
                                                MethodTypeEN1177.CRITICAL_FALL_HEIGHT_DETERMINATION
                                            }
                                        >
                                            1: <Translated keyEn="CFH" />
                                        </Radio.Button>
                                        <Radio.Button
                                            value={
                                                MethodTypeEN1177.IMPACT_ATTENUATION_COMPLIANCE
                                            }
                                        >
                                            2: <Translated keyEn="Adequacy" />
                                        </Radio.Button>
                                    </Radio.Group>
                                </FlexCol>
                            </Col>
                            <Col>
                                <FlexCol>
                                    <Translated keyEn="LabTest" />
                                    <Switch
                                        checked={isLabTest === true}
                                        onChange={setIsLabTest}
                                    />
                                </FlexCol>
                            </Col>
                        </Row>
                    </Col>
                )}
                <Col xs={24} md={6}>
                    <FlexCol>
                        Execution Date
                        <DatePicker
                            picker="date"
                            variant="filled"
                            size="large"
                            value={executionDate}
                            onChange={(date) => setExecutionDate(date)}
                        />
                    </FlexCol>
                </Col>
                <Col xs={24} md={6}>
                    <FlexCol>
                        <Translated keyEn="Mission" />
                        <Input
                            value={missionName}
                            onChange={(e) => setMissionName(e.target.value)}
                            size="large"
                            placeholder="Mission name"
                            variant="filled"
                        />
                    </FlexCol>
                </Col>
                <Col xs={24} md={6}>
                    <FlexCol>
                        <Translated keyEn="Client" />
                        <Input
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                            size="large"
                            placeholder="Client name"
                            variant="filled"
                        />
                    </FlexCol>
                </Col>
                <Col xs={24} md={6}>
                    <FlexCol>
                        <Translated keyEn="Site" />
                        <Input
                            value={siteName}
                            onChange={(e) => setSiteName(e.target.value)}
                            size="large"
                            placeholder="Site name"
                            status={
                                error !== null && siteName === ""
                                    ? "error"
                                    : undefined
                            }
                            variant="filled"
                        />
                    </FlexCol>
                </Col>
                <Col xs={24}>
                    <FlexCol>
                        <Translated keyEn="Address" />
                        <GoogleAddressWidget
                            address={address}
                            setAddress={setAddress}
                        />
                    </FlexCol>
                </Col>
            </Row>
            <FlexRow
                style={{
                    alignItems: "center",
                    justifyContent: "end",
                }}
            >
                <Button
                    onClick={() => {
                        onToggle(false)
                    }}
                    type="link"
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={onSubmit}
                    loading={isLoading}
                    disabled={isLoading}
                    size="large"
                    style={{
                        minWidth: 200,
                    }}
                >
                    Create
                </Button>
            </FlexRow>
        </FlexCol>
    )
}
