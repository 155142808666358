import {
    FC,
    ReactElement,
    memo,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import {
    useUsercommAsyncRequestBLE,
    useUsercommGenericDeviceEntitiesBLE,
} from "../../usercomm/local/ble/usercommAsyncRequestBLE"
import {
    useCloudUsercommAsyncRequestWS,
    useUsercommGenericDeviceEntitiesWS,
} from "../../usercomm/cloud/cloudUsercommAsyncRequestWS"
import { COLOR_BG_GRAY, uuidToPbUUID } from "../../utils/utils"
import { useSyncronizationContext } from "../../providers/syncronizationProvider"
import {
    ESyncDisposition,
    ESyncState,
    IRawGenericStorageEntity,
    ISyncAggregatedProgress,
    ISyncEntityLocationAndState,
} from "../../types"
import {
    UsercommAsyncRequestHook,
    useUsercommDeleteRawStorageEntityGen,
    useUsercommRawStorageEntityGen,
    useUsercommUpdateRawStorageEntityGen,
} from "../../usercomm/common/usercommAsyncRequestGeneric"
import {
    GenericStorageEntity,
    StorageEntityType,
} from "../../generated/proto-ts/main"
import { Paper } from "./paper"
import { FloatButton, Progress, Spin, Tooltip, Typography } from "antd"
import { FlexCol, FlexRow } from "./common"
import { useUsercommContextBLE } from "../../usercomm/local/ble/usercommProviderBLE"
import {
    CloudDownloadOutlined,
    CloudOutlined,
    CloudSyncOutlined,
    CloudUploadOutlined,
    DisconnectOutlined,
    PlusOutlined,
    SwapOutlined,
    SyncOutlined,
} from "@ant-design/icons"

const _getChildrenRecursive = (
    entities: IRawGenericStorageEntity[],
    parentUUID: string,
) => {
    let children: IRawGenericStorageEntity[] = []
    for (let entity of entities) {
        if (entity.parent_uuid === parentUUID) {
            children.push(entity)
            children = [
                ...children,
                ..._getChildrenRecursive(entities, entity.uuid),
            ]
        }
    }
    return children
}

const aggregateEntitiesBySite = (
    localEntities: IRawGenericStorageEntity[] | null,
    remoteEntities: IRawGenericStorageEntity[] | null,
): Record<string, IRawGenericStorageEntity[]> => {
    let unionEntityUUIDs = new Set<string>()
    if (localEntities === null) {
        localEntities = []
    }
    if (remoteEntities === null) {
        remoteEntities = []
    }
    for (let entity of localEntities) {
        unionEntityUUIDs.add(entity.uuid)
    }
    for (let entity of remoteEntities) {
        unionEntityUUIDs.add(entity.uuid)
    }
    let unionEntities: IRawGenericStorageEntity[] = []
    for (let uuid of unionEntityUUIDs) {
        let localEntity = localEntities.find((entity) => entity.uuid === uuid)
        let remoteEntity = remoteEntities.find((entity) => entity.uuid === uuid)
        if (localEntity !== undefined) {
            unionEntities.push(localEntity)
        } else if (remoteEntity !== undefined) {
            unionEntities.push(remoteEntity)
        }
    }
    let siteEntities: IRawGenericStorageEntity[] = []
    for (let entity of unionEntities) {
        if (entity.entity_type === StorageEntityType.SITE) {
            siteEntities.push(entity)
        }
    }
    let siteEntitiesMap: Record<string, IRawGenericStorageEntity[]> = {}
    for (let siteEntity of siteEntities) {
        siteEntitiesMap[siteEntity.uuid] = [
            siteEntity,
            ..._getChildrenRecursive(unionEntities, siteEntity.uuid),
        ]
    }
    return siteEntitiesMap
}

const useUpdateQueue = (
    sourceAsyncRequest: UsercommAsyncRequestHook,
    targetAsyncRequest: UsercommAsyncRequestHook,
): [
    IRawGenericStorageEntity[] | null,
    (updateQueue: IRawGenericStorageEntity[] | null) => void,
] => {
    const [sourceEntity, getSourceEntity] =
        useUsercommRawStorageEntityGen(sourceAsyncRequest)
    const [
        updatedTargetEntityAck,
        updatedTargetEntityNack,
        updateTargetEntity,
    ] = useUsercommUpdateRawStorageEntityGen(targetAsyncRequest)

    const [updatedEntities, setUpdatedEntities] = useState<
        IRawGenericStorageEntity[] | null
    >(null)
    const [updateQueue, setUpdateQueue] = useState<
        IRawGenericStorageEntity[] | null
    >(null)

    const currentEntityRef = useRef<IRawGenericStorageEntity | null>(null)
    const forbiddenParentsRef = useRef<Set<string>>(new Set())

    // GET SOURCE ENTITY on updateQueue change
    useEffect(() => {
        if (updateQueue === null) {
            return
        }
        console.log(
            `useUpdateQueue: the rest of the updateQueue:`,
            updateQueue,
            forbiddenParentsRef.current,
        )
        let _currentEntity = updateQueue[0]
        if (_currentEntity === undefined) {
            return
        }
        currentEntityRef.current = _currentEntity
        if (
            _currentEntity.parent_uuid !== null &&
            forbiddenParentsRef.current.has(_currentEntity.parent_uuid)
        ) {
            console.log(
                `useUpdateQueue: parent of ${_currentEntity.uuid} is in the forbiddenParents list, skipping..`,
            )
            let currentEntityChildren = _getChildrenRecursive(
                updateQueue,
                _currentEntity.parent_uuid,
            )
            for (let child of currentEntityChildren) {
                forbiddenParentsRef.current.add(child.uuid)
            }
            setUpdatedEntities((prevEntities) => {
                if (prevEntities === null) {
                    prevEntities = []
                }
                if (currentEntityRef.current === null) {
                    return prevEntities
                }
                return [...prevEntities, ...currentEntityChildren]
            })
            setUpdateQueue((prevQueue) => {
                if (prevQueue === null) {
                    return null
                }
                return prevQueue.filter(
                    (entity) => !currentEntityChildren.includes(entity),
                )
            })
            return
        }
        getSourceEntity(
            uuidToPbUUID(_currentEntity.uuid),
            _currentEntity.entity_type,
        )
    }, [updateQueue])

    // UPDATE TARGET ENTITY on sourceEntity reception
    useEffect(() => {
        if (sourceEntity === null) {
            return
        }
        let genericSourceEntity: GenericStorageEntity =
            GenericStorageEntity.deserializeBinary(sourceEntity)
        let entityType = StorageEntityType.UNKNOWN_STORAGE_ENTITY_TYPE
        if (currentEntityRef.current !== null) {
            entityType = currentEntityRef.current.entity_type
        }
        // console.log(
        //     `useUpdateQueue: received sourceEntity of size ${sourceEntity.length} (type ${entityType}), will update the target..`,
        // )
        updateTargetEntity(
            genericSourceEntity.uuid,
            genericSourceEntity.parent_uuid,
            entityType,
            sourceEntity,
        )
    }, [sourceEntity])

    // UPDATE UPDATED ENTITIES on updatedTargetEntityAck reception
    // REMOVE CURRENT ENTITY from updateQueue
    useEffect(() => {
        if (updatedTargetEntityAck === null) {
            return
        }
        setUpdatedEntities((prevEntities) => {
            if (prevEntities === null) {
                prevEntities = []
            }
            if (currentEntityRef.current === null) {
                return prevEntities
            }
            return [...prevEntities, currentEntityRef.current]
        })
        setUpdateQueue((prevQueue) => {
            if (prevQueue === null) {
                return null
            }
            return prevQueue.slice(1)
        })
    }, [updatedTargetEntityAck])

    useEffect(() => {
        if (updatedTargetEntityNack === null) {
            return
        }
        if (currentEntityRef.current === null) {
            return
        }
        console.log(
            `useUpdateQueue: NACK received for`,
            currentEntityRef.current.uuid,
        )
        // Add the parent to the forbiddenParents list
        forbiddenParentsRef.current.add(currentEntityRef.current.uuid)

        setUpdatedEntities((prevEntities) => {
            if (prevEntities === null) {
                prevEntities = []
            }
            if (currentEntityRef.current === null) {
                return prevEntities
            }
            return [...prevEntities, currentEntityRef.current]
        })
        setUpdateQueue((prevQueue) => {
            if (prevQueue === null) {
                return null
            }
            return prevQueue.slice(1)
        })
    }, [updatedTargetEntityNack])

    return [updatedEntities, setUpdateQueue]
}

const useDeleteQueue = (
    targetAsyncRequest: UsercommAsyncRequestHook,
): [
    IRawGenericStorageEntity[] | null,
    (deleteQueue: IRawGenericStorageEntity[] | null) => void,
] => {
    const [currentEntity, setCurrentEntity] =
        useState<IRawGenericStorageEntity | null>(null)
    const [deletedEntityAck, deleteEntity] =
        useUsercommDeleteRawStorageEntityGen(targetAsyncRequest)

    const [deleteQueue, setDeleteQueue] = useState<
        IRawGenericStorageEntity[] | null
    >(null)
    const [deletedEntities, setDeletedEntities] = useState<
        IRawGenericStorageEntity[] | null
    >(null)

    // DELETE ENTITY on deleteQueue change
    useEffect(() => {
        if (deleteQueue === null) {
            return
        }
        let _currentEntity = deleteQueue[0]
        if (_currentEntity === undefined) {
            return
        }
        setCurrentEntity(_currentEntity)
        deleteEntity(
            uuidToPbUUID(_currentEntity.uuid),
            _currentEntity.entity_type,
        )
    }, [deleteQueue])

    // UPDATE DELETED ENTITIES on deletedEntityAck reception
    // REMOVE CURRENT ENTITY from deleteQueue
    useEffect(() => {
        if (deletedEntityAck === null || currentEntity === null) {
            return
        }
        setDeletedEntities((prevEntities) => {
            if (prevEntities === null) {
                prevEntities = []
            }
            return [...prevEntities, currentEntity]
        })
        setDeleteQueue((prevQueue) => {
            if (prevQueue === null) {
                return null
            }
            return prevQueue.slice(1)
        })
    }, [deletedEntityAck, currentEntity])

    return [deletedEntities, setDeleteQueue]
}

export const DataCloudSyncWidget: FC = () => {
    const [localGenericEntities, getLocalGenericEntities] =
        useUsercommGenericDeviceEntitiesBLE()
    const [remoteGenericEntities, getRemoteGenericEntities] =
        useUsercommGenericDeviceEntitiesWS()
    const {
        currentUser,
        entitySyncStateMap,
        setEntitySyncStateMap,
        setAggregatedSyncProgressMap,
    } = useSyncronizationContext()
    const { bleIsConnected } = useUsercommContextBLE()
    // Local
    // Update queue
    const [localUpdateQueue, setLocalUpdateQueue] = useState<
        IRawGenericStorageEntity[] | null
    >(null)
    // Delete queue
    const [localDeleteQueue, setLocalDeleteQueue] = useState<
        IRawGenericStorageEntity[] | null
    >(null)
    // Stale entities: already-deleted locally and still not existing remotely
    const [localStaleEntities, setLocalStaleEntities] = useState<
        IRawGenericStorageEntity[] | null
    >(null)
    // Remote
    // Update queue
    const [remoteUpdateQueue, setRemoteUpdateQueue] = useState<
        IRawGenericStorageEntity[] | null
    >(null)
    // Delete queue
    const [remoteDeleteQueue, setRemoteDeleteQueue] = useState<
        IRawGenericStorageEntity[] | null
    >(null)
    // Stale entities: already-deleted remotely and still not existing locally
    const [remoteStaleEntities, setRemoteStaleEntities] = useState<
        IRawGenericStorageEntity[] | null
    >(null)

    // Local
    // Local update queue: source from WS, target to BLE
    const [locallyUpdatedEntities, locallyUpdateEntities] = useUpdateQueue(
        useCloudUsercommAsyncRequestWS,
        useUsercommAsyncRequestBLE,
    )
    // Local delete queue: target to BLE
    const [locallyDeletedEntities, locallyDeleteEntities] = useDeleteQueue(
        useUsercommAsyncRequestBLE,
    )

    // Remote
    // Remote update queue: source from BLE, target to WS
    const [remotelyUpdatedEntities, remotelyUpdateEntities] = useUpdateQueue(
        useUsercommAsyncRequestBLE,
        useCloudUsercommAsyncRequestWS,
    )
    // Remote delete queue: target to WS
    const [remotelyDeletedEntities, remotelyDeleteEntities] = useDeleteQueue(
        useCloudUsercommAsyncRequestWS,
    )

    const [globalSyncDownProgress, setGlobalSyncDownProgress] =
        useState<number>(0)

    const [globalSyncUpProgress, setGlobalSyncUpProgress] = useState<number>(0)

    const localGenericEntitiesTimestampRef = useRef<number | null>(null)
    const remoteGenericEntitiesTimestampRef = useRef<number | null>(null)

    useEffect(() => {
        if (currentUser === null) {
            return
        }
        let pbEnterpriseUUID = uuidToPbUUID(currentUser.Enterprise.UUID)
        let t = Date.now()
        localGenericEntitiesTimestampRef.current = t
        remoteGenericEntitiesTimestampRef.current = t
        getLocalGenericEntities(pbEnterpriseUUID) // enterpriseUUID is ignored by the firmware
        getRemoteGenericEntities(pbEnterpriseUUID) // UPD 2024-09-05: enterpriseUUID instead of deviceUUID
    }, [currentUser])

    useEffect(() => {
        if (localGenericEntities === null) {
            return
        }
        let dt = Date.now() - localGenericEntitiesTimestampRef.current!
        console.log(
            `DataCloudSyncWidget: localGenericEntities received in ${dt}ms:`,
            localGenericEntities,
        )
    }, [localGenericEntities])

    useEffect(() => {
        if (remoteGenericEntities === null) {
            return
        }
        let dt = Date.now() - remoteGenericEntitiesTimestampRef.current!
        console.log(
            `DataCloudSyncWidget: remoteGenericEntities received in ${dt}ms:`,
            remoteGenericEntities,
        )
    }, [remoteGenericEntities])

    useEffect(() => {
        if (localGenericEntities === null || remoteGenericEntities === null) {
            return
        }

        let _localUpdateQueue: IRawGenericStorageEntity[] = []
        let _localDeleteQueue: IRawGenericStorageEntity[] = []
        let _localStaleEntities: IRawGenericStorageEntity[] = []

        let _remoteUpdateQueue: IRawGenericStorageEntity[] = []
        let _remoteDeleteQueue: IRawGenericStorageEntity[] = []
        let _remoteStaleEntities: IRawGenericStorageEntity[] = []

        for (let localEntity of localGenericEntities) {
            let remoteEntity = remoteGenericEntities.find(
                (entity) => entity.uuid === localEntity.uuid,
            )
            if (remoteEntity === undefined) {
                // remote entity does not exist, we should upsert it if it has a parent
                if (localEntity.parent_uuid === null) {
                    continue
                }
                if (localEntity.deleted_at === 0) {
                    // local entity is not deleted, we should upsert it to the remote
                    _remoteUpdateQueue.push(localEntity)
                } else {
                    // local entity is deleted and it does not exist on the remote
                    // TODO: how to not count this entity when calculating disposition?
                    _localStaleEntities.push(localEntity)
                }
            } else {
                if (localEntity.deleted_at > 0 || remoteEntity.deleted_at > 0) {
                    if (
                        localEntity.deleted_at === 0 &&
                        remoteEntity.deleted_at > 0
                    ) {
                        // remote entity was deleted, we should delete the still existing local entity
                        _localDeleteQueue.push(remoteEntity)
                    } else if (
                        localEntity.deleted_at > 0 &&
                        remoteEntity.deleted_at === 0
                    ) {
                        // local entity was deleted, we should delete the still existing remote entity
                        _remoteDeleteQueue.push(localEntity)
                    }
                    continue
                }
                if (
                    localEntity.updated_at > remoteEntity.updated_at &&
                    localEntity.parent_uuid !== null
                ) {
                    // local entity is newer than remote entity
                    _remoteUpdateQueue.push(localEntity)
                } else if (
                    localEntity.updated_at < remoteEntity.updated_at &&
                    remoteEntity.parent_uuid !== null
                ) {
                    // remote entity is newer than local entity
                    _localUpdateQueue.push(remoteEntity)
                } else {
                    // entities are in sync
                }
            }
        }
        _remoteUpdateQueue.sort((a, b) => a.entity_type - b.entity_type) // sites -> impacts
        setRemoteDeleteQueue(_remoteDeleteQueue)
        setRemoteUpdateQueue(_remoteUpdateQueue)
        setLocalDeleteQueue(_localDeleteQueue)

        // check for remote entities that do not exist locally
        // sort them by entity type (sites -> impacts) to allow for proper parent-child upserting
        remoteGenericEntities.sort((a, b) => a.entity_type - b.entity_type) // sites -> impacts
        let _forbiddenParents = new Set<string>()
        for (let remoteEntity of remoteGenericEntities) {
            let localEntity = localGenericEntities.find(
                (entity) => entity.uuid === remoteEntity.uuid,
            )
            if (localEntity === undefined) {
                // local entity does not exist, we should upsert it if it has a parent (remote entities always have parents)
                if (remoteEntity.parent_uuid === null) {
                    continue
                }
                if (remoteEntity.deleted_at === 0) {
                    // TODO: firmware will check if entity's parentUUID exists, so this will only work for sub-site entities
                    // We should not push this remoteEntity here if it is a site or
                    // if it is any of previously discared entities
                    if (remoteEntity.entity_type === StorageEntityType.SITE) {
                        _forbiddenParents.add(remoteEntity.uuid)
                        continue
                    }
                    if (_forbiddenParents.has(remoteEntity.parent_uuid)) {
                        _forbiddenParents.add(remoteEntity.uuid)
                        continue
                    }
                    _localUpdateQueue.push(remoteEntity)
                } else {
                    // remote entity is deleted and it does not exist on the local
                    // TODO: how to not count this entity when calculating disposition?
                    _remoteStaleEntities.push(remoteEntity)
                }
            }
        }
        _localUpdateQueue.sort((a, b) => a.entity_type - b.entity_type) // sites -> impacts
        setLocalUpdateQueue(_localUpdateQueue)

        // Set stale entities
        setLocalStaleEntities(_localStaleEntities)
        setRemoteStaleEntities(_remoteStaleEntities)
    }, [localGenericEntities, remoteGenericEntities])

    useEffect(() => {
        if (localUpdateQueue === null) {
            return
        }
        console.log(`DataCloudSyncWidget: localUpdateQueue:`, localUpdateQueue)
        locallyUpdateEntities(localUpdateQueue)
    }, [localUpdateQueue])

    useEffect(() => {
        if (localDeleteQueue === null) {
            return
        }
        console.log(`DataCloudSyncWidget: localDeleteQueue:`, localDeleteQueue)
        locallyDeleteEntities(localDeleteQueue)
    }, [localDeleteQueue])

    useEffect(() => {
        if (remoteUpdateQueue === null) {
            return
        }
        console.log(
            `DataCloudSyncWidget: remoteUpdateQueue:`,
            remoteUpdateQueue,
        )
        remotelyUpdateEntities(remoteUpdateQueue)
    }, [remoteUpdateQueue])

    useEffect(() => {
        if (remoteDeleteQueue === null) {
            return
        }
        console.log(
            `DataCloudSyncWidget: remoteDeleteQueue:`,
            remoteDeleteQueue,
        )
        remotelyDeleteEntities(remoteDeleteQueue)
    }, [remoteDeleteQueue])

    // SyncMap
    useEffect(() => {
        if (
            localGenericEntities === null ||
            remoteGenericEntities === null ||
            localUpdateQueue === null ||
            localDeleteQueue === null ||
            localStaleEntities === null ||
            remoteUpdateQueue === null ||
            remoteDeleteQueue === null ||
            remoteStaleEntities === null
        ) {
            return
        }
        let _syncStateMap: Record<
            IRawGenericStorageEntity["uuid"],
            ISyncEntityLocationAndState
        > = {}
        // All local
        for (let entity of localGenericEntities || []) {
            _syncStateMap[entity.uuid] = {
                syncState: ESyncState.ALREADY_SYNCED,
                isLocal: true,
                isRemote: false,
            }
        }
        // All remote
        for (let entity of remoteGenericEntities || []) {
            if (_syncStateMap[entity.uuid] !== undefined) {
                _syncStateMap[entity.uuid].isRemote = true
            } else {
                _syncStateMap[entity.uuid] = {
                    syncState: ESyncState.ALREADY_SYNCED,
                    isLocal: false,
                    isRemote: true,
                }
            }
        }
        // Local
        for (let entity of localUpdateQueue || []) {
            _syncStateMap[entity.uuid].syncState = ESyncState.SYNCING_DOWN
        }
        for (let entity of localDeleteQueue || []) {
            _syncStateMap[entity.uuid].syncState = ESyncState.SYNCING_DOWN
        }
        for (let entity of localStaleEntities || []) {
            _syncStateMap[entity.uuid].syncState = ESyncState.WONT_SYNC_UP
        }
        if (locallyUpdatedEntities !== null) {
            for (let entity of locallyUpdatedEntities) {
                _syncStateMap[entity.uuid].syncState =
                    ESyncState.SYNC_DOWN_COMPLETED
                _syncStateMap[entity.uuid].isLocal = true
            }
        }
        if (locallyDeletedEntities !== null) {
            for (let entity of locallyDeletedEntities) {
                _syncStateMap[entity.uuid].syncState =
                    ESyncState.SYNC_DOWN_COMPLETED
                _syncStateMap[entity.uuid].isLocal = true
            }
        }
        // Remote
        for (let entity of remoteUpdateQueue || []) {
            _syncStateMap[entity.uuid].syncState = ESyncState.SYNCING_UP
        }
        for (let entity of remoteDeleteQueue || []) {
            _syncStateMap[entity.uuid].syncState = ESyncState.SYNCING_UP
        }
        for (let entity of remoteStaleEntities || []) {
            _syncStateMap[entity.uuid].syncState = ESyncState.WONT_SYNC_DOWN
        }
        if (remotelyUpdatedEntities !== null) {
            for (let entity of remotelyUpdatedEntities) {
                _syncStateMap[entity.uuid].syncState =
                    ESyncState.SYNC_UP_COMPLETED
                _syncStateMap[entity.uuid].isRemote = true
            }
        }
        if (remotelyDeletedEntities !== null) {
            for (let entity of remotelyDeletedEntities) {
                _syncStateMap[entity.uuid].syncState =
                    ESyncState.SYNC_UP_COMPLETED
                _syncStateMap[entity.uuid].isRemote = true
            }
        }
        console.log(`DataCloudSyncWidget: _syncStateMap:`, _syncStateMap)
        setEntitySyncStateMap(_syncStateMap)

        let nTotalUp = 0
        let nTotalDown = 0
        let nAlreadySynced = 0
        let nSyncingUp = 0
        let nSyncingDown = 0
        let nSyncDownCompleted = 0
        let nSyncUpCompleted = 0
        for (let value of Object.values(_syncStateMap)) {
            switch (value.syncState) {
                case ESyncState.ALREADY_SYNCED:
                    nTotalUp++
                    nTotalDown++
                    nAlreadySynced++
                    break
                case ESyncState.SYNCING_UP:
                    nTotalUp++
                    nSyncingUp++
                    break
                case ESyncState.SYNCING_DOWN:
                    nTotalDown++
                    nSyncingDown++
                    break
                case ESyncState.SYNC_UP_COMPLETED:
                    nTotalUp++
                    nSyncUpCompleted++
                    break
                case ESyncState.SYNC_DOWN_COMPLETED:
                    nTotalDown++
                    nSyncDownCompleted++
                    break
            }
        }

        if (nTotalDown === 0) {
            setGlobalSyncDownProgress(1)
        } else {
            setGlobalSyncDownProgress(
                (nAlreadySynced + nSyncDownCompleted) / nTotalDown,
            )
        }
        if (nTotalUp === 0) {
            setGlobalSyncUpProgress(1)
        } else {
            setGlobalSyncUpProgress(
                (nAlreadySynced + nSyncUpCompleted) / nTotalUp,
            )
        }
    }, [
        localGenericEntities,
        remoteGenericEntities,
        localUpdateQueue,
        localDeleteQueue,
        localStaleEntities,
        remoteUpdateQueue,
        remoteDeleteQueue,
        remoteStaleEntities,
        locallyUpdatedEntities,
        locallyDeletedEntities,
        remotelyUpdatedEntities,
        remotelyDeletedEntities,
    ])
    // Site-aggregated SyncMap
    useEffect(() => {
        if (entitySyncStateMap === null) {
            return
        }
        let aggregatedEntitiesMap = aggregateEntitiesBySite(
            localGenericEntities,
            remoteGenericEntities,
        )
        let aggregatedSyncMap: Record<
            string,
            Record<string, ISyncEntityLocationAndState>
        > = {}
        for (let [siteUUID, siteEntities] of Object.entries(
            aggregatedEntitiesMap,
        )) {
            let siteSyncMap: Record<string, ISyncEntityLocationAndState> = {}
            for (let entity of siteEntities) {
                let entitySyncState = entitySyncStateMap[entity.uuid]
                if (entitySyncState === undefined) {
                    continue
                }
                siteSyncMap[entity.uuid] = entitySyncState
            }
            aggregatedSyncMap[siteUUID] = siteSyncMap
        }
        console.log(
            `DataCloudSyncWidget: aggregatedSyncMap:`,
            aggregatedSyncMap,
        )
        let _aggregatedProgressMap: Record<string, ISyncAggregatedProgress> = {}
        for (let [siteUUID, siteSyncMap] of Object.entries(aggregatedSyncMap)) {
            let nAlreadySynced = 0
            let nTotalUp = 0
            let nTotalDown = 0
            let nSyncingUp = 0
            let nSyncingDown = 0
            let nSyncDownCompleted = 0
            let nSyncUpCompleted = 0
            let nWontSyncUp = 0
            let nWontSyncDown = 0

            let nIsLocal = 0
            let nIsRemote = 0
            let nTotal = 0

            for (let value of Object.values(siteSyncMap)) {
                nTotal++
                if (value.isLocal) {
                    nIsLocal++
                }
                if (value.isRemote) {
                    nIsRemote++
                }
                switch (value.syncState) {
                    case ESyncState.ALREADY_SYNCED:
                        nTotalUp++
                        nTotalDown++
                        nAlreadySynced++
                        break
                    case ESyncState.SYNCING_UP:
                        nTotalUp++
                        nSyncingUp++
                        break
                    case ESyncState.SYNCING_DOWN:
                        nTotalDown++
                        nSyncingDown++
                        break
                    case ESyncState.SYNC_UP_COMPLETED:
                        nTotalUp++
                        nSyncUpCompleted++
                        break
                    case ESyncState.SYNC_DOWN_COMPLETED:
                        nTotalDown++
                        nSyncDownCompleted++
                        break
                    case ESyncState.WONT_SYNC_UP:
                        nWontSyncUp++
                        break
                    case ESyncState.WONT_SYNC_DOWN:
                        nWontSyncDown++
                        break
                }
            }
            let progressDown = 0
            if (nTotalDown > 0) {
                progressDown =
                    (nAlreadySynced + nSyncDownCompleted) / nTotalDown
            }
            let progressUp = 0
            if (nTotalUp > 0) {
                progressUp = (nAlreadySynced + nSyncUpCompleted) / nTotalUp
            }

            let defaultDisposition = ESyncDisposition.UNKNOWN
            if (
                localGenericEntities === null &&
                remoteGenericEntities !== null
            ) {
                defaultDisposition = ESyncDisposition.REMOTE
            } else if (
                localGenericEntities !== null &&
                remoteGenericEntities === null
            ) {
                defaultDisposition = ESyncDisposition.LOCAL
            } else {
                defaultDisposition = ESyncDisposition.BOTH
            }

            let disposition = ESyncDisposition.UNKNOWN
            if (nIsLocal - nWontSyncUp > nIsRemote) {
                disposition = ESyncDisposition.LOCAL
            } else if (nIsRemote - nWontSyncDown > nIsLocal) {
                disposition = ESyncDisposition.REMOTE
            } else if (nIsRemote === nIsLocal) {
                disposition = defaultDisposition
            }
            _aggregatedProgressMap[siteUUID] = {
                progressDown,
                progressUp,
                disposition,
            }
        }
        console.log(
            `DataCloudSyncWidget: aggregatedProgressMap:`,
            _aggregatedProgressMap,
        )
        setAggregatedSyncProgressMap(_aggregatedProgressMap)
    }, [
        entitySyncStateMap,
        localGenericEntities,
        remoteGenericEntities,
        localStaleEntities,
        remoteStaleEntities,
    ])

    const floatButtonSize = 60

    const memoSyncGageUpElement = useMemo(() => {
        return (
            <Progress
                size={60}
                strokeWidth={7}
                percent={100 * globalSyncUpProgress}
                type="circle"
                format={() => (
                    <CloudUploadOutlined style={{ fontSize: "2rem" }} />
                )}
            />
        )
    }, [globalSyncUpProgress])

    const memoSyncGageDownElement = useMemo(() => {
        return (
            <Progress
                size={60}
                strokeWidth={7}
                percent={100 * globalSyncDownProgress}
                type="circle"
                format={() => (
                    <CloudDownloadOutlined style={{ fontSize: "2rem" }} />
                )}
            />
        )
    }, [globalSyncDownProgress])

    if (globalSyncDownProgress === 0 && globalSyncUpProgress === 0) {
        let el: ReactElement = (
            <Tooltip title="N/C">
                <SwapOutlined
                    style={{
                        fontSize: "3rem",
                        color: COLOR_BG_GRAY,
                    }}
                />
            </Tooltip>
        )
        if (bleIsConnected) {
            el = (
                <FlexCol
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Tooltip title="Initiating sync..">
                        <div
                            style={{
                                position: "relative",
                            }}
                        >
                            <CloudOutlined
                                style={{
                                    fontSize: "3rem",
                                    color: COLOR_BG_GRAY,
                                }}
                            />
                            <SyncOutlined
                                spin
                                style={{
                                    position: "absolute",
                                    bottom: -2,
                                    left: "0.75rem",
                                    fontSize: "1.5rem",
                                    color: COLOR_BG_GRAY,
                                }}
                            />
                        </div>
                    </Tooltip>
                </FlexCol>
            )
        }
        return (
            <FloatButton
                type="default"
                style={{
                    width: floatButtonSize,
                    height: floatButtonSize,
                    right: "5vw",
                    bottom: 60 + 1 * (floatButtonSize + 10),
                }}
                description={el}
            />
        )
    }

    return (
        <>
            <Tooltip
                placement="left"
                trigger="click"
                overlay={`Sync to Cloud: ${(100 * globalSyncUpProgress).toFixed(0)}%`}
                overlayStyle={{ textAlign: "center" }}
            >
                <FloatButton
                    type="default"
                    style={{
                        width: floatButtonSize,
                        height: floatButtonSize,
                        right: "5vw",
                        bottom: 60 + 2 * (floatButtonSize + 10),
                    }}
                    description={memoSyncGageUpElement}
                />
            </Tooltip>
            <Tooltip
                placement="left"
                trigger="click"
                overlay={`Sync to Station: ${(100 * globalSyncDownProgress).toFixed(0)}%`}
                overlayStyle={{ textAlign: "center" }}
            >
                <FloatButton
                    type="default"
                    style={{
                        width: floatButtonSize,
                        height: floatButtonSize,
                        right: "5vw",
                        bottom: 60 + 1 * (floatButtonSize + 10),
                    }}
                    description={memoSyncGageDownElement}
                />
            </Tooltip>
        </>
    )
}
