import { MutexInterface, Mutex } from "async-mutex"
import { useState, useRef, useCallback, useEffect } from "react"
import {
    Equipment,
    Impact,
    Site,
    UCPayload,
    UUID,
    Zone,
} from "../../generated/proto-ts/main"
import { equalUint8Array } from "../common/usercommUtils"
import { useCloudUsercommContextWS } from "./cloudUsercommProviderWS"
import { v4 as uuidv4, stringify as uuidStringify } from "uuid"
import {
    useUsercommStatsGen,
    useUsercommSitesGen,
    useUsercommSiteGen,
    useUsercommCreateSiteGen,
    useUsercommUpdateSiteGen,
    useUsercommDeleteSiteGen,
    useUsercommSiteAnnexesGen,
    useUsercommAnnexGen,
    useUsercommCreateAnnexGen,
    useUsercommUpdateAnnexGen,
    useUsercommDeleteAnnexHardGen,
    useUsercommSiteChildrenRecursiveGen,
    useUsercommSiteEquipmentsGen,
    useUsercommEquipmentGen,
    useUsercommCreateEquipmentGen,
    useUsercommUpdateEquipmentGen,
    useUsercommDeleteEquipmentHardGen,
    useUsercommEquipmentChildrenRecursiveGen,
    useUsercommEquipmentZonesGen,
    useUsercommZoneGen,
    useUsercommCreateZoneGen,
    useUsercommUpdateZoneGen,
    useUsercommDeleteZoneHardGen,
    useUsercommZoneImpactsGen,
    useUsercommAllImpactsGen,
    useUsercommSyntheticImpactWithEmbeddedReferencesGen,
    useUsercommImpactGen,
    useUsercommDeleteImpactHardGen,
    useUsercommUpdateImpactGen,
    useUsercommGenericDeviceEntitiesGen,
    useUsercommSetDeviceTelemetryGen,
} from "../common/usercommAsyncRequestGeneric"

export const useCloudUsercommAsyncRequestWS = (): [
    UCPayload | null,
    (payload: UCPayload) => void,
] => {
    const [usercommRequestUUID, setUsercommRequestUUID] =
        useState<Uint8Array | null>(null)
    const [usercommResponse, setUsercommResponse] = useState<UCPayload | null>(
        null,
    )
    const { recvMessageQueue, consumeRecvMessage, addEmitMessage } =
        useCloudUsercommContextWS()
    const asyncMutexRef = useRef<MutexInterface>(new Mutex())

    const usercommRequest = useCallback(async (payload: UCPayload) => {
        if (asyncMutexRef.current.isLocked() && usercommRequestUUID !== null) {
            console.log(
                `CloudUsercommRequest (WS): mutex is already locked: request #${uuidStringify(usercommRequestUUID)} is already in progress`,
            )
            return
        }
        await asyncMutexRef.current.acquire()
        let _uuid = uuidv4<Uint8Array>(null, new Uint8Array(16))
        setUsercommRequestUUID(_uuid)
        payload.uuid = new UUID({ value: _uuid })
        addEmitMessage(payload)
    }, [])

    useEffect(() => {
        if (usercommRequestUUID === null) {
            return
        }
        for (let msg of recvMessageQueue) {
            if (!msg.has_uuid) {
                continue
            }
            if (equalUint8Array(msg.uuid.value, usercommRequestUUID)) {
                consumeRecvMessage(msg.uuid)
                setUsercommResponse(msg)
                asyncMutexRef.current.release()
                setUsercommRequestUUID(null)
                break
            }
        }
    }, [usercommRequestUUID, recvMessageQueue])

    return [usercommResponse, usercommRequest]
}

export const useUsercommGenericDeviceEntitiesWS = () =>
    useUsercommGenericDeviceEntitiesGen(useCloudUsercommAsyncRequestWS)

// STATS
export const useCloudUsercommStatsWS = () =>
    useUsercommStatsGen(useCloudUsercommAsyncRequestWS)

// SITES
export const useCloudUsercommSitesWS = () =>
    useUsercommSitesGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommSiteWS = () =>
    useUsercommSiteGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommCreateSiteWS = () =>
    useUsercommCreateSiteGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommUpdateSiteWS = () =>
    useUsercommUpdateSiteGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommDeleteSiteWS = () =>
    useUsercommDeleteSiteGen(useCloudUsercommAsyncRequestWS)

// ANNEXES
export const useCloudUsercommSiteAnnexesWS = () =>
    useUsercommSiteAnnexesGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommAnnexWS = () =>
    useUsercommAnnexGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommCreateAnnexWS = () =>
    useUsercommCreateAnnexGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommUpdateAnnexWS = () =>
    useUsercommUpdateAnnexGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommDeleteAnnexWS = () =>
    useUsercommDeleteAnnexHardGen(useCloudUsercommAsyncRequestWS)

// RECURSIVE SITE CHILDREN
export const useCloudUsercommSiteChildrenRecursiveWS = () =>
    useUsercommSiteChildrenRecursiveGen(useCloudUsercommAsyncRequestWS)

// EQUIPMENTS
export const useCloudUsercommSiteEquipmentsWS = () =>
    useUsercommSiteEquipmentsGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommEquipmentWS = () =>
    useUsercommEquipmentGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommCreateEquipmentWS = () =>
    useUsercommCreateEquipmentGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommUpdateEquipmentWS = () =>
    useUsercommUpdateEquipmentGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommDeleteEquipmentWS = () =>
    useUsercommDeleteEquipmentHardGen(useCloudUsercommAsyncRequestWS)

// RECURSIVE EQUIPMENT CHILDREN
export const useCloudUsercommEquipmentChildrenRecursiveWS = () =>
    useUsercommEquipmentChildrenRecursiveGen(useCloudUsercommAsyncRequestWS)

// ZONES
export const useCloudUsercommEquipmentZonesWS = () =>
    useUsercommEquipmentZonesGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommZoneWS = () =>
    useUsercommZoneGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommCreateZoneWS = () =>
    useUsercommCreateZoneGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommUpdateZoneWS = () =>
    useUsercommUpdateZoneGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommDeleteZoneWS = () =>
    useUsercommDeleteZoneHardGen(useCloudUsercommAsyncRequestWS)

// IMPACTS

export const useCloudUsercommZoneImpactsWS = () =>
    useUsercommZoneImpactsGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommAllImpactsWS = () =>
    useUsercommAllImpactsGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommSyntheticImpactWithEmbeddedReferencesWS = () =>
    useUsercommSyntheticImpactWithEmbeddedReferencesGen(
        useCloudUsercommAsyncRequestWS,
    )

export const useCloudUsercommImpactWS = () =>
    useUsercommImpactGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommUpdateImpactWS = () =>
    useUsercommUpdateImpactGen(useCloudUsercommAsyncRequestWS)

export const useCloudUsercommDeleteImpactWS = () =>
    useUsercommDeleteImpactHardGen(useCloudUsercommAsyncRequestWS)

export const useUsercommSetDeviceTelemetryWS = () =>
    useUsercommSetDeviceTelemetryGen(useCloudUsercommAsyncRequestWS)
