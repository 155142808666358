import { FC, useCallback, useMemo } from "react"
import { ICloudBruteUser, ICloudUser } from "../../types"
import { Collapse, List } from "antd"
import { faMedal } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSyncronizationContext } from "../../providers/syncronizationProvider"
import { COLOR_BG_GRAY, COLOR_BG_LIGHT_BLUE } from "../../utils/utils"

interface ICombinedBruteEnterpriseUser extends ICloudBruteUser {
    User: ICloudUser
}

export const BruteUserRankingWidget: FC<{
    user: ICloudUser | null
}> = ({ user }) => {
    const { enterpriseUsers, bruteEnterpriseUsers } = useSyncronizationContext()

    const memoCombinedBruteEnterpriseUsers = useMemo(() => {
        if (enterpriseUsers === null || bruteEnterpriseUsers === null) {
            return []
        }
        let _combinedBruteEnterpriseUsers: ICombinedBruteEnterpriseUser[] = []
        for (let bruteEnterpriseUser of bruteEnterpriseUsers) {
            let user = enterpriseUsers.find(
                (user) => user.UUID === bruteEnterpriseUser.UserUUID,
            )
            if (user !== undefined) {
                _combinedBruteEnterpriseUsers.push({
                    User: user,
                    ...bruteEnterpriseUser,
                })
            }
        }
        return _combinedBruteEnterpriseUsers
    }, [enterpriseUsers, bruteEnterpriseUsers])

    const memoCurrentUserIdx = useMemo(() => {
        if (user === null) {
            return -1
        }
        return memoCombinedBruteEnterpriseUsers.findIndex(
            (combinedBruteEnterpriseUser) =>
                combinedBruteEnterpriseUser.User.UUID === user.UUID,
        )
    }, [user, memoCombinedBruteEnterpriseUsers])

    const renderListItem = useCallback(
        (item: ICombinedBruteEnterpriseUser, idx: number) => {
            if (idx !== memoCurrentUserIdx) {
                return null
            }
            let color = COLOR_BG_LIGHT_BLUE
            switch (item.GmaxRank) {
                case 1:
                    color = "gold"
                    break
                case 2:
                    color = "silver"
                    break
                case 3:
                    color = "#cd7f32"
                    break
            }
            return (
                <List.Item>
                    <List.Item.Meta
                        avatar={
                            <FontAwesomeIcon
                                icon={faMedal}
                                style={{
                                    fontSize: "1.5rem",
                                    color: color,
                                }}
                            />
                        }
                        title={
                            <div
                                style={{
                                    fontWeight:
                                        idx === memoCurrentUserIdx
                                            ? "bold"
                                            : "normal",
                                }}
                            >
                                {item.GmaxRank}&nbsp;—&nbsp;
                                {item.User.FirstName}&nbsp;
                                {item.User.LastName}
                            </div>
                        }
                        description={
                            <div
                                style={{
                                    fontWeight:
                                        idx === memoCurrentUserIdx
                                            ? "bold"
                                            : "normal",
                                }}
                            >
                                {item.GmaxValue}G&nbsp;—&nbsp;
                                {item.BruteClass.KeyEn}
                            </div>
                        }
                    />
                </List.Item>
            )
        },
        [memoCurrentUserIdx],
    )

    return (
        <List
            className="brute-user-ranking-widget"
            itemLayout="horizontal"
            dataSource={memoCombinedBruteEnterpriseUsers}
            pagination={{
                pageSize: 3,
                defaultCurrent: Math.floor(memoCurrentUserIdx / 3) + 1,
                size: "small",
                simple: true,
                hideOnSinglePage: true,
            }}
            size="small"
            renderItem={renderListItem}
        />
    )
}
